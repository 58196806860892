import React, { Component } from 'react';
import airbnb from '../../assets/image/airbnb.png';
import isURL from 'validator/lib/isURL';
import Alert from 'react-bootstrap/Alert';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';


class Singup_stap_4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Fome data erroe
            valid_headquater : "", 
            valid_company_website: "",
            valid_email_address:"",
            //save upload data
            headquater : this.props.Headquater, 
            company_website: this.props.Company_Website,
            email_address:this.props.email,
            city_state_list:[],

            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
            
        }
        // add form value
        this.Headquater = React.createRef();
        this.Company_Website = React.createRef();
        this.Email_Address = React.createRef();
    }

    //gotonextStap(){this.props.clickEvent("stap4");}
    gotobackStap(){this.props.clickEvent("stap2");}

    handleKeyPress(e){
        if(e.target.name == "Email_Address"){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ email_address:e.target.value });
            if (re.test(e.target.value)) {
                this.setState({
                    valid_email_address:"",
                });
            }else {
                this.setState({ valid_email_address: "Please enter a valid email address" })
            }           
        }

        if(e.target.name == "Headquater"){
            this.setState({
                valid_headquater:"",
                headquater:e.target.value
            });
        }

        if(e.target.name == "Company_Website"){
            this.setState({company_website : e.target.value}) 
            if (isURL(e.target.value)) {
                this.setState({
                    valid_company_website:"",
                });
            }else{
                this.setState({ valid_company_website: "Please enter a valid url" })  
            }
            if (e.target.value == '') {
                this.setState({ valid_company_website: "" });
            }
        }
    }

    //list of city and starte
    city_state_list(e){
        // this.setState({headquater : '',valid_headquater:""});
        this.setState({headquater : e.target.value,valid_headquater:""});

        var city_state_list_val = [];
        const temp = {
            method: "GET", 
        }
        fetch('https://nominatim.openstreetmap.org/search?q='+e.target.value+'&limit=50&format=json&addressdetails=1',temp)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            resJson.map(function(es, i){  
                if(
                    ( es.address.hasOwnProperty('city') || es.address.hasOwnProperty('county') )
                    &&
                    ( es.address.hasOwnProperty('state') || es.address.hasOwnProperty('province') )
                ){
                    var city_temp = '';
                    var state_temp = '';

                    if(es.address.hasOwnProperty('county')){ city_temp = es.address.county; }
                    if(es.address.hasOwnProperty('city')){ city_temp = es.address.city; }

                    if(es.address.hasOwnProperty('province')){ state_temp = es.address.province; }
                    if(es.address.hasOwnProperty('state')){ state_temp = es.address.state; }

                    city_state_list_val.push({
                        value : city_temp+', '+state_temp,
                        name : es.display_name
                    })
                }
            })
            this.setState({city_state_list : city_state_list_val});
        });
    }

    //Fome submit function
    Save_company_data_stap_3(event){
        event.preventDefault();
        let log = false;

        const myArray = this.state.headquater.split(",");
        const city = myArray[0];
        const state = myArray[1];

        if(!this.state.headquater){
            this.setState({ valid_headquater: "Please enter a Headquater" });
            log = true;
        }

        if( state == undefined){
            this.setState({ valid_headquater: "Please enter a Headquater State" });
            log = true;
        }

        if( myArray.length >= 3 ){
            this.setState({ valid_headquater: "Please enter a City,State Headquater " });
            log = true;
        }
 
        if(!this.Company_Website.current.value) {
            this.setState({ valid_company_website: "Please enter a company website" });
            log = true;
        }else{
            if (isURL(this.Company_Website.current.value)) {
                this.setState({ valid_company_website:"" });
            }else{
                this.setState({ valid_company_website: "Please enter a valid url" });
                log = true;
            }
        }

        if(!this.Email_Address.current.value ) {
            this.setState({ valid_email_address: "Please enter a email address" });
            log = true;
        }

        if(this.Email_Address.current.value){
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(this.Email_Address.current.value)) {}else {
                this.setState({ valid_email_address: "Please enter a valid email address" })
                log = true;
            }           
        }

        if(log === true){ 
            return 
        }
        
        // this.state.headquater = this.Headquater.current.value
        this.state.company_website = this.Company_Website.current.value
        this.state.email_address = this.Email_Address.current.value

        // this.setState({headquater: this.state.headquater});
        this.setState({company_website: this.state.company_website});
        this.setState({email_address: this.state.email_address});

        this.props.get_data_stap_3(this.state.headquater,this.state.company_website,this.state.email_address);
        
        const apiOption = {
            method: "post",
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "email" :  this.Email_Address.current.value
            }), 
        }
   
        fetch(this.props.apiPath+"send_code",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Verification code sent to your email" });
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Email address is already registered" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.props.clickEvent("stap4");
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    changeState(e){
        this.setState({headquater : e})
    }


    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <>
                 {this.error_handaling()}
                <div className="row signin_signup_background singni_singup_meta_data4">
                    <div className="col-lg-6"></div> 
                    <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                        <div className="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                            <div className="Singup_stap_4_progress"></div>
                            <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 5%"}}>
                                    <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                        {/* {$imagePreview} */}
                                    </div>
                                    <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                        <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                        <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "24px"}}>Primary Information</p>
                                    </div>    
                                </div>
                                <div className="singup_box-singup-stap-2 singup_box-singup-stap-4 " style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                    <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data_stap_3.bind(this)} >
                                        <div className="singup_input" style={{float:"left",width:"100%",marginBottom:"5%"}}>
                                            
                                            <label>Headquarters</label>
                                            <input onChange={this.city_state_list.bind(this)} value={this.state.headquater} name='Headquater' type="text" ref={this.Headquater} placeholder="City, State" list="city_state_list" />
                                            <datalist id="city_state_list">
                                                {this.state.city_state_list.map((item) =>
                                                    <option key={item} value={item.value} >{item.name}</option>
                                                )}
                                            </datalist>
                                            <span className="form_validtion_class">{ this.state.valid_headquater }</span>
                                        </div>
                                        <div  className="singup_input" style={{float:"left",width:"100%",marginBottom:"5%"}}>
                                            <label>Company Website</label>
                                            <input onChange={this.handleKeyPress.bind(this)} value={this.state.company_website}  name='Company_Website' type="text" ref={this.Company_Website} placeholder="Company Website" />
                                            <span className="form_validtion_class">{ this.state.valid_company_website }</span>
                                        </div>
                                        <div className="singup_input" style={{float:"left",width:"100%",marginBottom:"5%"}}>
                                            <label>Email Address</label>
                                            <input onChange={this.handleKeyPress.bind(this)} value={this.state.email_address}  name='Email_Address' ref={this.Email_Address} type="Email" placeholder="Email Address" />
                                            <span className="form_validtion_class">{ this.state.valid_email_address }</span>
                                        </div>
                                        <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "15% 0 0"}}>
                                            <div className="back_wapper">
                                                <button type="button" onClick={this.gotobackStap.bind(this)}> Back </button>
                                            </div>
                                            <button type="button" onClick={this.Save_company_data_stap_3.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                        </div>
                                    </form> 
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </>
            
        );
    }
}
export default Singup_stap_4;