import React from 'react';
import Select from 'react-select';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const options = []
function  SelectIndustryList (props)  {
  
  props.list_industries.map(function(element){
    options.push(
      {
        value: element.id, label: element.name
      }
    )
  })

  const userIdHandler = (e) => {
    props.getvaluedata(e);
  }

  return(
    // <Select
    //   defaultValue={props.industries}
    //   isMulti
    //   name="colors"
    //   options={options}
    //   className="industry-multi-select"
    //   classNamePrefix="select"
    //   onChange={userIdHandler}
    // />
    <>
      {props.industries? 
        <Autocomplete className='country-select-demo2'
          multiple
          id="country-select-demo2"
          options={props.list_industries.map((option) => option.name)}
          onChange={(event, value) => {userIdHandler(value)}}

          defaultValue={props.industries}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField style={{border:'none !important'}}
              {...params}
              variant="filled"
              label="freeSolo"
              placeholder="Industry"
            />
          )}
        />
        :
        <Autocomplete className='country-select-demo2'
        multiple
        id="country-select-demo2"
        options={props.list_industries.map((option) => option.name)}
        onChange={(event, value) => {userIdHandler(value)}}

        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField style={{border:'none !important'}}
            {...params}
            variant="filled"
            label="freeSolo"
            placeholder="Industry"
          />
        )}
      />
        }
    </>
  );
}

export default SelectIndustryList;






