import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import airbnb from "../assets/image/Rectangle_348.png";

class Hiring_applicant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen_3: false,
        };
    }

    gotobackStap() { }

    change_page() { }

    openModal = () => this.setState({ isOpen_3: true });
    closeModal = () => this.setState({ isOpen_3: false });

    render() {
        return (
            <>
                <button type='button' onClick={this.openModal.bind(this)}>
                    {" "}
                    Hiring applicant{" "}
                </button>
                <Modal
                    className='new_team_member_box_main Hiring_applicant'
                    show={this.state.isOpen_3}
                    onHide={this.closeModal}
                >
                    <Modal.Body className='Hiring_applicant_body'>
                        <h3>What would you like to bo?</h3>
                        <div className='users_job_box'>
                            <img src={airbnb} />
                            <h3>Noha Nega</h3>
                            <span>Product Developer</span>
                        </div>
                        <div className='new_team_member_box_inner'>
                            <form>
                                <div
                                    className='singup_input '
                                    style={{ textAlign: "center", padding: "8% 0 0" }}
                                >
                                    <button
                                        type='button'
                                        className='singup_input_button singup_setp-3-button'
                                    >
                                        {" "}
                                        Hire and continue{" "}
                                    </button>
                                </div>
                                <div
                                    className='singup_input hire_and_close_job_btn'
                                    style={{ textAlign: "center", padding: "5% 0 0" }}
                                >
                                    <button
                                        type='button'
                                        className='singup_input_button singup_setp-3-button'
                                    >
                                        {" "}
                                        Hire and Colse job{" "}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default Hiring_applicant;
