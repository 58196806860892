import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom"
import airbnb from '../../assets/image/airbnb.png';
import upload_image from '../../assets/image/upload_image.png';
import Alert from 'react-bootstrap/Alert';
class Team_member_stap6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validpassword : "", 
            Passowrd : "",
            redirect: false,

            more_charecter : false,
            upper_or_lowercase : false,
            number_or_special : false,
        }
        // add form value
        this.password = React.createRef();
    }

    // handleKeyPress(e){
    //     if(e.target.value == ""){
    //         this.state.validpassword = "Please enter password";
    //         this.setState({validpassword:this.state.validpassword});
    //     }

    //     if(e.target.value.length < 5){
    //         this.state.validpassword = "Please enter password min 6 characters";
    //         this.setState({validpassword:this.state.validpassword});
    //     }else{
    //         this.setState({ validpassword: "" });
    //     }
    // }


    upper_or_lowercase(data){
        let upper_lower_data = false;
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
        if(regex.test(data)){ 
            upper_lower_data = true; 
        }else{
            upper_lower_data = false; 
        }
        return upper_lower_data;
    }

    onlyspealcardteralud(data){
        let special_data = false;
        var regex = new RegExp("^(?=.*[@$!%*?&])");
        var numb = new RegExp("^(?=.*?[0-9])");
        if(regex.test(data)){ 
            special_data = true; 
        }else if(numb.test(data)){ 
            special_data = true; 
        }else{
            special_data = false; 
        }
        return special_data;
    }

    handleKeyPress(e){
        if(e.target.value == ""){
            this.state.validpassword = "Please enter password";
            this.setState({validpassword:this.state.validpassword});
        }else{
            if(e.target.value.length < 7){
                //this.state.validpassword = "Please enter password min 7 characters";
                this.state.validpassword = "";
                this.setState({validpassword:this.state.validpassword,more_charecter:false});

                var medata = this.upper_or_lowercase(e.target.value);
                if(medata == true){
                    this.setState({upper_or_lowercase: true})
                }else{
                    this.setState({upper_or_lowercase: false})
                }

                var getnumbatr = this.onlyspealcardteralud(e.target.value);
                if(getnumbatr == true){ 
                    this.setState({number_or_special: true}) 
                }else{ 
                    this.setState({number_or_special: false}) 
                }

            }else{
                this.setState({ validpassword: "",more_charecter:true});
                var medata = this.upper_or_lowercase(e.target.value);
                if(medata == true){
                    this.state.validpassword = "";
                    this.setState({validpassword:this.state.validpassword,upper_or_lowercase:true});

                    var getnumbatr = this.onlyspealcardteralud(e.target.value);
                    if(getnumbatr == true){
                        this.state.validpassword = "";
                        this.setState({validpassword:this.state.validpassword,number_or_special:true});
                    }else{
                        //this.state.validpassword = "Please use 1 number or special character";
                        this.state.validpassword = "";
                        this.setState({ validpassword: this.state.validpassword,number_or_special:false});
                    }
                }else{
                    //this.state.validpassword = "Please use 1 uppercase and 1 lowercase characters";
                    this.state.validpassword = "";
                    this.setState({ validpassword: this.state.validpassword,upper_or_lowercase:false});
                }
            }
        }
    }

    Save_company_data_stap_6(event){
        event.preventDefault();
        let log = false;
        if(!this.password.current.value ){
            this.setState({ validpassword: "Please enter password" });
            log = true;
        }

        if(this.password.current.value.length < 7){
            this.setState({ validpassword: "Please enter password min 7 characters" });
            log = true;
        }

        let lovarcash = this.upper_or_lowercase(this.password.current.value);
        if(lovarcash == false){
            this.setState({ validpassword: "Please use 1 uppercase and 1 lowercase characters" });
            log = true;
        }

        let onlyspealcardteralud = this.onlyspealcardteralud(this.password.current.value);
        if(onlyspealcardteralud == false){
            this.setState({ validpassword: "Please use 1 number or special character" });
            log = true;
        }
 
        if(log === true){ 
            return 
        }

        this.state.password = this.password.current.value;
        this.setState({passowrd: this.state.password});

        var formdata = new FormData();
        formdata.append("company_id", this.props.company_id);
        formdata.append("email", this.props.email);
        formdata.append("password", this.state.password);
        formdata.append("phone", this.props.phone);
        formdata.append("name", this.props.Team_Name);
        formdata.append("job_title", this.props.Team_job_title);
        formdata.append("profile_image", this.props.Team_file);
        const apiOption2 = {
            method: "post",
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.apiPath+"register-company-team",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            // this.props.clickEvent("stap6");
            if(resJson.status == true){

                let Manin_data_wrap = resJson.data;
                let Profle = Manin_data_wrap.profile;
                let profile_img = Profle.profile_image;
                localStorage.setItem('profile_img', profile_img);

                let id = Profle.user_id;
                localStorage.setItem('User_id', id);
                localStorage.setItem('Account_Email', Profle.email);

                let access_token =  Manin_data_wrap.access_token;
                let token = access_token.token;
                localStorage.setItem('token',token);

                let user_name = Profle.name;
                localStorage.setItem('user_name',user_name);

                let account_status = Profle.account_status;
                localStorage.setItem('account_status',account_status);

                let green_house_token = Profle.greenhouse_access_token;
                localStorage.setItem('greenhouse_access_token',green_house_token);

                let user_Role = Profle.role;
                localStorage.setItem('user_Role',user_Role); 

                if(user_Role == 4){
                    let get_compnay_id = this.props.company_id;
                    localStorage.setItem('perent_id',get_compnay_id);

                    let get_compnay_email = Profle.email;
                    localStorage.setItem('get_compnay_email',get_compnay_email);
                }

                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Your company is registered" });
                this.error_handaling();
            }else{
                this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: "Something wrong with api fields" });
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect:true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    gotobackStap(){ this.props.clickEvent("Team_member_stap4");}
   
    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }

        if (this.state.redirect) {
            return <Navigate to="/ALL_Post" />
        }
        
        return (
            <div className="row signin_signup_background singni_singup_meta_data6">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box" style={{background:'overlay',background: "#FFF",float: "left", width: "100%", height: "100%",borderRadius: "6px"}}>
                        <div className="Singup_stap_6_progress" style={{width:"100%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 25px 0 0"}} ></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 15%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "bold", fontSize: "32px"}}>Company Information</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>Primary Information</p>
                                </div>    
                            </div>
                                   
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data_stap_6.bind(this)}>
                                    <div className="singup_input" style={{float:"left",width:"100%",marginBottom:"5%"}}>
                                        <label>Password</label>
                                        <input onChange={this.handleKeyPress.bind(this)} name='Password' ref={this.password} placeholder='Password' type="password" style={{borderColor:"rgba(109,109,109,1)",borderRadius:"6px"}} />
                                        <span className="form_validtion_class">{this.state.validpassword}</span>
                                    </div>
                                    <div className='validtion-check-data' style={{float:"left",width:"100%"}}>
                                        <div class="form-check">
                                            <input style={{opacity:1}} checked={this.state.more_charecter != false} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                            <label className="form-check-label" for="DEI_Programming"> 7 or more characters </label>
                                        </div>
                                        <div class="form-check">
                                            <input style={{opacity:1}} checked={this.state.upper_or_lowercase != false} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                            <label className="form-check-label" for="DEI_Programming"> Upper & lowercase </label>
                                        </div>
                                        <div class="form-check">
                                            <input style={{opacity:1}} checked={this.state.number_or_special != false} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                            <label className="form-check-label" for="DEI_Programming"> 1 number or special character </label>
                                        </div>
                                    </div>
                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "50% 0 0"}}>
                                        <div className="back_wapper">
                                            <button type="button" onClick={this.gotobackStap.bind(this)} > Back </button>
                                        </div>
                                        <button onClick={this.Save_company_data_stap_6.bind(this)} type="button" className="singup_input_button singup_setp-3-button"> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Team_member_stap6;