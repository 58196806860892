// import LeftSideBar from "../sidebar/leftside";
import React, { Component } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import airbnb from '../../assets/image/airbnb.png';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

class Singup_stap_8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DEI_Lead_or_Team : null,
            Resources_or_Cultural_Affinity: null,            
            DEI_Programming : null,
            DEI_Training: null, 

            valid_DEI_Lead_or_Team : "",
            valid_Resources_or_Cultural_Affinity: "",
            valid_DEI_Programming : "",
            valid_DEI_Training: "", 
        }
        
    }

    gotonextStap(){
        this.props.clickEvent("stap8");
    }

    gotobackStap(){
        this.props.clickEvent("stap6");
    }

    handleKeyPress(e){
        if(e.target.name == "Lead_or_Team"){
            this.setState({
                valid_DEI_Lead_or_Team:"",
                DEI_Lead_or_Team:e.target.value
            });
        }
        if(e.target.name == "flexRadioDefault"){
            this.setState({
                valid_Resources_or_Cultural_Affinity:"",
                Resources_or_Cultural_Affinity:e.target.value
            });
        }
        if(e.target.name == "DEI_Programmingdata"){
            this.setState({
                valid_DEI_Programming:"",
                DEI_Programming:e.target.value
            });
        }
        if(e.target.name == "DEI-Training2"){
            this.setState({
                valid_DEI_Training:"",
                DEI_Training:e.target.value
            });
        }
    }

    Save_company_data(event){
        event.preventDefault();
        let log = false;

        if(this.state.DEI_Lead_or_Team == null){
            this.setState({ valid_DEI_Lead_or_Team: "Please enter a DEI Information" });
            log = true;
        }

        if(this.state.Resources_or_Cultural_Affinity == null){
            this.setState({ valid_Resources_or_Cultural_Affinity: "Please enter a DEI Information" });
            log = true;
        }

        if(this.state.DEI_Programming == null){
            this.setState({ valid_DEI_Programming: "Please enter a DEI Information" });
            log = true;
        }

        if(this.state.DEI_Training == null){
            this.setState({ valid_DEI_Training: "Please enter a DEI Information" });
            log = true;
        }

        if(log === true){ 
            return 
        }

        this.props.saveEvent(this.state.DEI_Lead_or_Team,this.state.Resources_or_Cultural_Affinity,this.state.DEI_Programming,this.state.DEI_Training);
        this.props.clickEvent("stap8");
    }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            // $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            // $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data8">
                <div class="col-lg-6"></div> 
                <div class="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div class="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div class="Singup_stap_6_progress" style={{width:"39.25%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 25px 0 0"}} ></div>
                        <div class="singup_box_inner">
                            <div class="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 8% 8%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>DEI Information</p>
                                </div>      
                            </div>
                            <div class="singup_box-singup-stap-2" style={{padding:"0% 8% 6%",float:"left",width:"100%"}}>
                                <form className="stap_8_singup" style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data.bind(this)}>
                                    <div className="singup_input" style={{float:"left",width:"100%"}}>
                                        <label>Does your company have a DEI Lead or Team?</label>
                                        <div className="checkboxsingup">
                                            <div class="form-check">
                                                <input checked={this.state.DEI_Lead_or_Team == 1} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="Lead_or_Team" id="Lead" />
                                                <label className="form-check-label" for="Lead"> Yes </label>
                                            </div>
                                            <div className="form-check">
                                                <input checked={this.state.DEI_Lead_or_Team == 0} value="0" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="Lead_or_Team" id="Team" />
                                                <label className="form-check-label" for="Team"> No  </label>
                                            </div>
                                        </div>
                                        <span className="form_validtion_class">{this.state.valid_DEI_Lead_or_Team}</span>
                                    </div>
                                    <div className="singup_input" style={{float:"left",width:"100%"}}>
                                        <label>Does your company offer Employee Resources Groups or Cultural Affinity Groups?</label>
                                        <div className="checkboxsingup">
                                            <div class="form-check">
                                                <input checked={this.state.Resources_or_Cultural_Affinity == 1} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="flexRadioDefault" id="Affinity" />
                                                <label className="form-check-label" for="Affinity"> Yes </label>
                                            </div>
                                            <div className="form-check">
                                                <input checked={this.state.Resources_or_Cultural_Affinity == 0} value="0" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="flexRadioDefault" id="Groups" />
                                                <label className="form-check-label" for="Groups"> No  </label>
                                            </div>
                                        </div>
                                        <span className="form_validtion_class">{this.state.valid_Resources_or_Cultural_Affinity}</span>
                                    </div>
                                    <div className="singup_input" style={{float:"left",width:"100%"}}>
                                        <label>Does your company offer DEI Programming?</label>
                                        <div className="checkboxsingup">
                                            <div class="form-check">
                                                <input checked={this.state.DEI_Programming == 1} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" />
                                                <label className="form-check-label" for="DEI_Programming"> Yes </label>
                                            </div>
                                            <div className="form-check">
                                                <input checked={this.state.DEI_Programming == 0} value="0" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming1" />
                                                <label className="form-check-label" for="DEI_Programming1"> No  </label>
                                            </div>
                                        </div>
                                        <span className="form_validtion_class">{this.state.valid_DEI_Programming}</span>
                                    </div>
                                    <div className="singup_input" style={{float:"left",width:"100%"}}>
                                        <label>Does your company offer DEI Training?</label>
                                        <div className="checkboxsingup">
                                            <div class="form-check">
                                                <input checked={this.state.DEI_Training == 1} value="1" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI-Training2" id="DEI-Training" />
                                                <label className="form-check-label" for="DEI-Training"> Yes </label>
                                            </div>
                                            <div className="form-check">
                                                <input checked={this.state.DEI_Training == 0} value="0" onChange={this.handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI-Training2" id="DEI-Training1" />
                                                <label className="form-check-label" for="DEI-Training1"> No  </label>
                                            </div>
                                        </div>
                                        <span className="form_validtion_class">{this.state.valid_DEI_Training}</span>
                                    </div>
                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "12% 0 0"}}>
                                        <div className="back_wapper">
                                            <button type="button"  onClick={this.gotobackStap.bind(this)}> Back </button>
                                        </div>
                                        <button type="button" onClick={this.Save_company_data.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                    </div>
                                    {/* <div className="back_wapper" style={{textAlign:"center",marginTop:"5%", float:"left",width:"100%"}}>
                                        <button onClick={this.gotonextStap.bind(this)} type="button" style={{background: "transparent",border: "none",color: "teal" , fontSize:"20px"}}> Skip This </button>
                                    </div> */}
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Singup_stap_8;