import React, { Component } from "react";

import LeftSideBar from "../AccountCreation/sidebar/leftside";
import Header from "../AccountCreation/header/Header";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Nav, Tab, Row, Modal } from "react-bootstrap";
import Add_new_user from "../assets/image/Add_new_user.png";
import About_Team_Members from "./About_Team_Members";
import Team_Members_single_post from "./Team_Members_single_post";
import Alert from "react-bootstrap/Alert";

//Add Tabs
class Team_members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "Team_Members",
            List_Of_all_Post: [],
            List_Of_all_Accepted_team: [],
            List_Of_Invited: [],
            active_id: 0,
            profiledata: [],
            //
            profile_image: "",
            name: "",
            job_title: "",
            email: "",
            phone: "",
            Team_Members: [],
            Full_name: "",
            Email: "",
            valition_Full_name: "",
            valition_Email: "",
            showerror: false,
            showerrortype: 0,
            showerrormessage: "",
            change_post: true,
            selectTab: "Accepted",
            search_value: "",
            search_value_Accepted: "",
            search_value_Pending: "",
            search_value_Invited: "",
        };
        this.get_all_data();
        this.get_all_data_Accepted();
        this.get_all_data_Invited();

        this.Full_name = React.createRef();
        this.Email = React.createRef();

        localStorage.setItem("serach_key", "");
    }

    // list of Pending list account_status = 4
    get_all_data() {
        const apiOption = {
            method: "GET",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        fetch(
            this.props.APIPath + "company_team_members?off_set=0&account_status=4",
            apiOption
        )
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                let Manin_data_wrap = resJson.data;
                let data_applicat_user = Manin_data_wrap[0];
                let getFilst_id = Manin_data_wrap[0].user_id;
                this.get_all_data_by_post_id(getFilst_id);
                this.state.profiledata = data_applicat_user;
                this.setState({ profiledata: data_applicat_user });
                this.state.active_id = getFilst_id;
                this.setState({ active_id: getFilst_id });
                this.state.List_Of_all_Post = Manin_data_wrap;
                this.setState({ List_Of_all_Post: this.state.List_Of_all_Post });
                this.setState({ change_post: true });
            });
    }

    get_all_data_by_post_id(data) {
        setTimeout(
            function () {
                const apiOption1 = {
                    method: "Post",
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ user_id: data }),
                };

                fetch(this.props.APIPath + "getProfileById?job_id=" + data, apiOption1)
                    .then(function (res) {
                        return res.json();
                    })
                    .then((resJson) => {
                        this.state.active_id = data;
                        this.setState({ active_id: this.state.active_id });
                        let Manin_data_wrap = resJson.data;
                        let profile_image_data = Manin_data_wrap.profile_image;
                        this.state.profile_image = profile_image_data;
                        this.setState({ profile_image: this.state.profile_image });
                        let name = Manin_data_wrap.name;
                        this.setState({ name: name });
                        let job_title = Manin_data_wrap.job_title;
                        this.setState({ job_title: job_title });
                        let email = Manin_data_wrap.email;
                        this.setState({ email: email });
                        let phone = Manin_data_wrap.phone;
                        this.setState({ phone: phone });
                        this.setState({ change_post: true });
                    });
            }.bind(this),
            500
        );
    }

    Get_list_of_pandding() {
        if (this.state.List_Of_all_Post.length != 0) {
            return (
                <>
                    <div
                        id='scrollableDiv_List_Of_all_Post'
                        style={{ overflowX: "hide" }}>
                        <InfiniteScroll
                            dataLength={this.state.List_Of_all_Post.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={""}
                            scrollableTarget='scrollableDiv_List_Of_all_Post'>
                            {this.state.List_Of_all_Post.map((title, index) => (
                                <Team_Members_single_post
                                    list_invited={"pandding"}
                                    get_reload={this.get_all_data.bind(this)}
                                    key={index}
                                    {...title}
                                    {...this.props}
                                    get_user_id={this.get_all_data_by_post_id.bind(this)}
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                </>
            );
        } else {
            return "This list is empty";
        }
    }

    get_all_data_Invited() {
        const apiOption = {
            method: "GET",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        fetch(this.props.APIPath + "invited_members", apiOption)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                let Manin_data_wrap = resJson.data;
                let data_applicat_user = Manin_data_wrap[0];
                let getFilst_id = Manin_data_wrap[0].user_id;
                this.get_all_data_by_post_id(getFilst_id);
                this.state.profiledata = data_applicat_user;
                this.setState({ profiledata: data_applicat_user });
                this.state.active_id = getFilst_id;
                this.setState({ active_id: getFilst_id });
                this.setState({ List_Of_Invited: Manin_data_wrap });
            });
    }
    Get_list_of_invited() {
        if (this.state.List_Of_Invited.length !== 0) {
            // Create a Set to store unique emails
            const uniqueEmails = new Set();

            return (
                <>
                    <div id='scrollableDiv_List_Of_Invited' style={{ overflowX: "hide" }}>
                        <InfiniteScroll
                            dataLength={this.state.List_Of_Invited.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={""}
                            scrollableTarget='scrollableDiv_List_Of_Invited'>
                            {this.state.List_Of_Invited.map((title, index) => {
                                // Check if the email is already in the uniqueEmails set
                                if (!uniqueEmails.has(title.email)) {
                                    // Add the email to the set
                                    uniqueEmails.add(title.email);

                                    return (
                                        <Team_Members_single_post
                                            list_invited={"invited"}
                                            get_reload={this.get_all_data_Invited.bind(this)}
                                            key={index}
                                            {...title}
                                            {...this.props}
                                            get_user_id={this.get_active_data_id.bind(this)}
                                        />
                                    );
                                } else {
                                    return null; // Skip rendering for duplicate email
                                }
                            })}
                        </InfiniteScroll>
                    </div>
                </>
            );
        } else {
            return "This list is empty";
        }
    }

    // Get_list_of_invited() {
    //     if (this.state.List_Of_Invited.length != 0) {
    //         return (
    //             <>
    //                 <div id='scrollableDiv_List_Of_Invited' style={{ overflowX: "hide" }}>
    //                     <InfiniteScroll
    //                         dataLength={this.state.List_Of_Invited.length}
    //                         next={this.fetchMoreData}
    //                         hasMore={true}
    //                         loader={""}
    //                         scrollableTarget='scrollableDiv_List_Of_Invited'>
    //                         {this.state.List_Of_Invited.map((title, index) => (
    //                             <Team_Members_single_post
    //                                 list_invited={"invited"}
    //                                 get_reload={this.get_all_data_Invited.bind(this)}
    //                                 key={index}
    //                                 {...title}
    //                                 {...this.props}
    //                                 get_user_id={this.get_active_data_id.bind(this)}
    //                             />
    //                         ))}
    //                     </InfiniteScroll>
    //                 </div>
    //             </>
    //         );
    //     } else {
    //         return "This list is empty";
    //     }
    // }

    // list of Accepted list account_status = 1
    get_all_data_Accepted() {
        const apiOption = {
            method: "GET",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        fetch(
            this.props.APIPath + "company_team_members?off_set=0&account_status=1",
            apiOption
        )
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                let Manin_data_wrap = resJson.data;
                let getFilst_id = Manin_data_wrap.user_id;
                let getFilst_id_data = Manin_data_wrap[0].user_id;
                this.get_all_data_by_post_id(getFilst_id_data);

                this.state.active_id = getFilst_id;
                this.setState({ active_id: getFilst_id });
                this.state.List_Of_all_Accepted_team = Manin_data_wrap;
                this.setState({
                    List_Of_all_Accepted_team: this.state.List_Of_all_Accepted_team,
                });
            });
    }

    Get_list_of_Accepted_team() {
        if (this.state.List_Of_all_Accepted_team.length != 0) {
            return (
                <>
                    <div id='scrollableDiv' style={{ overflowX: "hide" }}>
                        <InfiniteScroll
                            dataLength={this.state.List_Of_all_Accepted_team.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={""}
                            scrollableTarget='scrollableDiv'>
                            {this.state.List_Of_all_Accepted_team.map((title, index) => (
                                <Team_Members_single_post
                                    list_invited={"Accepted_team"}
                                    active_id={this.state.active_id}
                                    get_reload={this.get_all_data_Accepted.bind(this)}
                                    key={index}
                                    {...title}
                                    {...this.props}
                                    get_user_id={this.get_active_data_id.bind(this)}
                                />
                            ))}
                        </InfiniteScroll>
                    </div>
                </>
            );
        } else {
            return "This list is empty";
        }
    }

    seleactdata(data) {
        this.searchHandler(data);
    }

    searchHandler(data) {
        localStorage.setItem("serach_key", data);
        if (this.state.selectTab == "Accepted") {
            this.setState({ search_value_Accepted: data });
            let searcjQery = data.toLowerCase(),
                displayedContacts = this.state.List_Of_all_Accepted_team.filter(
                    (el) => {
                        let searchValue = el.job_title.toLowerCase();
                        return searchValue.indexOf(searcjQery) !== -1;
                    }
                );

            if (data.length == 0) {
                this.get_all_data_Accepted();
            }
            if (data.length !== 0) {
                this.setState({ List_Of_all_Accepted_team: displayedContacts });
            }
        }

        if (this.state.selectTab == "Pending") {
            this.setState({ search_value_Pending: data });
            let searcjQery = data.toLowerCase(),
                displayedContacts = this.state.List_Of_all_Post.filter((el) => {
                    let searchValue = el.job_title.toLowerCase();
                    return searchValue.indexOf(searcjQery) !== -1;
                });

            if (data.length == 0) {
                this.get_all_data();
            }
            if (data.length !== 0) {
                this.setState({ List_Of_all_Post: displayedContacts });
            }
        }

        if (this.state.selectTab == "Invited") {
            this.setState({ search_value_Invited: data });

            let searcjQery = data.toLowerCase(),
                displayedContacts = this.state.List_Of_Invited.filter((el) => {
                    let searchValue = el.email.toLowerCase();
                    return searchValue.indexOf(searcjQery) !== -1;
                });

            if (data.length == 0) {
                this.get_all_data_Invited();
            }
            if (data.length !== 0) {
                this.setState({ List_Of_Invited: displayedContacts });
            }
        }
    }

    handleKeyPress(e) {
        if (e.target.name == "Email_Address") {
            let re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.setState({ Email: e.target.value });
            if (re.test(e.target.value)) {
                this.setState({
                    valition_Email: "",
                });
            } else {
                this.setState({ valition_Email: "Please enter a valid email address" });
            }
        }

        if (e.target.name == "Full_name") {
            this.setState({
                valition_Full_name: "",
                Full_name: e.target.value,
            });
        }
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    addNewFild(event) {
        event.preventDefault();
        let log = false;
        if (!this.Full_name.current.value) {
            this.setState({ valition_Full_name: "Please enter a full name" });
            log = true;
        }
        if (!this.Email.current.value) {
            this.setState({ valition_Email: "Please enter a email" });
            log = true;
        }

        if (log == true) {
            return;
        }
        let Full_name = this.Full_name.current.value;
        let Email = this.Email.current.value;

        let invitations = [{ name: Full_name, email: Email }];
        const apiOption = {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                invitations: invitations,
            }),
        };

        fetch(this.props.APIPath + "send_invites", apiOption)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                if (resJson.status == true) {
                    this.setState({
                        showerror: true,
                        showerrortype: 1,
                        showerrormessage: " Invites is send for new team member",
                    });
                    this.get_all_data_Invited();
                    this.error_handaling();
                    this.closeModal();
                } else {
                    this.setState({
                        showerror: true,
                        showerrortype: 2,
                        showerrormessage: "Something wrong with api fields",
                    });
                    this.error_handaling();
                }
            })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handaling();
            });
    }

    error_handaling() {
        if (this.state.showerror == true) {
            setTimeout(
                function () {
                    this.setState({ showerror: false });
                }.bind(this),
                3000
            );

            if (this.state.showerrortype == 1) {
                setTimeout(
                    function () {
                        this.setState({ redirect: true });
                    }.bind(this),
                    3100
                );
            }

            return (
                <>
                    <Alert
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            width: "100%",
                            float: "left",
                            textAlign: "center",
                        }}
                        variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            );
        }
    }

    handleSelect(eventKey) {
        if (eventKey == "Accepted") {
            this.get_all_data_Accepted();
            this.setState({ change_post: false, selectTab: "Accepted" });

            this.setState({ search_value: this.state.search_value_Accepted });
            localStorage.setItem("serach_key", this.state.search_value_Accepted);

            // this.Set_header();
        }

        if (eventKey == "Pending") {
            this.get_all_data();
            this.setState({ change_post: false, selectTab: "Pending" });

            this.setState({ search_value: this.state.search_value_Pending });
            localStorage.setItem("serach_key", this.state.search_value_Pending);
        }

        if (eventKey == "Invited") {
            this.get_all_data_Invited();
            this.setState({ selectTab: "Invited" });

            this.setState({ search_value: this.state.search_value_Invited });
            localStorage.setItem("serach_key", this.state.search_value_Invited);
        }
    }

    get_active_data_id(data) {
        this.state.active_id = data;
        this.setState({ active_id: this.state.active_id });
        this.get_all_data_by_post_id(data);
        this.setState({ change_post: false });
    }

    fetchMoreData() {
        if (this.state.selectTab == "Accepted") {
            let search_listed = this.state.search_value_Accepted;
            const apiOption = {
                method: "GET",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };

            var link =
                this.props.APIPath + "company_team_members?off_set=0&account_status=1";
            if (search_listed != "") {
                link =
                    this.props.APIPath +
                    "company_team_members?off_set=0&account_status=1&search=" +
                    search_listed;
            }
            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    let Manin_data_wrap = resJson.data;
                    if (search_listed.length !== 0) {
                        let searcjQery = search_listed.toLowerCase(),
                            displayedContacts = Manin_data_wrap.filter((el) => {
                                let searchValue = el.job_title.toLowerCase();
                                return searchValue.indexOf(searcjQery) !== -1;
                            });
                        this.setState({
                            List_Of_all_Accepted_team:
                                this.state.List_Of_all_Accepted_team.concat(displayedContacts),
                        });
                    }
                    if (search_listed.length == 0) {
                        this.get_all_data();
                    }
                });
        }
        if (this.state.selectTab == "Pending") {
            let search_listed = this.state.search_value_Pending;
            const apiOption = {
                method: "GET",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // body:JSON.stringify({}),
            };
            var link =
                this.props.APIPath + "company_team_members?off_set=0&account_status=4";
            if (search_listed != "") {
                link =
                    this.props.APIPath +
                    "company_team_members?off_set=0&account_status=4&search=" +
                    search_listed;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    let Manin_data_wrap_filled = resJson.data;
                    if (search_listed.length !== 0) {
                        this.state.List_Of_all_Post = Manin_data_wrap_filled;
                        this.setState({ List_Of_all_Post: this.state.List_Of_all_Post });

                        let searcjQery = search_listed.toLowerCase(),
                            displayedContacts = this.state.List_Of_all_Post.filter((el) => {
                                let searchValue = el.job_title.toLowerCase();
                                return searchValue.indexOf(searcjQery) !== -1;
                            });

                        this.setState({
                            List_Of_all_Post:
                                this.state.List_Of_all_Post.concat(displayedContacts),
                        });
                    }

                    if (search_listed.length == 0) {
                        this.get_all_data();
                    }
                });
        }
        if (this.state.selectTab == "Invited") {
            let search_listed = this.state.search_value_Invited;
            const apiOption = {
                method: "GET",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // body:JSON.stringify({}),
            };

            var link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=0";
            if (this.state.search_value_Closed != "") {
                link =
                    this.props.APIPath +
                    "my_jobs_list?job_status=2&off_set=0&search=" +
                    this.state.search_value_Closed;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    let Manin_data_wrap_closed = resJson.data;
                    if (search_listed.length !== 0) {
                        let searcjQery = search_listed.toLowerCase(),
                            displayedContacts = Manin_data_wrap_closed.filter((el) => {
                                let searchValue = el.email.toLowerCase();
                                return searchValue.indexOf(searcjQery) !== -1;
                            });
                        this.setState({
                            List_Of_Invited:
                                this.state.List_Of_Invited.concat(displayedContacts),
                        });
                    }
                    if (search_listed.length == 0) {
                        this.get_all_data_closed();
                    }
                });
        }
    }

    render() {
        return (
            <>
                {this.error_handaling()}
                <div className='wapper' style={{ backgroud: "orange" }}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <LeftSideBar meta={this.state.data} />
                            <div
                                className='col-md-12 col-lg-12 col-xl-9 col-xxl-10'
                                style={{ padding: "0 0 0 30px", height: "100%" }}>
                                <Header
                                    search_value={this.state.search_value}
                                    search_data={this.seleactdata.bind(this)}
                                    apilink={this.props.APIPath}
                                />
                                <div className='all_post_main  Tean_members_main Tean_members_main_boxed'>
                                    <Tab.Container
                                        id='left-tabs-example'
                                        defaultActiveKey='Accepted'>
                                        <Row>
                                            <Col
                                                sm={8}
                                                className='d-flex justify-content-between align-items-center'>
                                                <Nav
                                                    variant='pills'
                                                    className='flex-row'
                                                    onSelect={this.handleSelect.bind(this)}>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey='Accepted'>Accepted</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey='Pending'>Pending</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey='Invited'>Invited</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <a
                                                    href='#'
                                                    onClick={this.openModal.bind(this)}
                                                    className='View-Flagged'>
                                                    <img src={Add_new_user} />
                                                </a>
                                            </Col>
                                            <Col sm={4} style={{ paddingRight: "0" }}></Col>
                                            <Col sm={12} style={{ paddingRight: "0" }}>
                                                <Tab.Content>
                                                    <Tab.Pane
                                                        eventKey='Accepted'
                                                        className='Accepted-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div
                                                                className='col-sm-8 col-md-7 col-xl-7 col-xl-7 col-xxl-8'
                                                                style={{ padding: "0" }}>
                                                                {this.Get_list_of_Accepted_team()}
                                                            </div>
                                                            <div
                                                                className='col-sm-4 col-md-5 col-xl-5 col-xxl-4'
                                                                style={{ paddingRight: "0" }}>
                                                                {this.state.change_post == true ? (
                                                                    <>
                                                                        {this.state.List_Of_all_Accepted_team
                                                                            .length != 0 ? (
                                                                            <>
                                                                                <About_Team_Members
                                                                                    profile_imag={
                                                                                        this.state.profile_image
                                                                                    }
                                                                                    name={this.state.name}
                                                                                    job_title={this.state.job_title}
                                                                                    email={this.state.email}
                                                                                    phone={this.state.phone}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane
                                                        eventKey='Pending'
                                                        className='Pending-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div
                                                                className='col-sm-8 col-md-7 col-xl-7 col-xxl-8'
                                                                style={{ padding: "0" }}>
                                                                {this.Get_list_of_pandding()}
                                                            </div>
                                                            <div
                                                                className='col-sm-4 col-md-5 col-md-5 col-xl-5 col-xxl-4'
                                                                style={{ paddingRight: "0" }}>
                                                                {this.state.change_post == true ? (
                                                                    <>
                                                                        <About_Team_Members
                                                                            profile_imag={this.state.profile_image}
                                                                            name={this.state.name}
                                                                            job_title={this.state.job_title}
                                                                            email={this.state.email}
                                                                            phone={this.state.phone}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane
                                                        eventKey='Invited'
                                                        className='Invited-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div
                                                                className='col-sm-8 col-md-7 col-xl-7 col-xxl-8'
                                                                style={{ padding: "0" }}>
                                                                {this.Get_list_of_invited()}
                                                            </div>
                                                            <div
                                                                className='col-sm-4 col-md-5 col-xl-5 col-xxl-4'
                                                                style={{ paddingRight: "0" }}>
                                                                {/* <About_Team_Members profile_imag={ this.state.profile_image} name={this.state.name} job_title={this.state.job_title}  email={this.state.email} phone={this.state.phone}  /> */}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    className='new_team_member_box_main'
                    show={this.state.isOpen}
                    onHide={this.closeModal}>
                    <Modal.Body>
                        <h3>New Team Member</h3>
                        <div className='new_team_member_box_inner'>
                            <form onSubmit={this.addNewFild.bind(this)}>
                                <div
                                    className='singup_input'
                                    style={{ float: "left", width: "100%" }}>
                                    <label
                                        style={{ float: "left", width: "100%", fontWeight: "500" }}>
                                        Full Name
                                    </label>
                                    <input
                                        onChange={this.handleKeyPress.bind(this)}
                                        type='text'
                                        placeholder='Full Name'
                                        ref={this.Full_name}
                                        name='Full_name'
                                    />
                                    <span className='form_validtion_class'>
                                        {this.state.valition_Full_name}
                                    </span>
                                </div>
                                <div
                                    className='singup_input'
                                    style={{ float: "left", width: "100%" }}>
                                    <label
                                        style={{ float: "left", width: "100%", fontWeight: "500" }}>
                                        Email Address
                                    </label>
                                    <input
                                        onChange={this.handleKeyPress.bind(this)}
                                        type='text'
                                        placeholder='Email Address'
                                        ref={this.Email}
                                        name='Email_Address'
                                    />
                                    <span className='form_validtion_class'>
                                        {this.state.valition_Email}
                                    </span>
                                </div>
                                <div
                                    className='singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper'
                                    style={{ textAlign: "center", padding: "5% 0 0" }}>
                                    <div className='back_wapper'>
                                        <button type='button' onClick={this.closeModal.bind(this)}>
                                            {" "}
                                            Cancel{" "}
                                        </button>
                                    </div>
                                    <button
                                        onClick={this.addNewFild.bind(this)}
                                        type='button'
                                        className='singup_input_button singup_setp-3-button'>
                                        {" "}
                                        Add{" "}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default Team_members;
