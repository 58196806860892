import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

function  TagsInput (props)  {
	const [tags, setTags] = React.useState([]);
  
	const handleDelete = i => {
	  setTags(tags.filter((tag, index) => index !== i));
	};
  
	const handleAddition = tag => {
	  setTags([...tags, tag]);
	};
  
	const handleDrag = (tag, currPos, newPos) => {
	  const newTags = tags.slice();
  
	  newTags.splice(currPos, 1);
	  newTags.splice(newPos, 0, tag);
  
	  // re-render
	  setTags(newTags);
	};
  
	const handleTagClick = index => {
	
	};
  
	return (
	  	<ReactTags
			tags={tags}
			handleDelete={handleDelete}
			handleAddition={handleAddition}
			handleDrag={handleDrag}
			handleTagClick={handleTagClick}
			inputFieldPosition="bottom"
			autocomplete
		/>
		
	  
	);
  };
export default TagsInput; 