import React, { Component } from 'react';
import team_user_bg from '../assets/image/Rectangle_962.png';
import Usadre from '../assets/image/Rectangle 562.png';
import airbnb from '../assets/image/Ellipse_332_no@2x.png';

//Add Tabs
class About_Team_Members extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.job_title !== "" ? <>
                    <div className='about_team_Members_main'>
                        <div className='about_team_Members_bg_img'>
                            <img src={team_user_bg} />
                        </div>
                        <div className='about_team_Members_about_us'>
                            <div className='team_Members_Profile'>
                                <span className='user_profile'> <img style={{width:"125px",height:"125px",borderRadius:"50%"}} src={ this.props.profile_imag != "" ? this.props.profile_imag : airbnb }  /> </span>
                                <h3>{this.props.name}</h3>
                                <span>{this.props.job_title}</span>
                            </div>
                            <div className="Contact-Information">
                                <span className='countat_info_title'>Contact Information</span>
                                <span  className='countat_info_Email'>Email</span>
                                <span>{this.props.email}</span>
                            </div>
                        </div>
                        <div className='team_members_phone'>
                            <span>Phone Number</span>
                            <div className='teamusa_members_phone-main'>
                                <span><img src={Usadre} /></span>
                                <p>{this.props.phone}</p>
                            </div>
                        </div>
                    </div>
                </> : "" } 
            </>
        );
    }
}
export default About_Team_Members;