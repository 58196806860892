import React, { Component } from 'react';
import SelectIndustryList from './Select2_industry_list';
import Alert from 'react-bootstrap/Alert';
import { Modal } from "react-bootstrap";
import { formControlUnstyledClasses } from '@mui/base';
import Edit_icone from '../../assets/image/pen-to-square-solid.png';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

class Contact_us_information extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Name : "",
            validet_Name:"",
            
            Message_text: "",
            validet_Message_text:"",

            Message_Content: "",
            validet_Message_Content:"",
        }

        this.Name =  React.createRef();
        this.Message_text =  React.createRef();
        this.Message_Content =  React.createRef(); 
    }

    handleKeyPress(e){
        if(e.target.name == "Name"){
            this.setState({validet_Name:"",Name:e.target.value});
        }
        if(e.target.name == "Message_Title"){
            this.setState({validet_Message_text:"",Message_text:e.target.value});
        }
        if(e.target.name == "Message_Content"){
            this.setState({validet_Message_Content:"",Message_Content:e.target.value});
        }
    }

  
    Save_company_data(data){
        let log = false;
        if(!this.Name.current.value){
            this.setState({ validet_Name: "Please enter a name" });
            log = true;
        }
    
        if(!this.Message_text.current.value) {
            this.setState({ validet_Message_text: "Please enter a message title" });
            log = true;
        }
    
        if(!this.Message_Content.current.value) {
            this.setState({ validet_Message_Content: "Please enter a message contact" });
            log = true;
        }

        if(log === true){ 
            return 
        }
        
        var formdata = new FormData();
        formdata.append("name", this.state.Name);
        formdata.append("title", this.Message_text.current.value);
        formdata.append("description", this.Message_Content.current.value);
         
        const apiOption2 = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+localStorage.getItem("token"),  
            },
            body: formdata,
            redirect: 'follow'
        }
   
        fetch(this.props.apiPath+"contactus",apiOption2)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Email sent to admin" });
                this.error_handaling();
            }else{
                // this.setState({ valid_email_address: "Email address is already registered" });
                this.setState({showerror:true , showerrortype : 2 , showerrormessage: resJson.message});
                this.error_handaling();
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    error_handaling(){
        if(this.state.showerror == true){
            setTimeout(function() {
               this.setState({showerror: false});
            }.bind(this),3000); 

            if(this.state.showerrortype == 1){
                setTimeout(function() {
                    this.setState({redirect: true});
                }.bind(this),3100);     
            }

            return(
                <>
                    <Alert style={{position:"absolute",top:"0",left:"0",right:"0", width:"100%", float:"left", textAlign:"center"}} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    render() {
        return (
            <>
                {this.error_handaling()}
                <div className='tab_inner_contanet contact_us_information_main'>
                    <h3>Contact US </h3>
                    <div className='contact_us_information_form'>
                        <div className="singup_input">
                            <label>Name</label>
                            <input readonly onChange={this.handleKeyPress.bind(this)}  value={localStorage.getItem('user_name')} /*value={this.state.Name}*/ ref={this.Name} type="text" name="Name" />
                            <span className="form_validtion_class"> {this.state.validet_Name} </span>
                        </div>
                        <div className="singup_input">
                            <label>Message title</label>
                            <input onChange={this.handleKeyPress.bind(this)}  value={this.state.Message_text} ref={this.Message_text} type="text" name="Message_Title" />
                            <span className="form_validtion_class"> {this.state.validet_Message_text} </span>
                        </div>
                        <div className="singup_input">
                            <label>Message Content</label>
                            <textarea onChange={this.handleKeyPress.bind(this)} value={this.state.Message_Content} ref={this.Message_Content}  maxlength="1200"  name="Message_Content" ></textarea>
                            <span className="form_validtion_class"> {this.state.validet_Message_Content} </span>
                        </div>
                        <div className="singup_input" style={{textAlign:"center", marginBottom:"0"}}>
                            <button type="button" onClick={this.Save_company_data.bind(this)}  className="singup_input_button"> Send </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Contact_us_information;