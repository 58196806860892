import React from 'react';
// import Select from 'react-select';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



function  SelectSkillsList (props)  {
  const options = [];
  const selected_options = [];

  props.selected_value.map(function(element){
    selected_options.push(element)
  });

  // selected_options
  props.skillsList.map(function(element){
    options.push(
      {
        value: element.id, label: element.name
      }
    )
  });

  const userIdHandler = (e) => {
    props.getvaluedata(e);
  }

  return(
    <>
      {props.selected_value?
        <Autocomplete className='country-select-demo2'
          multiple
          id="country-select-demo2"
          options={options.map((option) => option.label)}
          onChange={(event, value) => {userIdHandler(value)}}

            defaultValue={selected_options}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField style={{border:'none !important'}}
                {...params}
                variant="filled"
                label="freeSolo"
                placeholder="Skills"
              />
        )}
      />
      :
      <Autocomplete className='country-select-demo2'
          multiple
          id="country-select-demo2"
          options={options.map((option) => option.label)}
          onChange={(event, value) => {userIdHandler(value.label)}}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField style={{border:'none !important'}}
                {...params}
                variant="filled"
                label="freeSolo"
                placeholder="Skills"
              />
        )}
      />
      }
      
      </>
  );
}

export default SelectSkillsList;