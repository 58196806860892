// import LeftSideBar from "../sidebar/leftside";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

//Images
import logo from "../../assets/image/logo.png";
import company from "../../assets/image/company.png";
import team from "../../assets/image/team.png";

class Team_member_stap1 extends Component {
    constructor(props) {
        super(props);
        // let navigate = useNavigate();
        var initialItems_data = [];
        this.state = {
            initialItems: [],
            State: "",
            items: [],
            onclickState: 0,
            get_search_value: "",
            selected_id: 0,
            selected_value: [],
            input_value_data: "",
            search_key: "",
            offset: 0,
        };

        // get list of company size
        const apiOption = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        fetch(
            this.props.apiPath +
            "companies?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",
            apiOption
        )
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                this.setState({
                    initialItems: resJson.data,
                    items: resJson.data,
                });
            });
    }

    fetchMoreData = () => {
        this.state.offset = this.state.offset + 15;
        this.setState({ offset: this.state.offset });

        if (this.state.search_key != "") {
            const apiOption = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            fetch(
                this.props.apiPath +
                "companies?off_set=" +
                this.state.offset +
                "&search=" +
                this.state.search_key +
                "&api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",
                apiOption
            )
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    let initialItems = resJson.data;
                    var temp_initialItems = this.state.items;
                    initialItems.map((elements) => {
                        temp_initialItems.push(elements);
                    });
                    this.setState({
                        items: temp_initialItems,
                        initialItems: temp_initialItems,
                    });
                });
        } else {
            const apiOption = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            fetch(
                this.props.apiPath +
                "companies?off_set=" +
                this.state.offset +
                "&api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",
                apiOption
            )
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    let initialItems = resJson.data;
                    var temp_initialItems = this.state.items;
                    initialItems.map((elements) => {
                        temp_initialItems.push(elements);
                    });
                    this.setState({
                        items: temp_initialItems,
                        initialItems: temp_initialItems,
                    });
                });
        }
    };

    get_id(e) {
        this.setState({ search_key: e.target.value });

        if (e.target.value != "") {
            this.state.offset = this.state.offset + 0;
            this.setState({ offset: this.state.offset });
            const apiOption = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            fetch(
                this.props.apiPath +
                "companies?search=" +
                e.target.value +
                "&api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",
                apiOption
            )
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    this.setState({
                        initialItems: resJson.data,
                        items: resJson.data,
                    });

                    const updatedList = this.state.initialItems.filter(function (item) {
                        return (
                            item.name.toLowerCase().search(e.target.value.toLowerCase()) !==
                            -1
                        );
                    });
                    if (updatedList.length == 0) {
                        this.setState({ input_value_data: e.target.value, items: [] });
                    } else {
                        this.setState({ items: updatedList });
                    }
                });
        } else {
            const apiOption = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            };
            fetch(
                this.props.apiPath +
                "companies?api_key=48e6c87a_6760_4b07_b987_7128fbea8545_1db5",
                apiOption
            )
                .then(function (res) {
                    return res.json();
                })
                .then((resJson) => {
                    this.setState({
                        initialItems: resJson.data,
                        items: resJson.data,
                    });
                    this.setState({
                        items: this.state.initialItems,
                        input_value_data: "",
                    });
                });
        }

        // if(e.target.value != ""){
        //     const updatedList = this.state.initialItems.filter(function(item){
        //         return item.name.toLowerCase().search(
        //         e.target.value.toLowerCase()) !== -1;
        //     });
        //     if(updatedList.length == 0){
        //         this.setState({input_value_data:e.target.value,items:[]})
        //     }else{
        //         this.setState({items:updatedList});
        //     }
        // }else{
        //     this.setState({items:this.state.initialItems,input_value_data:""});
        // }
    }

    backtocompany() {
        this.props.clickEvent("stap1");
    }

    send_to_next(user_id) {
        this.setState({ selected_id: user_id });
        this.props.savedata(user_id);
        this.props.clickEvent("Team_member_stap2");
    }

    componentWillMount() {
        this.setState({ items: this.state.initialItems });
    }

    render() {
        return (
            <div className='row signin_signup_background Team_member_stap_main'>
                <div className='col-lg-6'></div>
                <div
                    className='col-lg-6'
                    style={{
                        background: "#f9f9f9",
                        padding: "5% 5% 5% 5%",
                        textAlign: "center",
                    }}>
                    <div
                        className='singup_box Team_member_stap_1-inner_box'
                        style={{
                            background: "overlay",
                            background: "#FFF",
                            float: "left",
                            width: "100%",
                            height: "100%",
                            borderRadius: "15px",
                        }}>
                        <div className='progress-singup_box'></div>
                        <div className='singup_box_inner'>
                            <div
                                className='singup_logo'
                                style={{ textAlign: "center", paddingTop: "30px" }}>
                                <img src={logo} />
                            </div>
                            <div className='singup_team_membar_text'>
                                <span>Welcome!</span>
                                <h3>Let's Find Your Company</h3>
                            </div>
                            <div
                                className='main_of_the_all_list'
                                style={{ float: "left", width: "100%" }}>
                                <div
                                    className='header-search'
                                    style={{ backgroundImage: "../../assets/image/Search.png" }}>
                                    <input
                                        onChange={this.get_id.bind(this)}
                                        type='text'
                                        list='city_state_list'
                                        placeholder='Search for your company'
                                    />
                                    {/* <datalist id="city_state_list">
                                        {this.state.initialItems.map((item) =>
                                            <option key={item.name} value={item.name} data_id={item.id} >{item.name}</option>
                                        )}
                                    </datalist> */}
                                </div>
                                {this.state.items.length == 0 &&
                                    this.state.input_value_data != "" ? (
                                    <>
                                        {/* {this.state.items.length > 0? */}
                                        <div
                                            className='company_is_empty'
                                            style={{
                                                maxWidth: "70%",
                                                margin: "20px auto 0",
                                                display: "inline-block",
                                            }}>
                                            <h3 style={{ fontSize: "24px" }}>
                                                {" "}
                                                "{this.state.input_value_data}" Company Profile Not
                                                Found{" "}
                                            </h3>
                                            <div
                                                className='company_is_empty_image'
                                                style={{
                                                    float: "left",
                                                    width: "100%",
                                                    margin: "15px 0 30px",
                                                }}></div>
                                            <span
                                                style={{
                                                    float: "left",
                                                    width: "100%",
                                                    margin: "0 0 15px",
                                                }}>
                                                Please contact your company admin and request they make
                                                a company profile
                                            </span>
                                            <div className='company_is_empty_button'>
                                                <button
                                                    type='button'
                                                    onClick={this.backtocompany.bind(this)}
                                                    className='singup_input_button'
                                                    style={{ width: "280px" }}>
                                                    Create Company Profile
                                                </button>
                                            </div>
                                        </div>
                                        {/* : "" } */}
                                    </>
                                ) : (
                                    <>
                                        <div
                                            id='scrollableDiv'
                                            style={{
                                                display: "block",
                                                marginTop: "90px",
                                                height: "30vh",
                                                overflow: "auto",
                                            }}>
                                            <InfiniteScroll
                                                dataLength={this.state.items.length}
                                                next={this.fetchMoreData}
                                                hasMore={true}
                                                loader={""}
                                                scrollableTarget='scrollableDiv'>
                                                {this.state.items.map((itme) => (
                                                    <>
                                                        <div className='main_wapper_lst_data'>
                                                            <div className='compnay_logo'>
                                                                <img
                                                                    style={{
                                                                        width: "105px",
                                                                        height: "105px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    src={itme.profile_image}
                                                                />
                                                            </div>
                                                            <div className='compnay_text'>
                                                                <div className='compnay_inner_text'>
                                                                    <h3>{itme.name}</h3>
                                                                    <span>
                                                                        {itme.city} {itme.state}
                                                                    </span>
                                                                    {itme.active_members == 0 ? (
                                                                        <span>No Employees</span>
                                                                    ) : (
                                                                        <span>
                                                                            {itme.active_members}+ Employees
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className='Request_To_Join'>
                                                                    <button
                                                                        onClick={this.send_to_next.bind(
                                                                            this,
                                                                            itme.user_id
                                                                        )}
                                                                        type='button'>
                                                                        Request To Join
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </InfiniteScroll>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Team_member_stap1;
