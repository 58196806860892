import React, { Component } from "react";
import { Col, Nav, Tab, Row } from "react-bootstrap";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link,
    Navigate,
} from "react-router-dom";
import Alert from "react-bootstrap/Alert";

//header saidbar
import Header from "../AccountCreation/header/Header";
import LeftSideBar from "../AccountCreation/sidebar/leftside";
import About_post from "./About_post";
import Single_post from "./Single_post";
//Add Tabs
class List_of_flagged extends Component {
    constructor(props) {
        super(props);
        this.get_all_data();
        this.state = {
            data: "ALL_Post",
            temp_login: true,
            toggledata: "drop",
            List_Of_all_Post: [],
            List_Of_all_Post_Closed: [],
            List_Of_all_Post_filled: [],
            company: [],
            active_id: 0,
            // get data
            job_title: "",
            city: "",
            state: "",
            responsibilities: "",
            // company
            company_profile_img: "",
            company_name: "",
            // user data
            user_name: "",
            user_profile: "",
            //salary
            min_salary: "",
            max_salary: "",
            //industry
            industry: "",
            // applications
            total_applications: "",
            applications: [],
            applypost: false,
            //roal
            roles: "",
        };

        var token = localStorage.getItem("token");
        if (token == null) {
            this.state.temp_login = false;
        }
    }

    get_active_data_id(data) {
        this.state.active_id = data;
        this.setState({ active_id: this.state.active_id });
        this.get_all_data_by_post_id(data);
    }
    //Status Opened 1
    get_all_data() {
        setTimeout(
            function () {
                const apiOption = {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                };

                fetch(this.props.APIPath + "flagged_jobs_list", apiOption)
                    .then(function (res) {
                        return res.json();
                    })
                    .then((resJson) => {
                        let Manin_data_wrap = resJson.data;
                        if (Manin_data_wrap.length !== 0) {
                            let getFilst_id = Manin_data_wrap[0].id;
                            this.get_all_data_by_post_id(getFilst_id);
                            this.state.active_id = getFilst_id;
                            this.setState({ active_id: this.state.active_id });
                            this.state.List_Of_all_Post = Manin_data_wrap;
                            this.setState({ List_Of_all_Post: this.state.List_Of_all_Post });
                            this.get_fist_list();
                        }
                    });
            }.bind(this),
            500
        );
    }

    get_all_data_by_post_id(data) {
        setTimeout(
            function () {
                const apiOption = {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    // body:JSON.stringify({}),
                };

                fetch(this.props.APIPath + "get_job_detail?job_id=" + data, apiOption)
                    .then(function (res) {
                        return res.json();
                    })
                    .then((resJson) => {
                        this.state.active_id = data;
                        this.setState({ active_id: this.state.active_id });

                        let Manin_data_wrap = resJson.data;
                        let job_title = Manin_data_wrap.job_title;
                        this.state.job_title = job_title;
                        this.setState({ job_title: this.state.job_title });
                        let city = Manin_data_wrap.city;
                        this.state.city = city;
                        this.setState({ city: this.state.city });
                        let state = Manin_data_wrap.state;
                        this.state.state = state;
                        this.setState({ state: this.state.state });
                        //company data
                        let company = Manin_data_wrap.company;
                        let company_profile_img = company.profile_image;
                        this.state.company_profile_img = company_profile_img;
                        this.setState({
                            company_profile_img: this.state.company_profile_img,
                        });
                        let company_name = company.name;
                        this.state.company_name = company_name;
                        this.setState({ company_name: this.state.company_name });
                        // user data
                        let users = Manin_data_wrap.user;
                        let user_id = users.id;
                        let user_name = users.name;
                        this.state.user_name = user_name;
                        this.setState({ user_name: this.state.user_name });
                        let user_profile = users.profile_image;
                        this.state.user_profile = user_profile;
                        this.setState({ user_profile: this.state.user_profile });

                        let responsibilities = Manin_data_wrap.responsibilities;
                        this.state.responsibilities = responsibilities;
                        this.setState({ responsibilities: this.state.responsibilities });

                        let roal = Manin_data_wrap.roles;
                        this.state.roles = roal;
                        this.setState({ roles: this.state.roles });

                        //min_salary
                        let min_salary = Manin_data_wrap.min_salary;
                        this.state.min_salary = min_salary;
                        this.setState({ min_salary: this.state.min_salary });
                        //max_salary
                        let max_salary = Manin_data_wrap.max_salary;
                        this.state.max_salary = max_salary;
                        this.setState({ max_salary: this.state.max_salary });
                        //max_salary
                        let industry = Manin_data_wrap.industry;
                        this.state.industry = industry.name;
                        this.setState({ industry: this.state.industry.name });
                        //total_applications
                        let total_applications = Manin_data_wrap.total_applications;
                        this.state.total_applications = total_applications;
                        this.setState({
                            total_applications: this.state.total_applications,
                        });
                        //applications
                        let applications = Manin_data_wrap.applications;
                        this.state.applications = applications;
                        this.setState({ applications: this.state.applications });
                    });
            }.bind(this),
            500
        );
    }

    //Status Filled 3
    get_all_data_filled() {
        setTimeout(
            function () {
                const apiOption = {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer" + " " + localStorage.getItem("token"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    // body:JSON.stringify({}),
                };

                fetch(
                    this.props.APIPath + "my_jobs_list?job_status=3&off_set=0",
                    apiOption
                )
                    .then(function (res) {
                        return res.json();
                    })
                    .then((resJson) => {
                        let Manin_data_wrap_filled = resJson.data;
                        this.state.List_Of_all_Post_filled = Manin_data_wrap_filled;
                        this.setState({
                            List_Of_all_Post_filled: this.state.List_Of_all_Post_filled,
                        });
                    });
            }.bind(this),
            500
        );
    }

    //fetch list data
    //
    get_fist_list() {
        if (this.state.List_Of_all_Post.length !== 0) {
            return (
                <>
                    {this.state.List_Of_all_Post.map((title, index) => (
                        <Single_post
                            key={index}
                            {...title}
                            {...this.props}
                            get_id={this.get_active_data_id.bind(this)}
                            active_id={this.state.active_id}
                        />
                    ))}
                </>
            );
        } else {
            return "This list is empty";
        }
    }

    autoupdatestaert(data) {
        if (this.state.toggledata == "drop") {
            this.setState({ toggledata: "up" });
        } else {
            this.setState({ toggledata: "drop" });
        }
    }

    render() {
        if (this.state.temp_login == false) {
            return <Navigate to='/' />;
        }
        return (
            <>
                <div className='wapper' style={{ backgroud: "orange" }}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <LeftSideBar
                                myTogglebtn={this.state.toggledata}
                                meta={this.state.data}
                            />
                            <div
                                className='col-md-12 col-lg-12 col-xl-9 col-xxl-10'
                                style={{ padding: "0 0 0 30px", height: "100%" }}>
                                <Header
                                    apilink={this.props.APIPath}
                                    toggleStatus={this.state.toggledata}
                                    toggleButton={this.autoupdatestaert.bind(this)}
                                />
                                <div className='all_post_main all_post_data'>
                                    <Tab.Container
                                        id='left-tabs-example'
                                        defaultActiveKey='Listed'>
                                        <Row>
                                            <Col
                                                sm={8}
                                                className='d-flex justify-content-between align-items-center'>
                                                <h3>Flagged List</h3>
                                            </Col>
                                            <Col sm={4} style={{ paddingRight: "0" }}></Col>
                                            <Col sm={12} style={{ paddingRight: "0" }}>
                                                <Tab.Content>
                                                    <Tab.Pane
                                                        eventKey='Listed'
                                                        className='Listed-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div
                                                                className='col-sm-12 col-lg-8 col-xl-8 col-xxl-8 '
                                                                style={{ padding: "0" }}>
                                                                {this.get_fist_list()}
                                                            </div>
                                                            <div
                                                                className='col-sm-12 col-lg-4 col-xl-4 col-xxl-4'
                                                                style={{ paddingRight: "0" }}>
                                                                {this.state.List_Of_all_Post.length !== 0 ? (
                                                                    <About_post
                                                                        apiPath={this.props.APIPath}
                                                                        sendtokon={localStorage.getItem("token")}
                                                                        Listed={true}
                                                                        job_active_id={this.state.active_id}
                                                                        job_title={this.state.job_title}
                                                                        city={this.state.city}
                                                                        state={this.state.state}
                                                                        responsibilities={
                                                                            this.state.responsibilities
                                                                        }
                                                                        roles={this.state.roles}
                                                                        user_profile={this.state.user_profile}
                                                                        min_salary={this.state.min_salary}
                                                                        max_salary={this.state.max_salary}
                                                                        industry={this.state.industry}
                                                                        company_name={this.state.company_name}
                                                                        applications={this.state.applications}
                                                                        total_applications={
                                                                            this.state.total_applications
                                                                        }
                                                                        company_profile_img={
                                                                            this.state.company_profile_img
                                                                        }
                                                                        listofdata={this.get_all_data_closed.bind(
                                                                            this
                                                                        )}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default List_of_flagged;
