import React, { Component } from 'react';
import Select from 'react-select';
import MyComponent from './AddClientUser';


//Images
import upload_image from '../../assets/image/upload_image.png';

class Singup_stap_1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Save Upload image
            file: '',
            imagePreviewUrl: this.props.uploadimg,
            // Fome data erroe
            valid_logo : "",
            valid_company_name : "", 
            valid_company_bio: "",
            //save upload data
            company_name : this.props.Name, 
            company_bio: this.props.bio,
            logo : "",
            change_logo: this.props.change_logo,
            //count textariya
            countTextareadata : 0,

        };

        // add form value
        this.Company_Name = React.createRef();
        this.Company_Bio = React.createRef();
    }
    
    handleKeyPress(e){
        if(e.target.name == "Company_Name"){
            this.setState({ 
                valid_company_name: "" ,
                company_name : e.target.value
            });
        }
        
        if(e.target.name == "Company_Bio"){
            this.setState({
                valid_company_bio:"",
                company_bio:e.target.value
            });
        }
    }
   //Fome submit function
    Save_company_data(event){
        event.preventDefault();
        let log = false;

        if(this.state.imagePreviewUrl == ""){
            this.setState({ valid_logo: "Please enter a company logo" });
            log = true;
        }     
        if(!this.Company_Name.current.value){
            this.setState({ valid_company_name: "Please enter a company name" });
            log = true;
        }
        if(!this.Company_Bio.current.value){
            this.setState({ valid_company_bio: "Please enter a company bio" });
            log = true;
        }
        if(log === true){ 
            return 
        }

        this.state.company_name = this.Company_Name.current.value;
        this.state.company_bio = this.Company_Bio.current.value;
        this.setState({company_name: this.state.company_name});
        this.setState({company_bio: this.state.company_bio});
        this.props.get_data_stap_1( this.state.file, this.state.imagePreviewUrl, this.state.company_name, this.state.company_bio , this.state.change_logo );
        this.props.clickEvent("stap2");      
    }

    //text araye in count
    countTextarea(e){
        let textcount = e.target.value.length;
        this.setState({
            countTextareadata:textcount,
            company_bio : e.target.value
        });
    }

    dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    //set_image_after_update
    afterCropImage(data,times){
        let cropImage = data;
        let first_time = times;
        let filedata = this.dataURLtoFile(cropImage,"changeLogo.png");
        this.setState({imagePreviewUrl:cropImage,file:filedata,change_logo:first_time});
    }

    render() {
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
        //   $imagePreview = (<img src={imagePreviewUrl} />);
        $imagePreview = imagePreviewUrl;
        } else {
            $imagePreview = upload_image;
        //   $imagePreview = (<img src={upload_image} />);
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data1">
               <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div className="Singup_stap_2_progress"></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title" style={{padding:"8% 8% 0"}}>
                                <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information </h3>
                                <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "24px"}}>Primary Information</p>
                            </div>
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 15% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data.bind(this)} >
                                    <div className="fileUpload" style={{position: "relative", maxWidth: "300px",margin:"auto"}}>
                                        <div className="upload_image"  style={{borderRadius:'100%',width:'100px',height:'100px',backgroundSize:'100% 100%',margin:'0 auto',backgroundImage: `url(${$imagePreview})`}}>
                                            {/* {$imagePreview} */}
                                        </div>
                                        <div className='logo_upload_button'>
                                            <label htmlFor='input-file'> {this.state.change_logo == 0 ? "Upload logo" : "Change Logo" } </label>
                                            <span className="form_validtion_class"> {this.state.valid_logo} </span>
                                        </div>    
                                        <MyComponent change_logo={this.props.change_logo} edit_data = {false} afterCropImage={this.afterCropImage.bind(this)} />
                                    </div>
                                    <div className="singup_input">
                                        <label>Company Name</label>
                                        <input onChange={this.handleKeyPress.bind(this)}  value={this.state.company_name} ref={this.Company_Name} type="text" name="Company_Name" />
                                        <span className="form_validtion_class"> {this.state.valid_company_name} </span>
                                    </div>
                                    <div className="singup_input">
                                        <label>Company Bio</label>
                                        <textarea onChange={this.countTextarea.bind(this)} value={this.state.company_bio} ref={this.Company_Bio}  maxlength="1200"  onKeyPress={this.handleKeyPress.bind(this)} name="Company_Bio" ></textarea>
                                        <span className='Totoal_text_word'> {this.state.countTextareadata}/ 1200 Characters Maximum</span>
                                        <span className="form_validtion_class"> {this.state.valid_company_bio} </span>
                                    </div>
                                    <div className="singup_input" style={{textAlign:"center", marginBottom:"0"}}>
                                        <button type="button" onClick={this.Save_company_data.bind(this)}  className="singup_input_button"> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Singup_stap_1;