import React, { Component } from 'react';
import airbnb from '../assets/image/Ellipse_332.png';
import Delete from '../assets/image/Delete.png';
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";

//Add Tabs
class Team_Members_single_post_saved extends Component {
    constructor(props) {
        super(props);
         this.state = {
            isOpen: false,
            message_id : "",
            temp_login:true,
            showerror:false,
            showerrortype : 0,
            showerrormessage : "",
            chat_redirect:false
        };
        
    }
    openModal = () => this.setState({ isOpen:true });
    closeModal = () => this.setState({ isOpen:false });

    getdatabayid(data){
        this.props.get_id(data)
    }

    get_meta_data(data){
        this.setState({message_id:data})
        const apiOption = {
            method: "post",
            headers:{
                'Authorization': 'Bearer '+this.props.sendtokon,  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                "user" : this.props.user_id,
            }), 
        }

        fetch(this.props.apilink+"create_chat",apiOption)
        .then(function(res) {
            return res.json();
        }).then(resJson => {
            if(resJson.status == true){
                this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: "Job is add",temp_login:false,chat_redirect:true});
                // this.error_handaling();
            }else{
                let data = resJson.message;
                this.setState({  showerror: true ,showerrortype : 2 ,showerrormessage: data,chat_redirect:true });
                // this.error_handaling();

                
            }
        })
        .catch(error => {
            this.setState({ showerror: true ,showerrortype : 2 ,showerrormessage: "Invalid Response" });
            // this.error_handaling();
        });
    }

    render() {
        if (this.state.temp_login == false) {

            let user_id = this.state.message_id;
            let actviefed_id = localStorage.getItem("User_id");
            // 
        }
        if (this.state.chat_redirect == true) {
            return <Navigate to={`/Messages/?${localStorage.getItem('User_id')}-${this.props.user_id}`} />
        }
        return (
            <>
            <div className='show_post_list'>
                <button type='button' onClick={this.getdatabayid.bind(this,this.props.user_id)} style={{background:"transparent",border:"0",float:"left", width:"100%"}} >
                    <div className='post_box-user'>
                        <span className='post_user_icon'>
                            <img style={{width:"91px",borderRadius:'100%'}} src={this.props.profile_image} />
                        </span>
                        <div className='post_user_text'>
                            <div className='post_user_text_inner'>
                                <h3>{this.props.name}</h3>
                                <span>{this.props.job_title}</span>
                                <span className='user_email'>osborn29@gmail.com</span>
                            </div>    
                            <div className='action_team_members'>
                                <div className='team_members_Decline'>
                                    <a href="javascript:void(0);" onClick={this.openModal.bind(this)}> Decline </a>
                                </div>
                                <div className='team_members_Delete'>
                                    <a href="javascript:void(0);" onClick={this.openModal.bind(this)}><img src={Delete} /></a>
                                </div>
                                <div className='team_members_accept'>
                                    <a href="javascript:void(0);"> Accept </a>
                                </div>
                                <div className='team_members_accept-Message'>
                                    <button style={{color: "rgba(25,153,154,1)", border:"none",background:"transparent", padding: 0}} onClick={this.get_meta_data.bind(this,this.props.user_id)} type='button'>Message</button>
                                    {/* <Link onClick={this.get_meta_data.bind(this,this.props.user_id)} to={"/Messages"} variant="outline-success">Message</Link> */}
                                </div>
                            </div>    
                        </div>
                    </div>
                </button>        
            </div>
                
                {/* opne modle */}
                <Modal className="Delete_model_main" show={this.state.isOpen} onHide={this.closeModal} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body>
                        <div className='Delete_model_body'>
                            <div className="Delete_model_user">
                                <span className='Delete_model_user_img'>
                                    <img style={{width:"129px"}} src={airbnb} />
                                </span>
                                <div className='Delete_model_user_text'>
                                    <h3>Kevin Valdez</h3>
                                    <span>Android developer</span>
                                </div>
                                <p>Confirm you want to delete this team member</p>
                            </div>
                            <div className='delete_mobel_buttons'>
                                <div className='delete_mobel_buttons_cancel'>
                                    <button type="button" onClick={this.closeModal.bind(this)}> Cancel</button>
                                </div>
                                <div className='delete_mobel_buttons_delete'>
                                    <button type="button" onClick={this.closeModal.bind(this)}>Yes! Delete</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* opne model */}
            </>
        );
    }
}
export default Team_Members_single_post_saved;