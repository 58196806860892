// import LeftSideBar from "../sidebar/leftside";
import React, { Component } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import airbnb from '../../assets/image/airbnb.png';
import Slider from '@mui/material/Slider';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

class Singup_stap_10 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LGBTQ: this.props.LGBTQ,
            valid_LGBTQ: "",
        }
    }

    gotonextStap(){
        this.props.clickEvent("stap10");
    }

    gotobackStap(){
        this.props.clickEvent("stap8");
    }

    handleClickSlider(event){
        this.state.LGBTQ = event.target.value;
        this.setState({LGBTQ : this.state.LGBTQ});
    }

   

    gotonextStap(){
        this.props.clickEvent("stap10");
    }

    gotobackStap(){
        this.props.clickEvent("stap8");
    }

    Save_company_data(event){
        event.preventDefault();
        this.props.get_singup2_stap4(this.state.LGBTQ);
        this.props.clickEvent("stap10");
    }

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data10">
                <div class="col-lg-6"></div> 
                <div class="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div class="singup_box" style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div class="Singup_stap_6_progress" style={{width:"77.35%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 25px 0 0"}} ></div>
                        <div class="singup_box_inner">
                            <div class="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 8%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>DEI Information</p>
                                </div>      
                            </div>
                            <div class="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                <h3 style={{fontSize:"20px",fontStyle: "normal", fontWeight: "normal" ,color:"rgba(29,29,29,1)"}}>What is the sexual orientation at your company?</h3>
                                <form className="stap_8_singup" style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data.bind(this)}>
                                    <div className="singup_input slider-bars" style={{float:"left",width:"100%"}}>
                                        <label style={{float:"left",width:"100%",fontWeight:"500",paddingBottom:'18px'}}>LGBTQ+</label>
                                        <Slider valueLabelFormat={this.state.LGBTQ+'%'} getAriaLabel={() => 'Minimum distance shift'} min={0} step={1} max={100} value={this.state.LGBTQ} name="LGBTQ"  onChange={this.handleClickSlider.bind(this)}  valueLabelDisplay="on"  />  
                                        <span className="form_validtion_class">{this.state.valid_LGBTQ}</span>
                                    </div>
                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "45% 0 0"}}>
                                        <div className="back_wapper">
                                            <button type="button" onClick={this.gotobackStap.bind(this)}> Back </button>
                                        </div>
                                        <button type="button" onClick={this.Save_company_data.bind(this)}  className="singup_input_button singup_setp-3-button"> Continue </button>
                                    </div>
                                    {/* <div className="back_wapper" style={{textAlign:"center",marginTop:"5%", float:"left",width:"100%"}}>
                                        <button onClick={this.gotonextStap.bind(this)} type="button" style={{background: "transparent",border: "none",color: "teal", fontSize:"20px"}}> Skip This </button>
                                    </div> */}
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Singup_stap_10;