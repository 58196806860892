import React, { Component,useEffect } from "react";
import { BrowserRouter as Router, Link, Navigate } from "react-router-dom";
import undraw_reviewed from "../../assets/image/undraw_reviewed.png";


class Underapproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logout_redirect: false,
            Account_approved: false,
        };
    }
    
    user_is_logout() {
        localStorage.clear();
        this.setState({ logout_redirect: true });
    }


    componentDidMount() {
        const apiOption = {
            method: "POST",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: localStorage.getItem("User_id"),
            }),
        };

        fetch(this.props.APIPath + "getProfileById", apiOption)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                localStorage.setItem("account_status", resJson.data.account_status);
                if (resJson.data.account_status == 1) {
                    this.state.Account_approved = true;
                    this.setState({ Account_approved: this.state.Account_approved });
                }
            });
    }

    render() {
        if (this.state.logout_redirect == true) {
            return <Navigate to='/' />;
        }

        if (this.state.Account_approved == true) {
            return <Navigate to='/ALL_Post' />;
        }
        return (
            <>
                <div style={{ display: "block" }}>
                    <img
                        src={undraw_reviewed}
                        style={{ margin: "0 auto", display: "block", paddingTop: "40px" }}
                    />
                    <h1 style={{ textAlign: "center", marginTop: "40px" }}>
                        Congratulations
                    </h1>
                    <p style={{ textAlign: "center", marginTop: "40px" }}>
                        {" "}
                        Thank you for creating your account!
                        <br />
                        UIT Admin will review and approve your account from here.
                    </p>
                    <button
                        onClick={this.user_is_logout.bind(this)}
                        className=' Profile_update_button'
                        type='button'
                        style={{ margin: "0 auto", display: "block", marginTop: "50px" }}>
                        Logout
                    </button>
                </div>
            </>
        );
    }
}

export default Underapproval;
