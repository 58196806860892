import React, { Component } from "react";
import { BrowserRouter as Router, Link, Navigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import PropTypes from "prop-types";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Search from "../../assets/image/Search.png";
/*-Imges Icon-*/
import notification1 from "../../assets/image/notification.png";
import notification2 from "../../assets/image/notification2.png";
import notify_user_imaged from "../../assets/image/Rectangle-486.png";
import airbnb from "../../assets/image/airbnb.png";
import toggleButton from "../../assets/image/Screenshot_3.png";
import last_stap from "../../assets/image/last-stap.png";
import Alert from "react-bootstrap/Alert";
import moment from "moment-timezone";
moment.tz.setDefault("Etc/UTC");

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            search_value: "",
            selectTab: this.props.selectTab,
            notification: [],
            account_status: 2,
            visibleNotifications: 4, // Set the number of initially visible notifications
            showDropdown: false,
            redirect_to_app: false,
            chat_redirect_to_app: false,
            setnotification : notification2,
            blankdata: false,
        };
        let token = localStorage.getItem("token");
        this.state.account_status = localStorage.getItem("account_status");
        this.setState({ account_status: this.state.account_status });

        const apiOption = {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };

        const apiOption1 = {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: localStorage.getItem('User_id')
            }),
        }
        fetch(this.props.apilink + "getProfileById", apiOption1)
        .then(function (res) {
            return res.json();
        }).then(resJson => {
            if (resJson.data === undefined) {
                localStorage.clear();
                const blankdata = true;
                this.setState({blankdata: blankdata});
                return <Navigate to="/" />
            }
        })

        fetch(this.props.apilink + "notifications", apiOption)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                let notification_data = resJson.data;
                this.state.notification = notification_data;
                this.setState({ notification: notification_data });
                // Find all is_seen values
                let is_seen_values = notification_data.map(
                    (notification) => notification.is_seen
                );

                notification_data.map(elements => {
                    if(elements.is_seen == 0){
                        this.setState({ setnotification: notification1 });
                    }
                });

                // notification_data.map(
                //     (notification) => notification.is_seen
                //     
                // );
            });
    }
    toggleSeen(notificationId, notifiable_id2) {
        // Find the notification by id in the state
        const { notification } = this.state;
        const updatedNotification = notification.find(
            (n) => n.id === notificationId
        );
        // Update the is_seen value to true
        updatedNotification.is_seen = true;
        // Update the state with the modified notification
        this.setState({ notification: [...notification] });
        // Update the local storage with the modified notification data
        localStorage.setItem("notification", JSON.stringify(notification));
        // toggleSeen
        const apiOption = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                notification_id: notificationId,
            }),
            redirect: "follow",
        };

        fetch(process.env.REACT_APP_API_URL + "seen_notification", apiOption)
            .then(function responsError(res) {
                return res.json();
            })
            .then((resJson) => { })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handaling();
            });
    }

    componentWillReceiveProps(props) { }
    componentDidMount() {
        // Retrieve notification data from localStorage
        const storedNotification = localStorage.getItem("notification");

        document.addEventListener("click", this.handleOutsideClick);
        if (storedNotification) {
            // If notification data exists, parse and set it in the component state
            this.setState({ notification: JSON.parse(storedNotification) });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
    }

    toggleButtondeta() {
        if (this.props.toggleStatus == "drop") {
            this.props.toggleButton("up");
        }
        if (this.props.toggleStatus == "up") {
            this.props.toggleButton("drop");
        }
    }

    send_new_post() {
        if (localStorage.getItem("account_status") == 4) {
            this.setState({
                showerror: true,
                showerrortype: 2,
                showerrormessage: "Your account in under approval",
            });
            this.error_handaling();
        } else {
            if (localStorage.getItem("edit_job_id") !== null) {
                localStorage.removeItem("edit_job_id");
            }
            this.setState({ redirect: true });
        }
    }

    error_handaling() {
        if (this.state.showerror == true) {
            setTimeout(
                function () {
                    this.setState({ showerror: false });
                }.bind(this),
                3000
            );

            if (this.state.showerrortype == 1) {
                setTimeout(
                    function () {
                        this.setState({ redirect: true });
                    }.bind(this),
                    3100
                );
            }

            return (
                <>
                    <Alert
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            width: "100%",
                            float: "left",
                            textAlign: "center",
                        }}
                        variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            );
        }
    }

    searchHandler(event) {
        let search_data = event.target.value;
        this.setState({ search_value: event.target.value });
        this.props.search_data(search_data);
    }
    //changes
    handleLoadMore = () => {
        this.setState((prevState) => ({
            visibleNotifications: prevState.visibleNotifications + 4,
        }));
    };
    handleToggleDropdown = () => {
        this.setState((prevState) => ({
            showDropdown: !prevState.showDropdown,
        }));
    };
    handleOutsideClick = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            const clickedElement = event.target;
            const clickedElementClass = clickedElement.classList; // Get the class list of the clicked element
            if (!clickedElement.classList.contains('load_more_note-main')) {
                this.setState({
                    showDropdown: false,
                });
            }
        }
    };

    notification_redirection = (id, user_id) => {
        localStorage.setItem("Applicants", id);
        localStorage.setItem("user_id_notification", user_id);
        this.setState({ redirect_to_app: true });
    };

    chat_redirection = (thread_id, user_id) => {

        localStorage.setItem("redirection_thread_id", "/Messages?" + thread_id);
        localStorage.setItem("redirection_user_id", user_id);
        // this.setState({ chat_redirect_to_app: true });

        setTimeout(() => {
            this.setState({ chat_redirect_to_app: true });
        }, 1000);
    };

    render() {
        
        //changes ................
        const { notification, visibleNotifications, showDropdown } = this.state;
        const loadMoreButton = visibleNotifications < notification.length && (
            <Dropdown.Item
                className='load_more_note-main'
                onClick={this.handleLoadMore}>
                Load More
            </Dropdown.Item>
        );

        if (this.state.blankdata == true) {
            return <Navigate to="/" />
        }

        if (this.state.redirect) {
            return <Navigate to='/Post_Job' />;
        }

        if (this.state.account_status != 1) {
            return <Navigate to='/underapproval' />;
        }
        if (this.state.redirect_to_app == true) {
            return <Navigate to='/Applicants' />;
        }
        if (this.state.chat_redirect_to_app == true) {
            return <Navigate to={localStorage.getItem("redirection_thread_id")} />;
        }

        let $imagePreview = null;
        if (localStorage.getItem("profile_img") != null) {
            $imagePreview = <img src={localStorage.getItem("profile_img")} />;
        } else {
            $imagePreview = <img src={airbnb} />;
        }

        return (
            <>
                {this.error_handaling()}
                <header className='header'>
                    <div
                        className='header-search'
                        style={{ backgroundImage: "../../assets/image/Search.png" }}>
                        <input
                            type='text'
                            onChange={this.searchHandler.bind(this)}
                            value={localStorage.getItem("serach_key")}
                            placeholder='Search Jobs'
                        />
                    </div>
                    <div className='header-left'>
                        <button
                            type='button'
                            onClick={this.send_new_post.bind(this)}
                            className='post_jobs_btn'
                            variant='outline-success'>
                            Post Job
                        </button>
                        <Dropdown
                            show={showDropdown}
                            ref={(ref) => (this.dropdownRef = ref)}>
                            <Dropdown.Toggle
                                variant='success'
                                id='dropdown-basic'
                                onClick={this.handleToggleDropdown}>
                                <img src={this.state.setnotification} />
                            </Dropdown.Toggle>

                            {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <img src={notification1} />
                            </Dropdown.Toggle> */}
                            <Dropdown.Menu show={showDropdown}>
                                <div className='inner_waaper_dropdown'>
                                    <h3>Notifications</h3>

                                    {notification
                                        .slice(0, visibleNotifications)
                                        .map((elelent, index) => (
                                            <React.Fragment key={index}>
                                                {/* {this.state.notification.map((elelent, index) =>
                                        <>
                                            {index < 4 ? <>
                                                <> */}
                                                {elelent.notification_type === 1 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}
                                                                    onClick={() =>
                                                                        this.chat_redirection(
                                                                            elelent.chat_message.chat_id,
                                                                            elelent.chat_message.user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div
                                                                            className='side-box-text'
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                            }}>
                                                                            <div className='notification_box_users-text'>
                                                                                <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                    {elelent.from_user.name} sent you a
                                                                                    message.<br/>
                                                                                    <span style={{whiteSpace:'normal',wordWrap:'break-word'}}>{elelent.chat_message.message}</span>
                                                                                </h3>
                                                                            </div>
                                                                            <div>
                                                                                <span className='timetonot'>
                                                                                    {moment(
                                                                                        elelent.created_at,
                                                                                        "YYYYMMDD,HH:mm:ss"
                                                                                    ).fromNow(true) == "a few seconds"
                                                                                        ? "Now"
                                                                                        : moment(
                                                                                            elelent.created_at,
                                                                                            "YYYYMMDD,HH:mm:ss"
                                                                                        ).fromNow(true)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    onClick={() =>
                                                                        this.chat_redirection(
                                                                            elelent.chat_message.chat_id,
                                                                            elelent.chat_message.user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div
                                                                            className='side-box-text'
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                            }}>
                                                                            <div className='notification_box_users-text'>
                                                                                <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                    {elelent.from_user.name} sent you a
                                                                                    message.<br />
                                                                                    <span style={{whiteSpace:'normal',wordWrap:'break-word'}}>{elelent.chat_message.message}</span>
                                                                                </h3>
                                                                                
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    className='timetonot'
                                                                                    style={{
                                                                                        padding: "0",
                                                                                    }}>
                                                                                    {moment(
                                                                                        elelent.created_at,
                                                                                        "YYYYMMDD,HH:mm:ss"
                                                                                    ).fromNow(true) == "a few seconds"
                                                                                        ? "Now"
                                                                                        : moment(
                                                                                            elelent.created_at,
                                                                                            "YYYYMMDD,HH:mm:ss"
                                                                                        ).fromNow(true)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type === 2 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} is joining UIT
                                                                                as an applicant.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name}is joining UIT
                                                                                as an applicant.{" "}
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type === 3 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} is approval
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} is approval{" "}
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type === 4 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} has commented on your post.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} has commented on your post.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type === 5 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>{elelent.from_user.name} flagged</h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}> {elelent.from_user.name}flagged </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 6 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>{elelent.from_user.name} A job</h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}> {elelent.from_user.name} A job </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 7 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} just hired a
                                                                                user for a position regarding his job
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} just hired a
                                                                                user for a position regarding his job
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 8 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {/* {elelent.job.id ===} */}
                                                        {/* <Link to={"/Applicants/?jobid="+elelent.job.id+"&id="+elelent.from_user.user_id} style={{ color: "black" }}> */}
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}
                                                                    onClick={() =>
                                                                        this.notification_redirection(
                                                                            elelent.job.id,
                                                                            elelent.from_user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} has applied
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className='notification_box show '
                                                                    onClick={() =>
                                                                        this.notification_redirection(
                                                                            elelent.job.id,
                                                                            elelent.from_user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} has applied
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                        {/* </Link> */}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 9 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} is waiting for
                                                                                your approval.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name}is waiting for
                                                                                your approval.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type === 10 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}
                                                                    onClick={() =>
                                                                        this.notification_redirection(
                                                                            elelent.job.id,
                                                                            elelent.from_user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} is new recruiter
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    onClick={() =>
                                                                        this.notification_redirection(
                                                                            elelent.job.id,
                                                                            elelent.from_user.user_id
                                                                        )
                                                                    }>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} is new recruiter{" "}
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}

                                                {elelent.notification_type == 11 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} is waiting for
                                                                                your approval.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} is waiting for
                                                                                your approval.
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 12 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} job approved{" "}
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} job approved{" "}
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 13 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} rejected a user
                                                                                for a position regarding his job
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} rejected a user
                                                                                for a position regarding his job
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 14 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} liked your post
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} liked your post
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {elelent.notification_type == 15 ? (
                                                    <Dropdown.Item
                                                        key={elelent.id}
                                                        onClick={() =>
                                                            this.toggleSeen(elelent.id, elelent.notifiable_id)
                                                        }>
                                                        {elelent.is_seen === false ? (
                                                            <>
                                                                <div
                                                                    className='notification_box show'
                                                                    style={{ backgroundColor: "#E5E5E5" }}>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.from_user.name} unliked your
                                                                                post
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {elelent.is_seen === true ? "" : ""}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) === "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='notification_box show'>
                                                                    <div className='notification_box_users'>
                                                                        <spna>
                                                                            <img
                                                                                src={elelent.from_user.profile_image}
                                                                            />
                                                                        </spna>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.from_user.name} unliked your
                                                                                post
                                                                            </h3>
                                                                        </div>
                                                                        <div className='notification_box_users-text'>
                                                                            <h3 style={{whiteSpace:'normal',wordWrap:'break-word'}}>
                                                                                {" "}
                                                                                {elelent.is_seen === true
                                                                                    ? ""
                                                                                    : ""}{" "}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <span className='timetonot'>
                                                                        {moment(
                                                                            elelent.created_at,
                                                                            "YYYYMMDD, HH:mm:ss"
                                                                        ).fromNow(true) == "a few seconds"
                                                                            ? "Now"
                                                                            : moment(
                                                                                elelent.created_at,
                                                                                "YYYYMMDD, HH:mm:ss"
                                                                            ).fromNow(true)}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropdown.Item>
                                                ) : (
                                                    ""
                                                )}
                                                {/* </>
                                            </> : ""}
                                        </> */}
                                                {/* )} */}
                                            </React.Fragment>
                                        ))}

                                    {/* // <Dropdown.Item >
                                        <div className="notification_box show">
                                            <div className="notification_box_users">
                                                <spna>
                                                    <img src={elelent.from_user.profile_image} />
                                                    <img src={last_stap} className="hover_note_img" />
                                                </spna>
                                                <div className="notification_box_users-text">
                                                    <h3>New messages from rata</h3>
                                                    <span>Connor</span>
                                                </div>
                                            </div>
                                            <span className='timetonot'>
                                                20 mins ago
                                            </span>
                                        </div>
                                    </Dropdown.Item> */}

                                    {/* <Dropdown.Item >
                                        <div className="notification_box show">
                                            <div className="notification_box_users">
                                                <spna>
                                                    <img src={elelent.from_user.profile_image} />
                                                </spna>
                                                <div className="notification_box_users-text">
                                                    <h3> Andres Anthonio wants to join  </h3>
                                                    <h3> your team </h3>
                                                </div>
                                            </div>
                                            <span className='timetonot'>
                                                20 mins ago
                                            </span>
                                        </div>
                                    </Dropdown.Item> */}
                                    {loadMoreButton}
                                    {/* 
                                    {visibleNotifications < notification.length && (
                                        <Dropdown.Item className='load_more_note-main' onClick={this.handleLoadMore}>
                                            Load More
                                        </Dropdown.Item>
                                    )} */}
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to={"/Profile"} variant='outline-success'>
                            <div className='userProfile'>
                                <span className='user_image'>{$imagePreview}</span>
                                <span className='user_text'>
                                    {localStorage.getItem("user_name")}
                                </span>
                            </div>
                        </Link>
                        <button
                            onClick={this.toggleButtondeta.bind(this)}
                            className='toggole_button'
                            type='button'>
                            {" "}
                            <img src={toggleButton} />{" "}
                        </button>
                    </div>
                </header>
            </>
        );
    }
}

export default Header;
