import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';


function  TagsInput (props)  {
	
  	const [tags, setTags] = React.useState(props.taglist);

	//suggestions
	// const suggestions = props.industries_list.map(name => {
	// 	return {
	// 		id: name,
	// 		name: name
	// 	};
	// });  

	const handleDelete = i => {
		setTags(tags.filter((tag, index) => index !== i));
		props.tagDelete(i);
	};

  	const handleAddition = tag => {
    	setTags([...tags, tag]);
		props.tagState(tag);
	};

  	const handleDrag = (tag, currPos, newPos) => {
    	const newTags = tags.slice();
		newTags.splice(currPos, 1);
    	newTags.splice(newPos, 0, tag);
		setTags(newTags);
	}; 

	const handleTagClick = index => {
	}; 
	
	return (
		<div className="tags-input">
			
			<ReactTags  tags={tags}
				//suggestions={suggestions}
				//delimiters={delimiters}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleDrag={handleDrag}
				handleTagClick={handleTagClick} 
				inputFieldPosition="bottom" 
			/>
		</div>
    );
};
export default TagsInput; 