import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import mainLogo from '../../assets/image/mainLogo.png';
/*-Imges Icon-*/
import Bag_black from '../../assets/image/Bag_black.png';
import team_black from '../../assets/image/team_black.png';
import chat_black from '../../assets/image/chat_black.png';



class leftside extends Component {
    constructor(props) {
        super(props);
        this.state = { activeD: this.props.meta }
    }

    nextFunction(data) {
        this.setState({ activeD: data });
    }

    render() {
        return (
            <>
                <div className={`col-md-12 col-lg-12 col-xl-3 col-xxl-2 leftside_wapper sidebar-menu${this.state.statusClass === true ? ' open' : ''}`}  >
                    <div className="leftside_main">
                        <div className='leftside_logo'>
                            <Link to={"/ALL_Post"} variant="outline-success"> <img src={mainLogo} style={{ width: "200px" }} /> </Link>
                        </div>
                        <div className='leftside_left_navbar'>
                            <ul>
                                <li>
                                    <Link className={`${this.state.activeD == "ALL_Post" ? ' active' : ''}`} onClick={this.nextFunction.bind(this, "ALL_Post")} to={"/ALL_Post"} variant="outline-success">
                                        <span className='leftside-nav-icon'> <img src={Bag_black} /> </span>
                                        <span className='leftside-nav-text'>All Jobs</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${this.state.activeD == "Team_Members" ? ' active' : ''}`} onClick={this.nextFunction.bind(this, "Team_Members")} to={"/Team_members"} variant="outline-success" >
                                        <span className='leftside-nav-icon'> <img src={team_black} /></span>
                                        <span className='leftside-nav-text'>Team Members</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${this.state.activeD == "Chat" ? ' active' : ''}`} onClick={this.nextFunction.bind(this, "Chat")} to={"/Messages"} variant="outline-success">
                                        <span className='leftside-nav-icon'> <img src={chat_black} /> </span>
                                        <span className='leftside-nav-text'>Chat</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

leftside.propTypes = {

};

export default leftside;