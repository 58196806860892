import React, { Component } from "react";
import Single_list_data from "./singlie";

//Add Tabs
class Mylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "ALL_Post",
      sing_data: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
      active_id: 1,
    };
  }

  get_active_data_id(data) {
    this.setState({ active_id: data });
  }

  render() {
    return (
      <>
        <span>this is active id :{this.state.active_id} </span>
        <br></br>
        {this.state.sing_data.map((title, index) => (
          <Single_list_data
            key={index}
            {...title}
            {...this.props}
            get_id={this.get_active_data_id.bind(this)}
            active_id={this.state.active_id}
          />
        ))}
      </>
    );
  }
}
export default Mylist;
