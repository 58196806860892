import React, { Component } from 'react';
import ReactDOM from "react-dom";
import airbnb from '../../assets/image/upload_image.png';
import TagsInput from './TagsInput';
import Alert from 'react-bootstrap/Alert';
//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';
class Team_member_stap3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid_job_name : "", 
            //save upload data
            job_name : this.props.stage, 
        }
        // add form value
        this.Job_Title = React.createRef();
    }    

    handleKeyPress(e){
        if(e.target.name == "Company_Name"){
            this.setState({ 
                valid_job_name: "" ,
                job_name : e.target.value
            });
        }
    }

    //Fome submit function
    Save_company_data_stap_2(event){
        event.preventDefault();
        let log = false;
        if(!this.Job_Title.current.value){
            this.setState({ valid_job_name: "Please enter a  job title" });
            log = true;
        } 
       
        if(log === true){ 
            return 
        }

        this.state.job_name = this.Job_Title.current.value;
        this.setState({job_name: this.state.job_name});

        this.props.savedata(this.state.job_name);
        this.props.clickEvent("Team_member_stap4");
    }

    gotobackStap(){ this.props.clickEvent("Team_member_stap2");}

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data3">
                <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box" style={{ background:'overlay',background: "#FFF",float: "left", width: "100%", height: "100%",borderRadius: "6px"}}>
                        <div className="Singup_stap_3_progress" style={{width:'51.45%'}}></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 10% 5%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "bold", fontSize: "32px"}}>Team Member Account</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>Primary Information</p>
                                </div>    
                            </div>
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 10% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%",}} onSubmit={this.Save_company_data_stap_2.bind(this)}>
                                    <div className="singup_input" style={{marginBottom:"5%"}}>
                                        <label >Job Title</label>
                                        <input onChange={this.handleKeyPress.bind(this)}  value={this.state.job_name} ref={this.Job_Title} type="text" name="Company_Name" list="city_state_list" />
                                        <datalist id="city_state_list">
                                            {this.props.job_title_list.map((item) =>
                                                <option key={item} value={item.name} >{item.name}</option>
                                            )}
                                        </datalist>
                                        <span className="form_validtion_class"> {this.state.valid_job_name} </span> 
                                    </div>
                                    <div className="singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper" style={{textAlign:"center",padding: "65% 0 0"}}>
                                        <div className="back_wapper">
                                            <button onClick={this.gotobackStap.bind(this)} type="button"> Back </button>
                                        </div>
                                        <button type="button" onClick={this.Save_company_data_stap_2.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}

export default Team_member_stap3;