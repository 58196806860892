import React, { Component } from 'react';
import { Col, Nav, Tab, Row } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from "react-router-dom"
import Alert from 'react-bootstrap/Alert'
import InfiniteScroll from "react-infinite-scroll-component";
//header saidbar
import Header from "../AccountCreation/header/Header";
import LeftSideBar from "../AccountCreation/sidebar/leftside";
import About_post from './About_post';
import Single_post from './Single_post';
//Add Tabs
class All_post extends Component {

    constructor(props) {
        super(props);
        this.get_all_data();
        this.state = {
            data: "ALL_Post",
            temp_login: true,
            redite_data: true,
            toggledata: "drop",
            List_Of_all_Post: [],
            List_Of_all_Post_Closed: [],
            List_Of_all_Post_filled: [],
            company: [],
            active_id: 0,
            // get data 
            job_title: "",
            city: "",
            state: "",
            responsibilities: "",
            // user data
            user_name: "",
            user_profile: "",
            //salary
            min_salary: "",
            max_salary: "",
            //industry
            industry: "",
            // applications
            total_applications: "",
            employment_type: "",
            created_at: "",
            applications: [],
            applypost: false,
            //roal
            roles: "",
            search_key: "",
            selectTab: "Listed",
            search_value_Listed: "",
            search_value_Filled: "",
            search_value_Closed: "",
            // company
            company_profile_img: "",
            company_name: "",
            company_website: "",
            company_bio: "",
            company_address: "",
            //get post id by user id
            company_post_id: 0,
            fetchMoreData1: 0,
            fetchMoreData2: 0,
            fetchMoreData3: 0
        }

        var token = localStorage.getItem('token');
        if (token == null) {
            this.state.temp_login = false;
        }
        localStorage.setItem('serach_key', '')
    }

    fetchMoreData = () => {
        if (this.state.selectTab == "Listed") {
            var temp_fetchMoreData1 = this.state.fetchMoreData1 + 10;
            this.setState({ fetchMoreData1: temp_fetchMoreData1 });

            let search_listed = this.state.search_value_Listed;

            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            var link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=" + temp_fetchMoreData1;
            if (search_listed != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=" + temp_fetchMoreData1 + "&search=" + search_listed;
            }
            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap = resJson.data;
                    var temp_List_Of_all_Post = this.state.List_Of_all_Post;
                    Manin_data_wrap.map(elements => {
                        temp_List_Of_all_Post.push(elements);
                    });
                    this.setState({ List_Of_all_Post: temp_List_Of_all_Post });
                });
        }
        if (this.state.selectTab == "Filled") {
            var temp_fetchMoreData2 = this.state.fetchMoreData2 + 10;
            this.setState({ fetchMoreData2: temp_fetchMoreData2 });

            let search_listed = this.state.search_value_Filled;
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            var link = this.props.APIPath + "my_jobs_list?job_status=3&off_set=" + temp_fetchMoreData2;
            if (search_listed != '') {
                link = this.props.APIPath + "&off_set=" + temp_fetchMoreData2 + "&search=" + search_listed;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap = resJson.data;
                    var temp_List_Of_all_Post_filled = this.state.List_Of_all_Post_filled;
                    Manin_data_wrap.map(elements => {
                        temp_List_Of_all_Post_filled.push(elements);
                    });

                    var temp_mergedData = [];
                    temp_List_Of_all_Post_filled.map(function (element) {
                        var temp_applications = []
                        var temp_array = element;
                        element.applications.map(function (element2) {
                            if(element2.application_status == 3){
                                temp_applications.push(element2)
                            }
                        });
                        temp_array.applications = temp_applications;
                        temp_array.total_applications = temp_applications.length;
                        temp_mergedData.push(temp_array);
                    });

                    this.setState({ List_Of_all_Post_filled: temp_mergedData });
                });


        }
        if (this.state.selectTab == "Closed") {
            var temp_fetchMoreData3 = this.state.fetchMoreData3 + 10;
            this.setState({ fetchMoreData3: temp_fetchMoreData3 });

            let search_listed = this.state.search_value_Closed;
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }

            var link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=" + temp_fetchMoreData3;
            if (this.state.search_value_Closed != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=" + temp_fetchMoreData3 + "&search=" + this.state.search_value_Closed;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap = resJson.data;
                    var temp_List_Of_all_Post_filled = this.state.List_Of_all_Post_Closed;
                    Manin_data_wrap.map(elements => {
                        temp_List_Of_all_Post_filled.push(elements);
                    });

                    var temp_mergedData = [];
                    temp_List_Of_all_Post_filled.map(function (element) {
                        var temp_applications = []
                        var temp_array = element;
                        element.applications.map(function (element2) {
                            if(element2.application_status == 3){
                                temp_applications.push(element2)
                            }
                        });
                        temp_array.applications = temp_applications;
                        temp_array.total_applications = temp_applications.length;
                        temp_mergedData.push(temp_array);
                    });

                    this.setState({ List_Of_all_Post_Closed: temp_mergedData });
                });
        }
    }

    get_active_data_id(data) {
        this.state.active_id = data;
        this.setState({ active_id: this.state.active_id });
        this.get_all_data_by_post_id(data);
    }
    //Status Opened 1
    get_all_data() {
        setTimeout(function () {
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }
            var link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=0";
            if (this.state.search_key != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=0&search=" + this.state.search_key;
            }
            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap = resJson.data;
                    if (Manin_data_wrap.length !== 0) {
                        let getFilst_id = Manin_data_wrap[0].id;
                        this.get_all_data_by_post_id(getFilst_id);
                        this.state.active_id = getFilst_id;
                        this.setState({ active_id: this.state.active_id });
                        this.state.List_Of_all_Post = Manin_data_wrap;
                        this.setState({ List_Of_all_Post: this.state.List_Of_all_Post });
                        this.get_fist_list();
                    } else {
                        this.setState({ List_Of_all_Post: [] });
                    }
                });
        }.bind(this), 500);
    }

    get_all_data_by_post_id(data) {
        setTimeout(function () {
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }
            fetch(this.props.APIPath + "get_job_detail?job_id=" + data, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {

                    this.state.active_id = data;
                    this.setState({ active_id: this.state.active_id });

                    let Manin_data_wrap = resJson.data;

                   

                    //create post by user_id
                    let post_user_id = Manin_data_wrap.user_id;
                    let post_user_id_id = Manin_data_wrap.user_id;

                    this.setState({ company_post_id: post_user_id_id });

                    let job_title = Manin_data_wrap.job_title;
                    this.state.job_title = job_title;
                    this.setState({ job_title: this.state.job_title });
                    let city = Manin_data_wrap.city;
                    this.state.city = city;
                    this.setState({ city: this.state.city });
                    let state = Manin_data_wrap.state;
                    this.state.state = state;
                    this.setState({ state: this.state.state });
                    //company data
                    let company = Manin_data_wrap.company;
                    let company_profile_img = company.profile_image;
                    this.state.company_profile_img = company_profile_img;
                    this.setState({ company_profile_img: this.state.company_profile_img });
                    let company_name = company.name;
                    this.state.company_name = company_name;
                    this.setState({ company_name: this.state.company_name });
                    // company_website 
                    let company_website = company.website;
                    this.state.company_website = company_website;
                    this.setState({ company_website: this.state.company_website });
                    // company_bio
                    let company_bio = company.bio;
                    this.state.company_bio = company_bio;
                    this.setState({ company_bio: this.state.company_bio });
                    // company_address
                    let company_address = company.address;
                    this.state.company_address = company_address;
                    this.setState({ company_address: this.state.company_address });
                    // user data
                    let users = Manin_data_wrap.user;
                    let user_id = users.id;
                    let user_name = users.name;
                    this.state.user_name = user_name;
                    this.setState({ user_name: this.state.user_name });
                    let user_profile = users.profile_image;
                    this.state.user_profile = user_profile;
                    this.setState({ user_profile: this.state.user_profile });

                    let responsibilities = Manin_data_wrap.responsibilities;
                    this.state.responsibilities = responsibilities;
                    this.setState({ responsibilities: this.state.responsibilities });

                    let roal = Manin_data_wrap.roles;
                    this.state.roles = roal;
                    this.setState({ roles: this.state.roles });

                    //min_salary
                    let min_salary = Manin_data_wrap.min_salary;
                    this.state.min_salary = min_salary;
                    this.setState({ min_salary: this.state.min_salary });

                    //max_salary
                    let max_salary = Manin_data_wrap.max_salary;
                    this.state.max_salary = max_salary;
                    this.setState({ max_salary: this.state.max_salary });
                    //max_salary

                    let industry = Manin_data_wrap.industry;
                    this.state.industry = industry.name;
                    this.setState({ industry: industry.name });

                    //total_applications
                    let total_applications = Manin_data_wrap.total_applications;
                    this.state.total_applications = total_applications;
                    this.setState({ total_applications: this.state.total_applications });
                    this.setState({ employment_type: Manin_data_wrap.employment_type });
                    this.setState({ created_at: Manin_data_wrap.created_at });

                    //applications
                    let applications = Manin_data_wrap.applications;
                    this.state.applications = applications;
                    this.setState({ applications: this.state.applications });
                });


        }.bind(this), 500);
    }

    seleactdata(data) {
        this.searchHandler(data);
    }


    searchHandler(data) {
        localStorage.setItem('serach_key', data)
        if (this.state.selectTab == "Listed") {
            this.setState({ fetchMoreData1: 0 });
            this.setState({ search_value_Listed: data });
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }
            var link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=0";
            if (this.state.search_value_Listed != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=1&off_set=0&search=" + this.state.search_value_Listed;
            }
            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap = resJson.data;
                    let searcjQery = data.toLowerCase(),
                        displayedContacts = Manin_data_wrap.filter((el) => {
                            let searchValue = el.job_title.toLowerCase();
                            return searchValue.indexOf(searcjQery) !== -1;
                        });

                    if (data.length == 0) { this.get_all_data(); }
                    if (data.length !== 0) { this.setState({ List_Of_all_Post: displayedContacts }); }
                })
        }

        if (this.state.selectTab == "Filled") {
            this.setState({ fetchMoreData2: 0 });
            this.setState({ search_value_Filled: data });

            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }
            var link = this.props.APIPath + "my_jobs_list?job_status=3&off_set=0";
            if (this.state.search_value_Filled != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=3&off_set=0&search=" + this.state.search_value_Filled;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap_filled = resJson.data;
                    this.state.List_Of_all_Post_filled = Manin_data_wrap_filled;

                    var temp_mergedData = [];
                    this.state.List_Of_all_Post_filled.map(function (element) {
                        var temp_applications = []
                        var temp_array = element;
                        element.applications.map(function (element2) {
                            if(element2.application_status == 3){
                                temp_applications.push(element2)
                            }
                        });
                        temp_array.applications = temp_applications;
                        temp_array.total_applications = temp_applications.length;
                        temp_mergedData.push(temp_array);
                    });


                    this.setState({ List_Of_all_Post_filled:  temp_mergedData });
                });

            let searcjQery = data.toLowerCase(),
                displayedContacts = this.state.List_Of_all_Post_filled.filter((el) => {
                    let searchValue = el.job_title.toLowerCase();
                    return searchValue.indexOf(searcjQery) !== -1;
                });

            if (data.length == 0) { this.get_all_data_filled(); }
            if (data.length !== 0) {
                var temp_mergedData = [];
                displayedContacts.map(function (element) {
                    var temp_applications = []
                    var temp_array = element;
                    element.applications.map(function (element2) {
                        if(element2.application_status == 3){
                            temp_applications.push(element2)
                        }
                    });
                    temp_array.applications = temp_applications;
                    temp_array.total_applications = temp_applications.length;
                    temp_mergedData.push(temp_array);
                });
                
                this.setState({ List_Of_all_Post_filled: temp_mergedData });
            }
        }

        if (this.state.selectTab == "Closed") {
            this.setState({ fetchMoreData3: 0 });
            this.setState({ search_value_Closed: data });
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }

            var link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=0";
            if (this.state.search_value_Closed != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=0&search=" + this.state.search_value_Closed;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap_closed = resJson.data;
                    this.state.List_Of_all_Post_Closed = Manin_data_wrap_closed;
                    this.setState({ List_Of_all_Post_Closed: this.state.List_Of_all_Post_Closed });

                    let searcjQery = data.toLowerCase(),
                        displayedContacts = Manin_data_wrap_closed.filter((el) => {
                            let searchValue = el.job_title.toLowerCase();
                            return searchValue.indexOf(searcjQery) !== -1;
                        });

                    if (data.length == 0) { this.get_all_data_closed(); }
                    if (data.length !== 0) { this.setState({ List_Of_all_Post_Closed: displayedContacts }); }
                });
        }
    }

    //Status Closed 2
    get_all_data_closed() {
        setTimeout(function () {
            const apiOption = {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body:JSON.stringify({}), 
            }

            var link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=0";
            if (this.state.search_key != '') {
                link = this.props.APIPath + "my_jobs_list?job_status=2&off_set=0&search=" + this.state.search_key;
            }

            fetch(link, apiOption)
                .then(function (res) {
                    return res.json();
                }).then(resJson => {
                    let Manin_data_wrap_closed = resJson.data;

                    if (Manin_data_wrap_closed.length !== 0) {
                        let getFilst_id = Manin_data_wrap_closed[0].id;
                        this.get_all_data_by_post_id(getFilst_id);
                        this.state.List_Of_all_Post_Closed = Manin_data_wrap_closed;
                        this.setState({ List_Of_all_Post_Closed: this.state.List_Of_all_Post_Closed });
                    } else {
                        this.setState({ List_Of_all_Post_Closed: [] });
                    }
                });
        }.bind(this), 500);
    }

    get_all_data_filled() {
        const apiOption = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        // let jobStatusArray = [1, 2, 3, 4, 5]; // Array of job_status IDs
        let jobStatusArray = [3]; // Array of job_status IDs

        // Clear the existing data
        this.setState({ active_id: null, List_Of_all_Post_filled: [] });
        
        let promises = jobStatusArray.map(jobStatus => {
            let link = this.props.APIPath + `my_jobs_list?job_status=3&off_set=0`;

            if (this.state.search_key !== '') {
                link = this.props.APIPath + `my_jobs_list?job_status=${jobStatus}&off_set=0&search=${this.state.search_key}`;
            }

            return fetch(link, apiOption)
                .then(res => res.json())
                .then(resJson => resJson.data)
                .catch(error => {
                    console.log(`Error fetching data for job_status ${jobStatus}:`, error);
                    return []; // Return an empty array if there's an error in fetching data
                });
        });

        Promise.all(promises)
            .then(results => {
                let mergedData = results.flat(); // Merge the individual data arrays into a single array
                if (mergedData.length !== 0) {
                    let getFirst_id = mergedData[0].id;
                    this.get_all_data_by_post_id(getFirst_id);

                    var temp_mergedData = [];
                    mergedData.map(function (element) {
                        var temp_applications = []
                        var temp_array = element;
                        element.applications.map(function (element2) {
                            if(element2.application_status == 3){
                                temp_applications.push(element2)
                            }
                        });
                        temp_array.applications = temp_applications;
                        temp_array.total_applications = temp_applications.length;
                        temp_mergedData.push(temp_array);
                    });

                    this.setState({ active_id: getFirst_id, List_Of_all_Post_filled: temp_mergedData });
                } else {
                    this.setState({ List_Of_all_Post_filled: [] });
                }
            })
            .catch(error => {
                console.log("Error fetching data:", error);
            });
    }

    //fetch list data
    get_fist_list() {
        if (this.state.List_Of_all_Post.length !== 0) {
            return (
                <>
                    <div id="scrollableDiv" style={{ height: '100vh', overflow: "auto" }}>
                        <InfiniteScroll
                            dataLength={this.state.List_Of_all_Post.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={''}
                            scrollableTarget="scrollableDiv"
                        >
                            {this.state.List_Of_all_Post.map((title, index) =>
                                <>
                                    <Single_post tab_type={"Listed"} key={index} {...title}  {...this.props} get_id={this.get_active_data_id.bind(this)} active_id={this.state.active_id} company_post_id={this.state.company_post_id} />
                                </>
                            )}
                        </InfiniteScroll>
                    </div>
                </>
            );
        } else {
            return ("This list is empty");
        }

    }

    // Filled list get data
    filled_job_data() {
        if (this.state.List_Of_all_Post_filled.length != 0) {
            return (
                <>
                    <div id="scrollableDiv_Post_filled" style={{ overflowX: "hide" }}>

                        <InfiniteScroll
                            dataLength={this.state.List_Of_all_Post_filled.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={''}
                            scrollableTarget="scrollableDiv_Post_filled"
                        >
                            {this.state.List_Of_all_Post_filled.map((title, index) =>
                                <Single_post tab_type={"Filled"} company_post_id={this.state.company_post_id} key={index} {...title}  {...this.props} get_id={this.get_active_data_id.bind(this)} active_id={this.state.active_id} />
                            )}
                        </InfiniteScroll>
                    </div>
                </>
            );
        } else {
            return ("This list is empty");
        }
    }
    // Closed list get data
    Closed_job_data() {

        return (
            <>
                <div id="scrollableDiv_Post_Closed" style={{ overflowX: "hide" }}>
                    {this.state.List_Of_all_Post_Closed.length != 0 ? <>
                        <InfiniteScroll
                            dataLength={this.state.List_Of_all_Post_Closed.length}
                            next={this.fetchMoreData}
                            hasMore={true}
                            loader={''}
                            scrollableTarget="scrollableDiv_Post_Closed"
                        >
                            {this.state.List_Of_all_Post_Closed.map((title, index) =>
                                <Single_post tab_type={"Closed"} company_post_id={this.state.company_post_id} key={index} {...title}  {...this.props} get_id={this.get_active_data_id.bind(this)} active_id={this.state.active_id} />
                            )}
                        </InfiniteScroll>
                    </> : "This list is empty"}
                </div>
            </>
        );

    }

    autoupdatestaert(data) {
        if (this.state.toggledata == "drop") {
            this.setState({ toggledata: "up" });
        } else {
            this.setState({ toggledata: "drop" });
        }
    }

    gotoflagged() {
        this.setState({ redite_data: false })
    }

    handleSelect(eventKey) {
        if (eventKey == "Listed") {
            this.get_all_data();
            this.state.search_value = this.state.search_value_Listed;
            this.setState({ selectTab: "Listed", search_value: this.state.search_value });

            localStorage.setItem('serach_key', this.state.search_value)

            this.Set_header();


        }

        if (eventKey == "Filled") {
            this.get_all_data_filled();
            this.state.search_value = this.state.search_value_Filled;
            this.setState({ selectTab: "Filled", search_value: this.state.search_value_Filled });

            localStorage.setItem('serach_key', this.state.search_value_Filled)

            this.Set_header();


        }

        if (eventKey == "Closed") {
            this.get_all_data_closed();
            this.state.search_value = this.state.search_value_Closed;
            this.setState({ selectTab: "Closed", search_value: this.state.search_value });

            localStorage.setItem('serach_key', this.state.search_value)

            this.Set_header();


        }
    }

    Set_header() {
        return (
            <>
                <Header selectTab={this.state.selectTab} search_value={this.state.search_value} search_data={this.seleactdata.bind(this)} apilink={this.props.APIPath} toggleStatus={this.state.toggledata} toggleButton={this.autoupdatestaert.bind(this)} />
            </>
        )
    }

    render() {
        if (this.state.temp_login == false) { return <Navigate to="/" /> }
        if (this.state.redite_data == false) { return <Navigate to="/List_Of_Flagged" /> }
        return (
            <>
                <div className="wapper" style={{ backgroud: "orange" }}>
                    <div className="container-fluid">
                        <div className="row">
                            <LeftSideBar myTogglebtn={this.state.toggledata} meta={this.state.data} />
                            <div className="col-md-12 col-lg-12 col-xl-9 col-xxl-10" style={{ padding: "0 0 0 30px", height: "100%" }}>
                                {/* {this.Set_header()} */}
                                <Header selectTab={this.state.selectTab} search_value={this.state.search_value} search_data={this.seleactdata.bind(this)} apilink={this.props.APIPath} toggleStatus={this.state.toggledata} toggleButton={this.autoupdatestaert.bind(this)} />
                                <div className='all_post_main all_post_data'>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="Listed">
                                        <Row>
                                            <Col sm={8} className="d-flex justify-content-between align-items-center">
                                                <Nav variant="pills" className="flex-row" onSelect={this.handleSelect.bind(this)}>
                                                    <Nav.Item><Nav.Link eventKey="Listed" >Listed</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="Filled" >Filled </Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="Closed" >Closed</Nav.Link></Nav.Item>
                                                </Nav>
                                                <a href="#" onClick={this.gotoflagged.bind(this)} className='View-Flagged'>View Flagged</a>
                                            </Col>
                                            <Col sm={4} style={{ paddingRight: "0" }}></Col>
                                            <Col sm={12} style={{ paddingRight: "0" }}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Listed" className='Listed-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div className="col-sm-12 col-lg-8 col-xl-8 col-xxl-8 " style={{ padding: "0" }}>
                                                                {this.get_fist_list()}
                                                                {/* { this.state.List_Of_all_Post.map((title,index) =>
                                                                    <Single_post key = {index} {...title}  {...this.props} get_id={this.get_active_data_id.bind(this)} active_id={this.state.active_id}  />
                                                                )}     */}
                                                            </div>
                                                            <div className="col-sm-12 col-lg-4 col-xl-4 col-xxl-4" style={{ paddingRight: "0" }} >
                                                                {this.state.List_Of_all_Post.length !== 0 ?
                                                                    <About_post apiPath={this.props.APIPath} sendtokon={localStorage.getItem("token")} Listed={true} job_active_id={this.state.active_id}
                                                                        tab_type={"Listed"}
                                                                        job_title={this.state.job_title}
                                                                        city={this.state.city}
                                                                        state={this.state.state}
                                                                        responsibilities={this.state.responsibilities}
                                                                        roles={this.state.roles}
                                                                        user_profile={this.state.user_profile}
                                                                        min_salary={this.state.min_salary}
                                                                        max_salary={this.state.max_salary}
                                                                        industry={this.state.industry}
                                                                        company_name={this.state.company_name}
                                                                        company_website={this.state.company_website}
                                                                        company_bio={this.state.company_bio}
                                                                        company_address={this.state.company_address}
                                                                        applications={this.state.applications}
                                                                        total_applications={this.state.total_applications}
                                                                        employment_type={this.state.employment_type}
                                                                        created_at={this.state.created_at}
                                                                        company_profile_img={this.state.company_profile_img}
                                                                        listofdata={this.get_all_data.bind(this)}
                                                                        company_post_id={this.state.company_post_id}
                                                                    />
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Filled" className='Filled-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div className="col-sm-12 col-lg-8 col-xl-8" style={{ padding: "0" }}>
                                                                <div className='Filled-contends-data'>
                                                                    {this.filled_job_data()}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-lg-4 col-xl-4 " style={{ paddingRight: "0" }} >
                                                                {this.state.List_Of_all_Post_filled.length !== 0 ?
                                                                    <About_post
                                                                        tab_type={"Filled"}
                                                                        apiPath={this.props.APIPath} sendtokon={localStorage.getItem("token")}
                                                                        job_active_id={this.state.active_id} employment_type={this.state.employment_type}
                                                                        job_title={this.state.job_title} city={this.state.city} state={this.state.state} responsibilities={this.state.responsibilities} company_profile_img={this.state.company_profile_img} company_name={this.state.company_name}
                                                                        user_profile={this.state.user_profile} min_salary={this.state.min_salary} max_salary={this.state.max_salary} industry={this.state.industry} roles={this.state.roles} company_post_id={this.state.company_post_id}
                                                                        listofdata={this.get_all_data_filled.bind(this)}
                                                                        created_at={this.state.created_at}
                                                                    />
                                                                    : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Closed" className='Closed-contends'>
                                                        <div className='row' style={{ margin: "0" }}>
                                                            <div className="col-sm-12 col-lg-8 col-xl-8 col-xxl-8" style={{ padding: "0" }}>
                                                                {this.state.List_Of_all_Post_Closed.length != 0 ? <>
                                                                    {this.Closed_job_data()}
                                                                    {/* { this.state.List_Of_all_Post_Closeds.length != 0 ?
                                                                        <Single_post/> : "Closed job list is empty" 
                                                                    } */}
                                                                </> : "This list is empty"
                                                                }
                                                            </div>
                                                            <div className="col-sm-12 col-lg-4 col-xl-4 col-xxl-4" style={{ paddingRight: "0" }} >
                                                                {this.state.List_Of_all_Post_Closed.length != 0 ?
                                                                    <About_post

                                                                        tab_type={"Closed"}
                                                                        apiPath={this.props.APIPath} sendtokon={localStorage.getItem("token")}
                                                                        job_active_id={this.state.active_id}
                                                                        job_title={this.state.job_title} city={this.state.city} state={this.state.state} responsibilities={this.state.responsibilities} company_profile_img={this.state.company_profile_img} company_name={this.state.company_name}
                                                                        user_profile={this.state.user_profile} min_salary={this.state.min_salary} max_salary={this.state.max_salary} industry={this.state.industry}
                                                                        applications={this.state.applications} total_applications={this.state.total_applications} employment_type={this.state.employment_type} created_at={this.state.created_at} roles={this.state.roles}
                                                                        company_post_id={this.state.company_post_id} get_list_closed={this.get_all_data_closed.bind(this)}
                                                                    /> : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default All_post;