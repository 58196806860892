import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from "react-router-dom";
import team_user_bg from '../assets/image/Rectangle_962.png';
import airbnb from '../assets/image/Ellipse_332_no@2x.png';
import save_icon from '../assets/image/save icon.png';
import unsaved_icon from '../assets/image/save-icon.png';
import Icon_awesome_file_pdf from '../assets/image/Icon_awesome_file_pdf.png';
import Usadre from '../assets/image/Rectangle 562.png';
import closeicon from '../assets/image/Group 8187.png';
import checkbox from '../assets/image/last-stap.png';
import Alert from 'react-bootstrap/Alert';
import { Col, Nav, Tab, Row, Modal, Button } from 'react-bootstrap';


//Add Tabs
class Applicants_about extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isClose: false,
            isOpen_3: false,
            isOpen_4: false,
            isOpen_5: false,
            isActive: false,
            temp_login: true,

            showerror: false,
            showerrortype: 0,
            showerrormessage: "",
            job_Action: false
        };
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    openModalClose = () => this.setState({ isClose: true });
    closeModalClose = () => this.setState({ isClose: false });

    open_isOpen_3 = () => this.setState({ isOpen_3: true });
    close_isOpen_3 = () => this.setState({ isOpen_3: false });

    open_isOpen_4 = () => this.setState({ isOpen_4: true });
    close_isOpen_4 = () => this.setState({ isOpen_4: false });

    open_isOpen_5 = () => this.setState({ isOpen_5: true });
    close_isOpen_5 = () => this.setState({ isOpen_5: false });

    someAction() {
        if (this.state.isActive == true) {
            this.setState({ isActive: false });
        } else { this.setState({ isActive: true }); }
    }

    readmoreandless() {
        if (this.state.isActive == true) {
            return ("Read Less");
        } else {
            return ("Read More");
        }
    }

    hire_for_job(e) {
        e.preventDefault();

    }

    send_to_hire(e) {
        e.preventDefault();
        var applicate_id = localStorage.getItem('Applicants');
        const apiOption = {
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + this.props.sendtokon,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "job_id": applicate_id,
                "applicant_id": this.props.active_id,
                //"close_after_hire" : true
            }),
        }
        // window.location.href = "/ALL_Post/filled";

        fetch(this.props.apilink + "hire_applicant ", apiOption)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                if (resJson.status == true) {
                    this.close_isOpen_3();
                    this.open_isOpen_4();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: " This applicant is Hire " });
                    this.error_handaling();
                    this.setState({ job_Action: true });
                } else {
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "Applicant is hire" });
                    this.setState({ job_Action: true });
                    this.error_handaling();
                }
            })
            .catch(error => {
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
    }

    hire_for_job_close() {
        var applicate_id = localStorage.getItem('Applicants');
        const apiOption2 = {
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + this.props.sendtokon,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "job_id": applicate_id,
                "applicant_id": this.props.active_id,
                "close_after_hire": true,

            }),
        }

        fetch(this.props.apilink + "hire_applicant", apiOption2)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                /*
                if(resJson.status == true){
                   // this.closejob();
                   this.setState({showerror:true , showerrortype : 1 , showerrormessage: "Applicant is hire " });
                }else{
                    this.setState({ validPassword: "Please enter valid password" , showerror: true ,showerrortype : 2 ,showerrormessage: "Applicant is already hire" });
                    this.error_handaling();
                }*/

                if (resJson.status == true) {
                    this.close_isOpen_3();
                    this.open_isOpen_4();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: " This applicant is Hire " });
                    this.error_handaling();
                    this.setState({ job_Action: true });
                } else {
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "Applicant is hire" });
                    this.setState({ job_Action: true });
                    this.error_handaling();
                }
            })
            .catch(error => {
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
    }

    closejob() {
        var applicate_id = localStorage.getItem('Applicants');
        const apiOption3 = {
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + this.props.sendtokon,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "job_id": applicate_id,
            }),
        }

        fetch(this.props.apilink + "close_job", apiOption3)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                if (resJson.status == true) {
                    this.close_isOpen_3();
                    this.open_isOpen_4();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "Applicant is hire and job is close " });
                    this.error_handaling();
                    this.setState({ job_Action: true });
                } else {
                    let data = resJson.message;
                    this.error_handaling();
                }


            })
            .catch(error => {
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
    }

    error_handaling() {
        if (this.state.showerror == true) {
            setTimeout(function () {
                this.setState({ showerror: false });
            }.bind(this), 3000);

            if (this.state.showerrortype == 1) {
                setTimeout(function () {
                    this.setState({ redirect: true });
                }.bind(this), 3100);
            }

            return (
                <>
                    <Alert style={{ position: "absolute", top: "0", left: "0", right: "0", width: "100%", float: "left", textAlign: "center" }} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    SaveButton(job_id, applicant_id) {        
        const apiOption = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                job_id: job_id,
                applicant_id: applicant_id
            })
        };
        var apiUrl = this.props.apilink + "save_applicant";
        if(this.props.isSaved){
            apiUrl = this.props.apilink + "remove_applicant";            
        }

        fetch(apiUrl, apiOption)
            .then((res) => res.json())
            .then((resJson) => {
                this.props.update_isSaved(!this.props.isSaved);
            })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handling();
            });
    }



    go_to_home() {
        this.close_isOpen_4()
        this.setState({ temp_login: false });
    }

    Reject_Applicant() {
        var applicate_id = localStorage.getItem('Applicants');
        const apiOption = {
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + this.props.sendtokon,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "job_id": applicate_id,
                "applicant_id": this.props.active_id,
            }),
        }

        fetch(this.props.apilink + "reject_applicant ", apiOption)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                if (resJson.status == true) {
                    this.close_isOpen_5();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: " This applicant is rejected " });
                    this.error_handaling();
                    this.setState({ job_Action: true });
                } else {
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "Applicant is hire" });
                    this.setState({ job_Action: true });
                    this.error_handaling();
                }
            })
            .catch(error => {
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
    }

    render() {
        if (this.state.temp_login == false) {
            return <Navigate to="/" />
        }

        if (this.state.job_Action == true) {
            return <Navigate to="/ALL_Post" />
        }
        return (
            <>
                {this.props.active_id !== "" ? <>
                    {this.error_handaling()}
                    <div className='about_team_Members_main about_applications_about_main'>
                        <div className='about_applications_about_inner'>
                            <div className='about_team_Members_bg_img'>
                                <img src={this.props.bg_image != undefined ? this.props.bg_image : team_user_bg} />
                                <a className='save_icone' onClick={() => this.SaveButton(this.props.job_id, this.props.active_id)} >
                                    <img src={this.props.isSaved ? save_icon : unsaved_icon} alt={this.props.isSaved ? "Save":"Unsave"} />
                                    {/* <img src={save_icon} /> */}
                                </a>
                            </div>
                            <div className='about_team_Members_about_us'>
                                <div className='team_Members_Profile'>
                                    <span className='user_profile'> <img src={this.props.profile_image ? this.props.profile_image : airbnb} /> </span>
                                    <h3>{this.props.name !== "" ? this.props.name : ""}</h3>
                                    <span>{this.props.job_title !== "" ? this.props.job_title : ""}</span>
                                </div>
                                <div className='Contact-Information about-information'>
                                    <span className='countat_info_Email'>About</span>
                                    {this.props.about != null ?
                                        <span className={this.state.isActive ? "Readlessdata" : "Readmoredata"} style={{ marginBottom: "0px" }}> </span>
                                        : ""}
                                    {this.props.about != null ?
                                        <a href="javascript:void(0);" onClick={this.someAction.bind(this)}> {this.state.isActive ? "Read Less" : "Read More"}  </a>
                                        : ""}
                                </div>
                                <div className="Contact-Information">
                                    <span className='countat_info_title' style={{ marginBottom: "0" }}>Email</span>
                                    <span>{this.props.Email !== "" ? this.props.Email : ""}</span>
                                </div>
                            </div>
                            <div className='team_members_phone'>
                                <span>Phone Number</span>
                                <div className='team_members_phone-main'>
                                    <span><img src={Usadre} /></span>
                                    <p>{this.props.phone !== "" ? this.props.phone : ""}</p>
                                </div>
                            </div>
                            <div className='resume-main'>
                                <span className='resume-title'>Resume</span>
                                {this.props.resume != null ?
                                    <a target='_blank' href={this.props.resume} style={{color:'rgba(109,109,109,1)'}}>
                                        <div className='resume'>
                                            Resume.pdf
                                            <span> <img src={Icon_awesome_file_pdf} /> </span>
                                        </div>
                                    </a>
                                : ""}
                            </div>
                            <div className='resume-main cover_letter-main'>
                                <span className='resume-title'>Cover Letter</span>
                                {this.props.cover_letter != null ?
                                    <a target='_blank' href={this.props.cover_letter} style={{color:'rgba(109,109,109,1)'}}>
                                        <div className='resume cover_letter'>
                                            Cover Letter.pdf
                                            <span> <img src={Icon_awesome_file_pdf} /> </span>
                                        </div>
                                    </a>
                                : ""}
                            </div>
                        </div>
                        <div className='about_tab_applications'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="Professional-Info">
                                <Row style={{ margin: 0 }}>
                                    <Col sm={12} style={{ padding: 0 }}>
                                        <Nav variant="pills" className="flex-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="Professional-Info">Professional Info</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                            <Nav.Link eventKey="Demographic-Info">Demographic Info</Nav.Link>
                                        </Nav.Item> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={12} style={{ padding: 0 }}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Professional-Info">
                                                <div className='Professional-Info-inner'>
                                                    <div className='Employment_Status' style={{ marginBottom: "10px" }}>
                                                        <span>Employment Status</span>
                                                        {/* <a href="javascript:void(0);"> EDIT </a> */}
                                                    </div>
                                                    <div className='Employment_Status' style={{ marginBottom: "30px" }}>
                                                        {this.props.application_status == 1 ?
                                                            <span style={{ fontSize: "18px" }}>Submitted</span>
                                                            :
                                                            (this.props.application_status == 2 ?
                                                                <span style={{ fontSize: "18px" }}>Rejected</span>
                                                                :
                                                                <span style={{ fontSize: "18px" }}>Hired</span>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='Employment_Status'>
                                                        <span>Education</span>
                                                        {/* <a href="javascript:void(0);"> EDIT </a> */}
                                                    </div>
                                                    {this.props.education.map((element, index) =>
                                                        <>
                                                            <div className='Education_data'>
                                                                <span className="Art"> {element.degree.name} </span>
                                                                <span className="Art-time">{element.end_date}</span>
                                                                <span className="Professional"> {element.field}</span>
                                                            </div>

                                                            <div className='Education_data'>
                                                                <span className="Art"> School </span>
                                                                <span className="Art-time">{element.start_date}</span>
                                                                <span className="Professional">{element.school_name}</span>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className='Employment_Status' style={{ marginTop: "30px" }}>
                                                        <span style={{ fontSize: "16px", marginBottom: "5px", color: "rgba(25,25,25,1)" }}>Role</span>
                                                        {/* <a href="javascript:void(0);"> EDIT </a> */}
                                                    </div>
                                                    <div className='Employment_Status'>
                                                        <span style={{ fontSize: "18px", marginBottom: "5px", color: "rgba(25,25,25,1)" }}>Industry</span>
                                                        <a href="javascript:void(0);">  {this.props.education.length === 0 ? "" : "Design"}  </a>
                                                    </div>
                                                    <div className='Employment_Status' style={{ borderBottom: "1px solid #f6f6f6", paddingBottom: "10px", borderBottom: "1px solid #f6f6f6" }}>
                                                        <span style={{ fontSize: "18px", marginBottom: "5px", color: "rgba(25,25,25,1)" }}>Department</span>
                                                        <a href="javascript:void(0);"> {this.props.departments.map((elemnt) => elemnt.name)} </a>
                                                    </div>
                                                    <div className="job">
                                                        <span className='jobtitle'>Job</span>
                                                        {this.props.jobs.length !== 0 ?
                                                            <div className='job_tabs'>
                                                                {this.props.jobs.map((job) =>
                                                                    <>
                                                                        <span>{job.name}</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                            : " "}
                                                    </div>
                                                    <div className='Employment_Status'>
                                                        <span>Interest</span>
                                                        {/* <a href="javascript:void(0);"> EDIT </a> */}
                                                    </div>
                                                    <div className="job">
                                                        <div className='job_tabs'>
                                                            {this.props.professional_interests.map((items) =>
                                                                <span>{items.name}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='Employment_Status'>
                                                        <span>Experience</span>
                                                        {/* <a href="javascript:void(0);"> EDIT </a> */}
                                                    </div>

                                                    {this.props.experience.map((element) =>
                                                        <div className='Education_data Employment_Status_metadge'>
                                                            <span className="Art">{element.job_title} | {element.company}</span>
                                                            <span className="Art-time">{element.start_date}  {element.end_date != null ? <>- {element.end_date}</> : ''}</span>
                                                            <span className="Professional">{element.roles}</span>
                                                        </div>
                                                    )}

                                                    <div className='Employment_Status' style={{ marginTop: "30px", marginBottom: "10px", display: "block" }}>
                                                        <span> Interested Company Stage </span><br />

                                                        <a href="javascript:void(0);">
                                                            {this.props.user_stages.map((items) =>
                                                                <span>{items.name}, </span>
                                                            )}
                                                        </a>
                                                    </div>
                                                    <div className='Employment_Status' style={{ marginBottom: "30px" }}>
                                                        <span></span>
                                                    </div>
                                                    <div className='Employment_Status' style={{ marginBottom: "10px", display: "block" }}>
                                                        <span>Interested Company Size</span><br />
                                                        <a href="javascript:void(0);">
                                                            {this.props.user_sizes.map((items) =>
                                                                <span>{items.name}, </span>
                                                            )}
                                                        </a>
                                                    </div>
                                                    <div className='Employment_Status' style={{ marginBottom: "20px" }}>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Demographic-Info" >
                                                <button style={{ display: "none" }} type="button" onClick={this.openModal.bind(this)}> flagged applicant </button>
                                                <button style={{ display: "none" }} type="button" onClick={this.openModalClose.bind(this)}> Close Job </button>

                                                <Modal className="new_team_member_box_main flagged-applicant" show={this.state.isOpen} onHide={this.closeModal}>
                                                    <Modal.Body closeButton>
                                                        <div className='users_job_box'>
                                                            <img src={this.props.bg_image != undefined ? this.props.bg_image : airbnb} />
                                                            <h3>{this.props.name}</h3>
                                                        </div>

                                                        <div className="new_team_member_box_inner">
                                                            <form>
                                                                <div className="singup_input" style={{ float: "left", width: "100%" }}>
                                                                    <label style={{ float: "left", width: "100%", fontWeight: "500" }}>Tell us  why you're flagging this user</label>
                                                                    <textarea>Inappropriate content sharing</textarea>
                                                                </div>

                                                                <div className="singup_input " style={{ textAlign: "center", padding: "5% 0 0" }}>
                                                                    <button type="button" className="singup_input_button singup_setp-3-button"> Submit and close </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>

                                                <Modal className="new_team_member_box_main flagged-applicant Close-Job" show={this.state.isOpenClose} onHide={this.closeModalClose}>
                                                    <Modal.Body closeButton>
                                                        <div className='users_job_box'>
                                                            <img src={closeicon} />
                                                            <h3>You are  about to closing the job</h3>
                                                            <span> "software engineering needed" </span>
                                                        </div>

                                                        <div className="new_team_member_box_inner">
                                                            <form>
                                                                <div className="singup_input" style={{ float: "left", width: "100%" }}>
                                                                    <label style={{ float: "left", width: "100%", fontWeight: "500" }}>Reason for Closing</label>
                                                                    <textarea>I Found my  candidate alreddy</textarea>
                                                                </div>

                                                                <div className="singup_input " style={{ textAlign: "center", padding: "5% 0 0" }}>
                                                                    <button type="button" className="singup_input_button singup_setp-3-button"> Submit and close </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>

                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                            <div className="action_buttons">
                                <div className='Listed-contends-button'>
                                    {/* {localStorage.getItem('User_id') == localStorage.getItem('company_post_id') ?  <>
                                    <button type='button' onClick={this.open_isOpen_5.bind(this)} className='close_button'>Reject</button>
                                    <button type='button' style={{margin:'auto'}} onClick={this.open_isOpen_3.bind(this)} className='edit_button'>Hire Applicant</button>
                                </> : ""} */}
                                    {this.props.application_status == 1 ?
                                        <>
                                            <button type='button' onClick={this.open_isOpen_5.bind(this)} className='close_button'>Reject</button>
                                            <button type='button' style={{ margin: 'auto' }} onClick={this.open_isOpen_3.bind(this)} className='edit_button'>Hire Applicant</button>
                                        </>
                                        :
                                        (this.props.application_status == 2 ?
                                            <button type='button' style={{ margin: '0 auto' }} className='close_button'>Rejected</button>
                                            :
                                            <button type='button' style={{ margin: '0 auto' }} className='edit_button'>Hired</button>
                                        )
                                    }
                                </div>
                            </div>

                            <Modal className="new_team_member_box_main Hiring_applicant" show={this.state.isOpen_3} onHide={this.close_isOpen_3}>
                                <Modal.Body className="Hiring_applicant_body">
                                    <h3>What would you like to do?</h3>
                                    <div className='users_job_box'>
                                        <img style={{ width: "104px", height: "104px", borderRadius: '100%' }} src={this.props.profile_image ? this.props.profile_image : airbnb} />
                                        <h3>{this.props.name}</h3>
                                        <span>{this.props.job_title !== "" ? this.props.job_title : ""}</span>
                                    </div>
                                    <div className="new_team_member_box_inner">
                                        <form>
                                            <div className="singup_input hire_and_close_job_btn" style={{ textAlign: "center", padding: "5% 0 0" }}>
                                                <button type="button" onClick={this.Reject_Applicant.bind(this)} className="singup_input_button singup_setp-3-button"> Reject  Applicant </button>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal className="new_team_member_box_main Hiring_applicant" show={this.state.isOpen_3} onHide={this.close_isOpen_3}>
                                <Modal.Body className="Hiring_applicant_body">
                                    <h3>What would you like to do?</h3>
                                    <div className='users_job_box'>
                                        <img style={{ width: "104px", height: "104px", borderRadius: '100%' }} src={this.props.profile_image ? this.props.profile_image : airbnb} />
                                        <h3>{this.props.name}</h3>
                                        <span>{this.props.job_title !== "" ? this.props.job_title : ""}</span>
                                    </div>
                                    <div className="new_team_member_box_inner">
                                        <form>
                                            <div className="singup_input " style={{ textAlign: "center", padding: "8% 0 0" }}>
                                                {/* <button type="button" onClick={this.send_to_hire.bind(this)} className="singup_input_button singup_setp-3-button"> Hire and continue </button> */}
                                                <button type="button" id="hireButton" onClick={this.send_to_hire.bind(this)} className="singup_input_button singup_setp-3-button">Hire and continue</button>

                                            </div>
                                            <div className="singup_input hire_and_close_job_btn" style={{ textAlign: "center", padding: "5% 0 0" }}>
                                                <button type="button" onClick={this.hire_for_job_close.bind(this)} className="singup_input_button singup_setp-3-button"> Hire and Close job </button>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal className="new_team_member_box_main Hiring_applicant Hiring_applicant-last" show={this.state.isOpen_4} onHide={this.close_isOpen_4}>
                                <Modal.Body className="Hiring_applicant_body">
                                    <h3>Hooray! New Hire</h3>
                                    <div className='users_job_box' style={{ position: "relative" }}>
                                        <img style={{ width: "104px", height: "104px", borderRadius: '100%' }} src={this.props.profile_image ? this.props.profile_image : airbnb} />
                                        <span className="icon_imgd">
                                            <img src={checkbox} />
                                        </span>
                                        <h3>{this.props.name}</h3>
                                        <span>{this.props.job_title !== "" ? this.props.job_title : ""}</span>
                                    </div>
                                    <div className="new_team_member_box_inner">
                                        <form>
                                            <p>Congrats on your hire, we’ll notify the candidate shortly! 🎉</p>
                                            <div className="singup_input " style={{ textAlign: "center", padding: "8% 0 0" }}>
                                                <button type="button" onClick={this.go_to_home.bind(this)} className="singup_input_button singup_setp-3-button"> Continue </button>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Modal className="new_team_member_box_main Hiring_applicant" show={this.state.isOpen_5} onHide={this.close_isOpen_5}>
                                <Modal.Body className="Hiring_applicant_body">
                                    <h3>What would you like to do?</h3>
                                    <div className='users_job_box'>
                                        <img style={{ width: "104px", height: "104px", borderRadius: '100%' }} src={this.props.profile_image ? this.props.profile_image : airbnb} />
                                        <h3>{this.props.name}</h3>
                                        <span>{this.props.job_title !== "" ? this.props.job_title : ""}</span>
                                    </div>
                                    <div className="new_team_member_box_inner">
                                        <form>
                                            <div className="singup_input hire_and_close_job_btn" style={{ textAlign: "center", padding: "5% 0 0" }}>
                                                <button type="button" onClick={this.Reject_Applicant.bind(this)} className="singup_input_button singup_setp-3-button"> Reject  Applicant </button>
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </>
                    : ""}
            </>
        );
    }
}
export default Applicants_about;