import React, { Component } from "react";
import Profile_header from "./Profile_header";
import { Nav, Col, Tab, Sonnet, Row } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { BrowserRouter as Router, Link, Navigate } from "react-router-dom";
import LeftSideBar from "../../AccountCreation/sidebar/leftside";
import Header from "../../AccountCreation/header/Header";
import Edit_icone from "../../assets/image/pen-to-square-solid.png";

import Personal_information from "./Personal_information";
import DEI_Information from "./DEI_Information";
import Demographic_information from "./Demographic_information";
import Contact_us_information from "./Contact_us_information";

import User_Profile_bod from "../../assets/image/User_Profile_bod.png";
import Alert from "react-bootstrap/Alert";
import MyComponent from "./Edit_Profile_data";
//Add Tabs
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tempLogin : 0,
            showData: { data: "0" },
            mydata: [],
            logout_redirect: false,
            //logochange

            file: "",
            imagePreviewUrl: "",

            bg_file: "",
            bg_imagePreviewUrl: "",
            // show data
            Name: "",
            City: "",
            State: "",
            Email: "",
            industry: [],
            bio: "",
            user_sizes: [],
            website: "",
            user_stages: [],
            Greenhouse_Accoun: "",
            //Demographic Information
            //DEI Information
            dei_statement: "",
            team_lead: 0,
            programming: 0,
            training: 0,
            erg: 0,
            men: 111,
            women: 111,
            non_binary: 111,
            lgbt: 111,
            Role: 0,
            default_name: localStorage.getItem("user_name"),
            greenhousetoken: "",
            greenhouse_status: 0,
            account_delete_popup: false,
            account_delete_reason: "",
            account_delete_reason_is_other: false,
            account_delete_reason_is_other_reason: "",
            account_delete_popup_final: false,
        };
        this.togogleClass = this.togogleClass.bind(this);
        var token = localStorage.getItem("token");
        if (token == null) {
            this.setState({ tempLogin: false });
        }
        this.thismydata();
    }

    error_handaling() {
        if (this.state.showerror == true) {
            setTimeout(
                function () {
                    this.setState({ showerror: false });
                }.bind(this),
                3000
            );

            if (this.state.showerrortype == 1) {
                setTimeout(
                    function () {
                        this.setState({ redirect: true });
                    }.bind(this),
                    3100
                );
            }

            return (
                <>
                    <Alert
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            width: "100%",
                            float: "left",
                            textAlign: "center",
                        }}
                        variant={this.state.showerrortype == 1 ? "success" : "danger"}
                    >
                        {this.state.showerrormessage}
                    </Alert>
                </>
            );
        }
    }

    thismydata() {
        let data = localStorage.getItem("User_id");
        setTimeout(
            function () {
                let user_roal = localStorage.getItem("user_Role");
                if (user_roal == 2) {
                    const apiOption = {
                        method: "POST",
                        headers: {
                            Authorization: "Bearer" + " " + localStorage.getItem("token"),
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            user_id: data,
                        }),
                    };
                    fetch(this.props.APIPath + "getProfileById", apiOption)
                        .then(function (res) {
                            return res.json();
                        })
                        .then((resJson) => {
                            let Manin_data_wrap = resJson.data;
                            let dei_statement = Manin_data_wrap.dei_statement;
                            this.state.mydata = Manin_data_wrap;

                            var industry_temp = [];

                            Manin_data_wrap.industries.map((elements) => {
                                industry_temp.push(elements.name);
                            });

                            this.setState({
                                Name: Manin_data_wrap.name,
                                City: Manin_data_wrap.city,
                                State: Manin_data_wrap.state,
                                Email: Manin_data_wrap.email,

                                industry: industry_temp,

                                bio: Manin_data_wrap.bio,
                                user_sizes: Manin_data_wrap.user_sizes,
                                website: Manin_data_wrap.website,
                                user_stages: Manin_data_wrap.user_stages,
                                Role: Manin_data_wrap.role,
                                Greenhouse_Accoun: "",
                                greenhousetoken: Manin_data_wrap.greenhouse_access_token,
                                greenhouse_status: Manin_data_wrap.greenhouse_status,
                            });
                            if (dei_statement !== null) {
                                this.setState({
                                    dei_statement: dei_statement.deistatement,
                                    erg: dei_statement.erg,
                                    lgbt: dei_statement.lgbt,
                                    men: dei_statement.men,
                                    non_binary: dei_statement.non_binary,
                                    programming: dei_statement.programming,
                                    team_lead: dei_statement.team_lead,
                                    training: dei_statement.training,
                                    women: dei_statement.women,
                                    mydata: Manin_data_wrap,
                                });
                            }
                        });
                }

                if (user_roal == 4) {
                    const apiOption = {
                        method: "POST",
                        headers: {
                            Authorization: "Bearer" + " " + localStorage.getItem("token"),
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            user_id: localStorage.getItem("perent_id"),
                        }),
                    };
                    fetch(this.props.APIPath + "getProfileById", apiOption)
                        .then(function (res) {
                            return res.json();
                        })
                        .then((resJson) => {
                            let Manin_data_wrap = resJson.data;
                            let dei_statement = Manin_data_wrap.dei_statement;
                            this.state.mydata = Manin_data_wrap;

                            var industry_temp = [];
                            Manin_data_wrap.industries.map((elements) => {
                                industry_temp.push(elements.name);
                            });

                            this.setState({
                                Name: Manin_data_wrap.name,
                                City: Manin_data_wrap.city,
                                State: Manin_data_wrap.state,
                                Email: Manin_data_wrap.email,

                                industry: industry_temp,

                                bio: Manin_data_wrap.bio,
                                user_sizes: Manin_data_wrap.user_sizes,
                                website: Manin_data_wrap.website,
                                user_stages: Manin_data_wrap.user_stages,
                                Role: Manin_data_wrap.role,
                                Greenhouse_Accoun: "",
                                greenhousetoken: Manin_data_wrap.greenhouse_access_token,
                                greenhouse_status: Manin_data_wrap.greenhouse_status,
                            });

                            if (dei_statement !== null) {
                                this.setState({
                                    dei_statement: dei_statement.deistatement,
                                    erg: dei_statement.erg,
                                    lgbt: dei_statement.lgbt,
                                    men: dei_statement.men,
                                    non_binary: dei_statement.non_binary,
                                    programming: dei_statement.programming,
                                    team_lead: dei_statement.team_lead,
                                    training: dei_statement.training,
                                    women: dei_statement.women,
                                    mydata: Manin_data_wrap,
                                });
                            }
                        });
                }
            }.bind(this),
            500
        );
    }

    togogleClass(data) {
        let selectedValue = this.findItem("data");
        if (data == "0") {
            selectedValue.showData = 0;
            this.setState({ showData: this.state.showData });
        }

        if (data == "1") {
            if (data == "1") {
                selectedValue.showData = 1;
                this.setState({ showData: this.state.showData });
            }
        }
    }

    user_is_logout() {
        localStorage.clear();
        this.setState({ logout_redirect: true });
    }

    user_is_delete_account() {
        this.setState({ account_delete_reason: "" });
        this.setState({ account_delete_reason_is_other: "" });
        this.setState({ account_delete_reason_is_other_reason: "" });
        this.setState({ account_delete_popup_final: "" });
        this.setState({ account_delete_popup: true });
    }
    delete_account() {
        var delete_reason = "";
        if (this.state.account_delete_reason_is_other == true) {
            delete_reason = this.state.account_delete_reason_is_other_reason;
        } else {
            delete_reason = this.state.account_delete_reason;
        }

        const apiOption1 = {
            method: "Post",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: localStorage.getItem("User_id"),
                reason: delete_reason,
            }),
        };
        fetch(this.props.APIPath + "delete_user", apiOption1)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                localStorage.clear();
                this.setState({ logout_redirect: true });
            })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handaling();
            });
    }

    user_is_delete_account_action(value) {
        this.setState({ account_delete_reason: value });
        if (value == "Other") {
            this.setState({ account_delete_reason_is_other: true });
        } else {
            this.setState({ account_delete_popup: false });
            this.setState({ account_delete_popup_final: true });
        }
    }
    closeModal = () => this.setState({ account_delete_popup: false });
    closeModal2 = () => this.setState({ account_delete_popup_final: false });
    closeModal3 = () =>
        this.setState({
            account_delete_popup: false,
            account_delete_popup_final: true,
        });

    account_delete_reason_is_other_reason_action(e) {
        this.setState({ account_delete_reason_is_other_reason: e.target.value });
    }

    update_DEI_info(
        dei_statement,
        team_lead,
        programming,
        training,
        erg,
        men,
        women,
        non_binary,
        lgbt
    ) {
        this.setState({
            dei_statement: dei_statement,
            team_lead: team_lead,
            programming: programming,
            training: training,
            erg: erg,
            men: men,
            women: women,
            non_binary: non_binary,
            lgbt: lgbt,
        });
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    //set_image_after_update
    afterCropImage(data) {
        let cropImage = data;
        let logo = this.dataURLtoFile(cropImage, "update_logo_bg.png");
        this.setState({ imagePreviewUrl: cropImage, file: logo });

        //logo upload
        var formdata = new FormData();
        formdata.append("profile_image", logo);
        const apiOption2 = {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: formdata,
            redirect: "follow",
        };

        fetch(this.props.APIPath + "update_profile", apiOption2)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                if (resJson.status == true) {
                    let Manin_data_wrap = resJson.data;
                    let Profle = Manin_data_wrap.profile_image;
                    localStorage.setItem("profile_img", Profle);
                    this.setState({
                        showerror: true,
                        showerrortype: 1,
                        showerrormessage: "Your profile is update",
                    });
                    this.error_handaling();
                } else {
                    this.setState({
                        valid_email_address: "Email address is already registered",
                    });
                    this.setState({
                        showerror: true,
                        showerrortype: 2,
                        showerrormessage: "Something wrong with api fields",
                    });
                    this.error_handaling();
                }
            })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handaling();
            });
    }

    //set_image_after_update
    afterCropImageBg(data) {
        let cropImagebg = data;
        let logobg = this.dataURLtoFile(cropImagebg, "update_logo_bg.png");
        this.setState({ bg_imagePreviewUrl: cropImagebg, bg_file: logobg });

        //logo upload
        var formdata = new FormData();
        formdata.append("profile_banner ", logobg);
        const apiOption2 = {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: formdata,
            redirect: "follow",
        };

        fetch(this.props.APIPath + "update_profile", apiOption2)
            .then(function (res) {
                return res.json();
            })
            .then((resJson) => {
                if (resJson.status == true) {
                    let Manin_data_wrap = resJson.data;
                    let Profle = Manin_data_wrap.profile_image;
                    //  localStorage.setItem('profile_img', Profle);
                    this.setState({
                        showerror: true,
                        showerrortype: 1,
                        showerrormessage: "Your profile banner is update",
                    });
                    this.error_handaling();
                } else {
                    this.setState({
                        valid_email_address: "Email address is already registered",
                    });
                    this.setState({
                        showerror: true,
                        showerrortype: 2,
                        showerrormessage: "Something wrong with api fields",
                    });
                    this.error_handaling();
                }
            })
            .catch((error) => {
                this.setState({
                    showerror: true,
                    showerrortype: 2,
                    showerrormessage: "Invalid Response",
                });
                this.error_handaling();
            });
    }

    //set_image_after_update
    localStorage_Name(data) {
        this.setState({ default_name: data });
        localStorage.setItem("user_name", data);
    }

    greenhouse_status_change(data) {
        this.setState({ greenhouse_status: data });
    }
    greenhousetoken_change(data) {
        this.setState({ greenhousetoken: data });
    }

    localStorage_Email(data) {
        localStorage.setItem("Account_Email", data);
    }
    localStorage_city_state(city, state) {
        var mydata_temp = this.state.mydata;
        mydata_temp.city = city;
        mydata_temp.state = state;
        this.setState({ mydata: mydata_temp });
    }
    localStorage_company_industry(data) {
        this.setState({ industry: data });
    }

    render() {
        if (this.state.logout_redirect == true) {
            return <Navigate to='/' />;
        }
        return (
            <>
                {this.error_handaling()}
                <div className='wapper' style={{ backgroud: "orange" }}>
                    <div className='container-fluid'>
                        <div className='row'>
                            <LeftSideBar statusClass={this.state.showData} />
                            <div
                                className='col-md-12  col-lg-9 col-xl-9 col-xxl-10 Right_saidbar'
                                style={{ padding: "0 0 0 30px", height: "100%" }}
                            >
                                <Header
                                    apilink={this.props.APIPath}
                                    toggleButon={this.togogleClass.bind(this)}
                                    statusClass={this.state.showData}
                                />
                                <div className='user_profile_main'>
                                    <div className='Profile_header'>
                                        <img
                                            src={
                                                this.state.bg_imagePreviewUrl != ""
                                                    ? this.state.bg_imagePreviewUrl
                                                    : User_Profile_bod
                                            }
                                        />
                                        <div className='Profile_header-inner'>
                                            <div className='Profile_header_user'>
                                                <span className='Profile_header_user_logo'>
                                                    <img
                                                        src={
                                                            this.state.imagePreviewUrl != ""
                                                                ? this.state.imagePreviewUrl
                                                                : localStorage.getItem("profile_img")
                                                        }
                                                    />
                                                    
                                                    <button
                                                        className='profile_logo_img_edit'
                                                        type='button'
                                                        style={{
                                                            backgroundImage: "url(" + Edit_icone + ")",
                                                            width: "20px",
                                                            height: "20px",
                                                            backgroundSize: "cover"
                                                        }}
                                                    >
                                                        {" "}
                                                    </button>
                                                    <MyComponent
                                                        edit_data={"logo"}
                                                        afterCropImage={this.afterCropImage.bind(this)}
                                                    />
                                                </span>
                                                <div className='Profile_header_user_text'>
                                                    <h3>{this.state.default_name}</h3>
                                                    <span>
                                                        {this.state.mydata.city != ""
                                                            ? this.state.mydata.city +
                                                            " , " +
                                                            this.state.mydata.state
                                                            : ""}
                                                    </span>
                                                    <span style={{ display: "flex" }}>
                                                        {this.state.industry.map((data, i) => (
                                                            <>
                                                                {i != 0 ? (
                                                                    <span
                                                                        className='industry_dot'
                                                                        style={{
                                                                            fontSize: "40px",
                                                                            marginLeft: "5px",
                                                                            marginRight: "5px",
                                                                            marginTop: "-9px",
                                                                        }}
                                                                    >
                                                                        .
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {data}
                                                            </>
                                                        ))}
                                                    </span>
                                                    {/* <span>Design Finance </span> */}
                                                </div>
                                            </div>
                                            {/* {localStorage.getItem("user_Role") == 2 ?<>
                                            <div className='profile_change'>
                                                <a href='javascript:void(0)' style={{position:"relative",top:'20px'}}> 
                                                    Change cover photo 
                                                    <MyComponent edit_data={"bg_logo"} afterCropImageBg={this.afterCropImageBg.bind(this)} />
                                                </a>
                                            </div>
                                            </>:""} */}
                                        </div>
                                    </div>

                                    <div className='Profile_bottom'>
                                        <Tab.Container
                                            id='Profile_tab'
                                            defaultActiveKey='Personal-Information'
                                        >
                                            <div className='Profile_tab_main'>
                                                <Row>
                                                    <Col sm={3} md={12} lg={4} xl={4} xxl={3}>
                                                        <Nav
                                                            variant='pills'
                                                            className='flex-column tab-nav-main'
                                                        >
                                                            <Nav.Item>
                                                                {" "}
                                                                <Nav.Link eventKey='Personal-Information'>
                                                                    Personal Information
                                                                </Nav.Link>{" "}
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                {" "}
                                                                <Nav.Link eventKey='Demographic-Information'>
                                                                    Demographic Information
                                                                </Nav.Link>{" "}
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                {" "}
                                                                <Nav.Link eventKey='DEI-Information'>
                                                                    DEI Information
                                                                </Nav.Link>{" "}
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                {" "}
                                                                <Nav.Link eventKey='Contact-UIT'>
                                                                    Contact UIT
                                                                </Nav.Link>{" "}
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link> <a class="nav-link" style={{marginLeft:'0'}} href="https://www.usintechnology.com/platform-terms" target="_blank" rel="noopener noreferrer">Terms</a> </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link> <a class="nav-link" style={{marginLeft:'0'}} href="https://www.usintechnology.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a> </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                {" "}
                                                                <button
                                                                    id='Profile_tab-tab-Logout'
                                                                    className='nav-link'
                                                                    type='button'
                                                                    onClick={this.user_is_delete_account.bind(
                                                                        this
                                                                    )}
                                                                >
                                                                    {" "}
                                                                    Delete Account{" "}
                                                                </button>{" "}
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                {" "}
                                                                <button
                                                                    id='Profile_tab-tab-Logout'
                                                                    className='nav-link'
                                                                    type='button'
                                                                    onClick={this.user_is_logout.bind(this)}
                                                                >
                                                                    {" "}
                                                                    Logout{" "}
                                                                </button>{" "}
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col
                                                        sm={9}
                                                        md={12}
                                                        lg={8}
                                                        xl={8}
                                                        xxl={9}
                                                        style={{ padding: "0" }}
                                                    >
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey='Personal-Information'>
                                                                <>
                                                                    {this.state.mydata.length != 0 ? (
                                                                        <Personal_information
                                                                            APIPath={this.props.APIPath}
                                                                            mydata={this.state.mydata}
                                                                            Name={this.state.Name}
                                                                            City={this.state.City}
                                                                            State={this.state.State}
                                                                            Email={this.state.Email}
                                                                            industry={this.state.industry}
                                                                            Bio={this.state.bio}
                                                                            user_sizes={this.state.user_sizes}
                                                                            website={this.state.website}
                                                                            user_stages={this.state.user_stages}
                                                                            Greenhouse_Accoun={
                                                                                this.state.Greenhouse_Accoun
                                                                            }
                                                                            file={this.state.file}
                                                                            imagePreviewUrl={
                                                                                this.state.imagePreviewUrl
                                                                            }
                                                                            bg_file={this.state.bg_file}
                                                                            bg_imagePreviewUrl={
                                                                                this.state.bg_imagePreviewUrl
                                                                            }
                                                                            role={this.state.Role}
                                                                            localStorage_Name={this.localStorage_Name.bind(
                                                                                this
                                                                            )}
                                                                            localStorage_Email={this.localStorage_Email.bind(
                                                                                this
                                                                            )}
                                                                            localStorage_city_state={this.localStorage_city_state.bind(
                                                                                this
                                                                            )}
                                                                            localStorage_company_industry={this.localStorage_company_industry.bind(
                                                                                this
                                                                            )}
                                                                            greenhousetoken={
                                                                                this.state.greenhousetoken
                                                                            }
                                                                            greenhouse_status={
                                                                                this.state.greenhouse_status
                                                                            }
                                                                            greenhouse_status_change={this.greenhouse_status_change.bind(
                                                                                this
                                                                            )}
                                                                            greenhousetoken_change={this.greenhousetoken_change.bind(
                                                                                this
                                                                            )}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey='Demographic-Information'>
                                                                <>
                                                                    {" "}
                                                                    {this.state.mydata.length != 0 ? (
                                                                        <Demographic_information
                                                                            apiPath={this.props.APIPath}
                                                                            mydata={this.state.mydata}
                                                                            dei_statement={this.state.dei_statement}
                                                                            team_lead={this.state.team_lead}
                                                                            programming={this.state.programming}
                                                                            training={this.state.training}
                                                                            erg={this.state.erg}
                                                                            men={this.state.men}
                                                                            women={this.state.women}
                                                                            non_binary={this.state.non_binary}
                                                                            lgbt={this.state.lgbt}
                                                                            update_data={this.update_DEI_info.bind(
                                                                                this
                                                                            )}
                                                                            role={this.state.Role}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey='DEI-Information'>
                                                                <>
                                                                    {this.state.mydata.length != 0 ? (
                                                                        <DEI_Information
                                                                            apiPath={this.props.APIPath}
                                                                            mydata={this.state.mydata}
                                                                            update_data={this.update_DEI_info.bind(
                                                                                this
                                                                            )}
                                                                            dei_statement={this.state.dei_statement}
                                                                            team_lead={this.state.team_lead}
                                                                            programming={this.state.programming}
                                                                            training={this.state.training}
                                                                            erg={this.state.erg}
                                                                            men={this.state.men}
                                                                            women={this.state.women}
                                                                            non_binary={this.state.non_binary}
                                                                            lgbt={this.state.lgbt}
                                                                            role={this.state.Role}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey='Contact-UIT'>
                                                                <>
                                                                    <Contact_us_information
                                                                        apiPath={this.props.APIPath}
                                                                    />
                                                                </>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    className='new_team_member_box_main'
                    show={this.state.account_delete_popup}
                    onHide={this.closeModal}
                >
                    <Modal.Body>
                        <h3>Delete Account</h3>
                        <p style={{ textAlign: "center" }}>
                            Why do you want to delete your account?
                        </p>
                        <div className='new_team_member_box_inner'>
                            {this.state.account_delete_reason_is_other == false &&
                                this.state.account_delete_reason == "" ? (
                                <>
                                    <div
                                        className='new_team_member_box_inner_reason'
                                        onClick={this.user_is_delete_account_action.bind(
                                            this,
                                            "Already found a job"
                                        )}
                                    >
                                        Already found a job
                                    </div>
                                    <div
                                        className='new_team_member_box_inner_reason'
                                        onClick={this.user_is_delete_account_action.bind(
                                            this,
                                            "Switching to a different provider"
                                        )}
                                    >
                                        Switching to a different provider
                                    </div>
                                    <div
                                        className='new_team_member_box_inner_reason'
                                        onClick={this.user_is_delete_account_action.bind(
                                            this,
                                            "Can't find my community"
                                        )}
                                    >
                                        Can't find my community
                                    </div>
                                    <div
                                        className='new_team_member_box_inner_reason'
                                        onClick={this.user_is_delete_account_action.bind(
                                            this,
                                            "Privacy concerns"
                                        )}
                                    >
                                        Privacy concerns
                                    </div>
                                    <div
                                        className='new_team_member_box_inner_reason'
                                        onClick={this.user_is_delete_account_action.bind(
                                            this,
                                            "Other"
                                        )}
                                    >
                                        Other
                                    </div>
                                </>
                            ) : this.state.account_delete_reason_is_other == true ? (
                                <textarea
                                    style={{
                                        marginBottom: "0",
                                        height: "200px",
                                        borderWidth: "1px",
                                        borderColor: "var(--dark--darkgrey)",
                                        padding: "2%",
                                        fontWeight: "normal",
                                        fontSize: "20px",
                                        color: "rgba(33,33,33,0.5)",
                                        width: "100%",
                                        borderRadius: "6px",
                                    }}
                                    onChange={this.account_delete_reason_is_other_reason_action.bind(
                                        this
                                    )}
                                    value={this.state.account_delete_reason_is_other_reason}
                                    placeholder='Let us know why'
                                />
                            ) : (
                                ""
                            )}
                            <div
                                className='singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper'
                                style={{ textAlign: "center", padding: "5% 0 0" }}
                            >
                                <div className='back_wapper'>
                                    <button type='button' onClick={this.closeModal.bind(this)}>
                                        {" "}
                                        Cancel{" "}
                                    </button>
                                </div>
                                {/* <button onClick={this.addNewFild.bind(this)} type="button" className="singup_input_button singup_setp-3-button">  Continue  </button> */}
                                {this.state.account_delete_reason_is_other == true ? (
                                    <button
                                        onClick={this.closeModal3.bind(this)}
                                        type='button'
                                        className='singup_input_button singup_setp-3-button'
                                    >
                                        {" "}
                                        Continue{" "}
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    className='new_team_member_box_main'
                    show={this.state.account_delete_popup_final}
                    onHide={this.closeModal2}
                >
                    <Modal.Body>
                        <h3>Delete Account</h3>
                        <p style={{ textAlign: "center" }}>
                            Your data will be lost completely and you won't be able to
                            retrieve any data
                        </p>
                        <div className='new_team_member_box_inner'>
                            <div
                                className='singup_input d-flex align-items-center justify-content-between singup_setp-3-button-wapper'
                                style={{ textAlign: "center", padding: "5% 0 0" }}
                            >
                                <div className='back_wapper'>
                                    <button type='button' onClick={this.closeModal2.bind(this)}>
                                        {" "}
                                        Cancel{" "}
                                    </button>
                                </div>
                                <button
                                    onClick={this.delete_account.bind(this)}
                                    type='button'
                                    className='singup_input_button singup_setp-3-button'
                                >
                                    {" "}
                                    Continue{" "}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default Profile;
