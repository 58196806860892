import React, { Component } from 'react';
import airbnb from '../../assets/image/airbnb.png';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

class Singup_stap_7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DEI_Information : this.props.save_data,
            valid_DEI_Information : ""
        }
        this.DEI_Information = React.createRef();
    }

    gotonextStap(){this.props.clickEvent("stap7"); }

    gotobackStap(){
        this.props.clickEvent("stap5");
    }

    handleKeyPress(e){
        if(e.target.name == "DEI_Information"){
            this.setState({
                valid_DEI_Information:"",
                DEI_Information:e.target.value
            });
        }
    }

    Save_company_data(event){
        event.preventDefault();
        let log = false;
        if(!this.DEI_Information.current.value){
            this.setState({ valid_DEI_Information: "Please enter a DEI Information" });
            log = true;
        }

        if(log === true){ 
            return 
     
        }

        let DEI_Information = this.DEI_Information.current.value;
        this.state.DEI_Information = DEI_Information;
        this.setState({DEI_Information:this.state.DEI_Information});
        this.props.saveEvent(DEI_Information);
        this.props.clickEvent("stap7");
    }
  

    render() {
        let $imagePreview = null;
        if (this.props.logoUrl != "") {
            //   $imagePreview = (<img style={{width:"100%"}} src={this.props.logoUrl} />);
            $imagePreview = this.props.logoUrl;
        } else {
            //   $imagePreview = (<img style={{width:"100%"}} src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data7 company-information-staup-7">
                <div class="col-lg-6"></div> 
                <div class="col-lg-6" style={{background:'#f9f9f9',padding:"1% 5% 5% 5%",textAlign:"left"}}>
                    <div class="singup_box stap-7-singup " style={{marginTop:"5%", background:'overlay',background: "#FFF",float: "left", width: "100%", height: "auto",borderRadius: "6px"}}>
                        <div class="Singup_stap_6_progress" style={{width:"21.5%",float:"left",height:"5px", borderTop:"10px solid teal", borderRadius:"25px 25px 0 0"}} ></div>
                        <div class="singup_box_inner">
                            <div class="singup_box_title singup_box_title_logo d-flex justify-content-start f-left w-100 align-items-center" style={{padding:"5% 8% 5%"}}>
                                <div className="singup_box_title_logo_image" style={{marginRight:'12px',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                    {/* {$imagePreview} */}
                                </div>
                                <div className="singup_box_title_logo_text" style={{width:"85%"}}>
                                    <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Company Information</h3>
                                    <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "normal", fontSize: "24px"}}>DEI Information</p>
                                </div>  
                            </div>
                            <div class="singup_box-singup-stap-2" style={{padding:"0% 8% 8%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data.bind(this)}>
                                    <div className="singup_input" style={{float:"left",width:"100%",marginBottom:"23%"}}>
                                        <label>DEI Information</label>
                                        <textarea ref={this.DEI_Information} onChange={this.handleKeyPress.bind(this)} value={this.state.DEI_Information} name="DEI_Information" placeholder="DEI Information" />
                                        <span className="form_validtion_class"> {this.state.valid_DEI_Information} </span>
                                    </div>
                                    <div className="singup_input" style={{textAlign:"center"}}>
                                        <button type="button" onClick={this.Save_company_data.bind(this)} className="singup_input_button"> Continue </button>
                                    </div>
                                    {/* <div className="back_wapper" style={{textAlign:"center",marginTop:"1%", float:"left",width:"100%"}}>
                                        <button onClick={this.gotonextStap.bind(this)} type="button" style={{background: "transparent",border: "none",color: "teal", fontSize:"20px"}}> Skip This </button>
                                    </div>  */}
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Singup_stap_7;