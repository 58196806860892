import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch , Link, Navigate } from "react-router-dom";
import {Form} from 'react-bootstrap';
import last_stap from '../assets/image/last-stap.png';

//Add Tabs
class Post_job_stap_5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    new_post_job(){
        //this.props.postdata(this);
        this.props.postEvent("post_job_1");
    }

    sendDataToback(event){
        event.preventDefault();
        
        this.setState({redirect:true});
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/ALL_Post" />
        }
        return (
            <>
                <div className='Dashbaord Dashbaord-post_job_main'>
                    <div className='Dashbaord-post_job_inner_box'>
                        <div className='Dashbaord_header post_job_stap_5-header'>
                            <h3 className=''>Post A Job</h3>
                            <div className='progressbar'>
                                <div className='progressbar-data'></div>
                            </div>
                        </div>
                        <div className='Dashbaord_Body Dashbaord-stap-3 Dashbaord-stap-5'>
                            <div className='Dashbaord_Body_inner Dashbaord-post_job_inner  '>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='finel_stap-main'>
                                            <div className='finel_stap'>
                                                <div className='finel_stap-img'>   
                                                    <img src={last_stap} /> 
                                                </div>    
                                                <div className='finel_stap-text'>    
                                                    <h3> Congratulations </h3>
                                                    <span>Your job is now live on UIT Platform.</span>
                                                </div>     
                                                <div className='finel_stap-button'>    
                                                    <button onClick={this.new_post_job.bind(this)} type='button'>Post  Another Job</button>
                                                    <a onClick={this.sendDataToback.bind(this)} style={{cursor:'pointer'}}>Back To Dashboard</a>
                                                </div>    
                                            </div>
                                        </div>        
                                    </div>
                                </div>
                            </div>    
                        </div>
                        {/* <div className='Dashbaord_Body_stap-box'>
                            <div className='Dashbaord_Body_stap-box-button'>
                                <button type-="button" className='Dashbaord_stap-box-button-go_back'> Go Back </button>
                                <button type-="button" className='Dashbaord_stap-box-button-next'> Next Step </button>
                            </div>        
                        </div>     */}
                    </div>    
                </div>
            </>
        );
    }
}
export default Post_job_stap_5;