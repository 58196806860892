import LeftSideBar from ".././sidebar/leftside";
import React, { Component, useState,useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Navigate } from "react-router-dom"
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import upload_image from '../../assets/image/logo.png';

const ForgotPassword = (props) => {
    const [Email, setEmail] = useState('');
    const [Password, setPassword] = useState('');
    const [show_section, setshow_section] = useState(0);
    const [showerror, setshowerror] = useState(false);
    const [codeInputs, setcodeInputs] = useState(Array.from({ length: 6 }).fill(""));
    const [more_charecter, setmore_charecter] = useState('');
    const [upper_or_lowercase, setupper_or_lowercase] = useState('');
    const [number_or_special, setnumber_or_special] = useState('');
    const [otp1, setotp1] = useState('');
    const [otp2, setotp2] = useState('');
    const [otp3, setotp3] = useState('');
    const [otp4, setotp4] = useState('');
    const [otp5, setotp5] = useState('');
    const [otp6, setotp6] = useState('');
    const [showerrormessage, setshowerrormessage] = useState('');
    const [showerrortype, setshowerrortype] = useState('');
    const [redirect, setredirect] = useState(false);

    

    
    
    const otp1InputRef = useRef(null);
    const otp2InputRef = useRef(null);
    const otp3InputRef = useRef(null);
    const otp4InputRef = useRef(null);
    const otp5InputRef = useRef(null);
    const otp6InputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const error_handaling = () => {
        if (showerror == true) {
            setTimeout(function () {
                setshowerror(false);
            }.bind(this), 3000);

            return (
                <>
                    <Alert style={{ position: "absolute", top: "0", left: "0", right: "0", width: "100%", float: "left", textAlign: "center" }} variant={showerrortype == 1 ? "success" : "danger"}>
                        {showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    const upper_or_lowercase_function = (data) => {
        let upper_lower_data = false;
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
        if (regex.test(data)) {
            upper_lower_data = true;
        } else {
            upper_lower_data = false;
        }
        return upper_lower_data;
    }

    const onlyspealcardteralud_function = (data) => {
        let special_data = false;
        var regex = new RegExp("^(?=.*[@$!%*?&])");
        var numb = new RegExp("^(?=.*?[0-9])");
        if (regex.test(data)) {

        special_data = true;
        } else if (numb.test(data)) {
            special_data = true;
        } else {
            special_data = false;
        }

        return special_data;
    }

    const handleKeyPress = (e) => {
        const { name, value } = e.target;
        if (name === "Email") {
            setEmail(value);
        }
        if (name === "password") {
            setPassword(value);
            if (value === "") {
                setmore_charecter(false)
                setupper_or_lowercase(false)
                setnumber_or_special(false)
            } else {
                if (upper_or_lowercase_function(value)) {
                    setupper_or_lowercase(true);
                }
                if (onlyspealcardteralud_function(value)) {
                    setnumber_or_special(true);
                }
                if (value.length < 7) {
                    setmore_charecter(false);
                }else{
                    setmore_charecter(true);                    
                }
            }
        }
    }

    const addNewfild = (event) => {
        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (Email == '') {
            setshowerror(true);
            setshowerrormessage("Please enter a email address");
            setshowerrortype(2);
            return false;
        }
        if(!emailRegex.test(Email)){            
            setshowerror(true);
            setshowerrormessage("Please enter a valid email address");
            setshowerrortype(2);
            return false;
        }
        
        const apiOption = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": Email,
            }),
            redirect: 'follow'
        }

        fetch(props.APIPath + "forgot_password", apiOption)
        .then(function responsError(res) {
            return res.json();
        }).then(resJson => {
            if (resJson.status == true) {
                setshow_section(1);
                setshowerror(true);
                setshowerrormessage("Please check your email for the forgot password link");
                setshowerrortype(1);
            } else if (resJson.status == false) {
                let data = resJson.message;
                setshowerror(true);
                setshowerrormessage(data);
                setshowerrortype(2);
            } else {
                setshowerror(true);
                setshowerrormessage("Please enter valid email");
                setshowerrortype(2);
            }
        })
        .catch(error => {
            this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }
    const addNewfild2 = (event) => {
        event.preventDefault();
        if(otp1 == '' || otp2 == '' || otp3 == '' || otp4 == '' || otp5 == '' || otp6 == '' ){
            setshowerror(true);
            setshowerrormessage("Please enter a OTP");
            setshowerrortype(2);
            return false;
        }
        if(Password == ''){
            setshowerror(true);
            setshowerrormessage("Please enter a Password");
            setshowerrortype(2);
            return false;
        }
        if(!more_charecter || !upper_or_lowercase || !number_or_special){
            setshowerror(true);
            setshowerrormessage("Please enter a Strong Password");
            setshowerrortype(2);
            return false;
        }

        const apiOption = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": Email,
                "code": otp1+''+otp2+''+otp3+''+otp4+''+otp5+''+otp6,
                "new_password": Password,
            }),
            redirect: 'follow'
        }

        fetch(props.APIPath + "reset_in_app_password", apiOption)
        .then(function responsError(res) {
            return res.json();
        }).then(resJson => {
            if (resJson.status == true) {
                setshow_section(1);
                setshowerror(true);
                setshowerrormessage("Password reset successfully");
                setshowerrortype(1);
            
                setTimeout(function () {
                    setredirect(true);
                }.bind(this), 3100);
            } else if (resJson.status == false) {
                let data = resJson.message;
                setshowerror(true);
                setshowerrormessage(data);
                setshowerrortype(2);
            } else {
                setshowerror(true);
                setshowerrormessage("Please enter valid otp");
                setshowerrortype(2);
            }
        })
        .catch(error => {
            this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }
    

    const inputfocus = (e, idx) => {
        const { value } = e.target;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            if (value && idx < 6) {
                if(idx == 0){
                    setotp1(value);
                    otp2InputRef.current.focus();
                }
                if(idx == 1){
                    setotp2(value);
                    otp3InputRef.current.focus();
                }
                if(idx == 2){
                    setotp3(value);
                    otp4InputRef.current.focus();
                }
                if(idx == 3){
                    setotp4(value);
                    otp5InputRef.current.focus();
                }
                if(idx == 4){
                    setotp5(value);
                    otp6InputRef.current.focus();
                }
                if(idx == 5){
                    setotp6(value);
                    passwordInputRef.current.focus();
                }
            }
        } else {
            e.preventDefault();
        }
    }

    const resendEmailotp = (event) => {

        event.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (Email == '') {
            setshowerror(true);
            setshowerrormessage("Please enter a email address");
            setshowerrortype(2);
            return false;
        }
        if(!emailRegex.test(Email)){            
            setshowerror(true);
            setshowerrormessage("Please enter a valid email address");
            setshowerrortype(2);
            return false;
        }
        
        const apiOption = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "email": Email,
            }),
            redirect: 'follow'
        }

        fetch(props.APIPath + "forgot_password", apiOption)
        .then(function responsError(res) {
            return res.json();
        }).then(resJson => {
            if (resJson.status == true) {
                setshow_section(1);
                setshowerror(true);
                setshowerrormessage("Please check your email for the forgot password link");
                setshowerrortype(1);
            } else if (resJson.status == false) {
                let data = resJson.message;
                setshowerror(true);
                setshowerrormessage(data);
                setshowerrortype(2);
            } else {
                setshowerror(true);
                setshowerrormessage("Please enter valid email");
                setshowerrortype(2);
            }
        })
        .catch(error => {
            this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
            this.error_handaling();
        });
    }

    if (redirect) {
        return <Navigate to="/" />;
      }
    
    return (
        
        <>
            {error_handaling()}

            <div className="row  signin_signup_background signin_form_page">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 singin_box" style={{ background: '#f9f9f9', padding: "1% 5% 3% 5%", textAlign: "left" }}>
                    <div className="fileUpload" >
                        <div className="upload_image">
                            <img src={upload_image} style={{ width: "100px" }} />
                        </div>
                    </div>
                    <div className="singup_box " style={{ marginTop: "5%", background: 'overlay', background: "#FFF", float: "left", width: "100%", height: "auto", borderRadius: "15px" }}>
                        {show_section == 0 && (
                            <div className="singup_box_inner">
                                <div className="singup_box_title" style={{ padding: "5%", textAlign: "center" }}>
                                </div>
                                <div className="singup_box_title-sub" style={{ padding: "0 0 5% ", textAlign: "center" }}>
                                    <h3 style={{ color: '#070707', fontWeight: "bold", fontSize: "32px", fontFamily: 'Poppins' }}>Forgot Password</h3>
                                </div>
                                <div className="singup_box-singup-stap-2" style={{ padding: "0% 15% 6%", float: "left", width: "100%" }}>
                                    <form id="UserLoginForm" onSubmit={addNewfild.bind(this)} style={{ float: "left", width: "100%" }}>
                                        <div className="singup_input" style={{ marginBottom: "40px" }}>
                                            <input onChange={handleKeyPress.bind(this)} name="Email" type="Email" placeholder="Email address" style={{ fontSize: "18px", color: "rgba(109,109,109,1)", fontSize: "18px", border: "2px solid #AEAEAE" }} />
                                        </div>
                                        <div className="singup_input" style={{ textAlign: "center", width: "100%" }}>
                                            <button type="submit" style={{ maxWidth: "100%" }} className="singup_input_button forgot_password_input_button"> Forgot Password </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="forget-password">
                                    <span style={{ marginBottom: "20px" }}>Don't have an account? <Link style={{ fontWeight: "500" }} to={"/Signup"} variant="outline-success">Sign up now</Link></span>
                                    <span>By signing up, you agree to our  </span> <span style={{ maxWidth: "390px" }}> <a style={{ fontWeight: "500" }} href="#">Terms & Conditions</a>  and our <a href="#" style={{ fontWeight: "500" }} >Privacy Policy</a></span>
                                </div>
                            </div>
                        )}
                        {show_section == 1 && (
                            <div className="singup_box_inner">
                                <div className="singup_box_title" style={{ padding: "5%", textAlign: "center" }}>
                                </div>
                                <div className="singup_box_title-sub" style={{ padding: "0 0 2% ", textAlign: "center" }}>
                                    <h3 style={{ color: '#070707', fontWeight: "bold", fontSize: "32px", fontFamily: 'Poppins' }}>Forgot Password</h3>
                                </div>
                                <div className="singup_box-singup-stap-2" style={{ padding: "0% 15% 6%", float: "left", width: "100%" }}>
                                    <form id="UserLoginForm" onSubmit={addNewfild2.bind(this)} style={{ float: "left", width: "100%" }}>
                                        <div className="">
                                            <span style={{ color: "charcolblack", fontSize: "18px", fontStyle: "normal", fontWeight: "normal", float: "left", width: "100%", marginBottom: "2%" }}>Enter verification code <button type="button" onClick={resendEmailotp.bind(this)} style={{ color: "teal", fontSize: "18px", fontStyle: "normal", fontWeight: "normal", padding: "0", backgroundColor: "transparent", border: "none" }}>Resend</button></span>
                                        </div>
                                        <div className="singup_input" style={{ marginBottom: "40px" }}>
                                            <div className="d-flex justify-content-between" style={{ maxWidth: "100%" }}>
                                                
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp1InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 0)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp2InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 1)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp3InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 2)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp4InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 3)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp5InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 4)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                                <div className="singup_input" style={{ float: "left", marginBottom: "5%", marginRight: "1%", marginLeft: "1%" }}>
                                                    <input
                                                        ref={otp6InputRef}
                                                        type="text"
                                                        name="input1"
                                                        maxLength="1"
                                                        onChange={(e) => inputfocus(e, 5)}
                                                        style={{ textAlign: "center", fontSize: "18px", fontStyle: "normal", fontWeight: "normal" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="singup_input" style={{ float: "left", width: "100%", marginBottom: "2%" }}>
                                            <label>Password</label>
                                            <input onChange={handleKeyPress.bind(this)} ref={passwordInputRef} name='password' placeholder='Password' type="password" style={{ borderColor: "rgba(109,109,109,1)", borderRadius: "6px" }} />
                                        </div>
                                        
                                        <div className='validtion-check-data' style={{ float: "left", width: "100%" }}>
                                            <div className="form-check">
                                                <input style={{ opacity: 1 }} checked={more_charecter != false} value="1" onChange={handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                                <label className="form-check-label" for="DEI_Programming"> 7 or more characters </label>
                                            </div>
                                            <div className="form-check">
                                                <input style={{ opacity: 1 }} checked={upper_or_lowercase != false} value="1" onChange={handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                                <label className="form-check-label" for="DEI_Programming"> Upper & lowercase </label>
                                            </div>
                                            <div className="form-check">
                                                <input style={{ opacity: 1 }} checked={number_or_special != false} value="1" onChange={handleKeyPress.bind(this)} className="form-check-input" type="checkbox" name="DEI_Programmingdata" id="DEI_Programming" disabled />
                                                <label className="form-check-label" for="DEI_Programming"> 1 number or special character </label>
                                            </div>
                                        </div>                                        
                                        <div className="singup_input" style={{ textAlign: "center", width: "100%",marginTop:'20px' }}>
                                            <button type="submit" style={{ maxWidth: "100%" }} className="singup_input_button forgot_password_input_button"> Forgot Password </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;