import React, { Component } from 'react';
import Select from 'react-select';
import MyComponent from './AddClientUser';

//CSS

//Images
import upload_image from '../../assets/image/upload_image.png';

class Team_member_stap2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Save Upload image
            file: '',
            imagePreviewUrl: this.props.Team_imagePreviewUrl,
            company_name:this.props.Team_Name,
            // Fome data erroe
            logo:"",
            valid_logo : "",
            change_logo: this.props.change_logo,
            valid_company_name : "", 
        };
        // add form value
        this.Company_Name = React.createRef();
    }

    // Fileupload before privew;
    // changeLogo(e) {
    //     e.preventDefault();
    //     this.setState({ valid_logo: "" });

    //     let reader = new FileReader();
    //     let file = e.target.files[0];
    
    //     reader.onloadend = () => {
    //         this.setState({
    //             file: file,
    //             imagePreviewUrl: reader.result
    //         });
    //     }
    
    //     reader.readAsDataURL(file)
    // }
    //
    handleKeyPress(e){
        if(e.target.name == "Company_Name"){
            this.setState({ 
                company_name : e.target.value,
                valid_company_name: "" 
            });
        }
    }
    //Fome submit function
    Save_company_data(event){
        event.preventDefault();

        let log = false;
       
        if(this.state.imagePreviewUrl == ""){
            this.setState({ valid_logo: "Please upload a profile picture" });
            log = true;
        }     

        if(!this.Company_Name.current.value){
            this.setState({ valid_company_name: "Please enter a company name" });
            log = true;
        }
       
        if(log === true){ 
            return 
        }

        this.state.company_name = this.Company_Name.current.value;
        this.setState({company_name: this.state.company_name});
        this.props.savedata(this.state.file, this.state.imagePreviewUrl, this.state.company_name);

        this.props.clickEvent("Team_member_stap3");
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    //set_image_after_update
    afterCropImage(data,times){
        let cropImage = data;
        let first_time = times;
        let filedata = this.dataURLtoFile(cropImage,"changeprofile.png");
        this.setState({imagePreviewUrl:cropImage,file:filedata,change_logo:first_time});
    }

    render() {
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            //   $imagePreview = (<img src={imagePreviewUrl} />);
            $imagePreview = imagePreviewUrl;
        } else {
            //   $imagePreview = (<img src={upload_image} />);
            $imagePreview = upload_image;
        }
        return (
            <div className="row signin_signup_background singni_singup_meta_data1">
               <div className="col-lg-6"></div> 
                <div className="col-lg-6" style={{background:'#f9f9f9',padding:"5% 5% 5% 5%",textAlign:"left"}}>
                    <div className="singup_box Team_member_stap_2-inner_box" style={{background:'overlay',background: "#FFF",float: "left", width: "100%", height: "100%",borderRadius: "6px"}}>
                        <div className="Singup_stap_2_progress" style={{width:'36.35%'}}></div>
                        <div className="singup_box_inner">
                            <div className="singup_box_title" style={{padding:"8% 8% 0"}}>
                                <h3 style={{color:'var(--green)',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "600", fontSize: "32px"}}>Team Member Account</h3>
                                <p style={{color:'#6d6d6d',fontFamily: "Poppins", fontStyle: "normal", fontWeight: "500", fontSize: "24px"}}>Professional Information</p>
                            </div>
                            <div className="singup_box-singup-stap-2" style={{padding:"0% 15% 6%",float:"left",width:"100%"}}>
                                <form style={{float:"left",width:"100%"}} onSubmit={this.Save_company_data.bind(this)}>
                                    <div className="fileUpload" style={{position: "relative", maxWidth: "300px",margin:"auto"}}>
                                        <div className="upload_image" style={{margin:'0 auto',borderRadius:'100%',width:'82px',height:'80px',backgroundSize:'cover',backgroundImage: `url(${$imagePreview})`}}>
                                            {/* {$imagePreview} */}
                                        </div>
                                        <div className='logo_upload_button'>
                                            <label htmlFor='input-file'> Upload Profile Picture </label>
                                            {/* <input  onChange={(e)=>this.changeLogo(e)} id='input-file' type='file' inputProps={{ accept: 'image/*' }} /> */}
                                            <span  className="form_validtion_class"> {this.state.valid_logo} </span>
                                        </div>
                                        <MyComponent change_logo={this.state.change_logo} edit_data = {false} afterCropImage={this.afterCropImage.bind(this)} />    
                                    </div>
                                    <div className="singup_input">
                                        <label>Full Name</label>
                                        <input onChange={this.handleKeyPress.bind(this)}  value={this.state.company_name} ref={this.Company_Name} type="text" name="Company_Name" />
                                        <span className="form_validtion_class"> {this.state.valid_company_name} </span>
                                    </div>
                                    <div className="singup_input" style={{textAlign:"center", marginTop:"27%"}}>
                                        <button type="button" onClick={this.Save_company_data.bind(this)}  className="singup_input_button"> Continue </button>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default Team_member_stap2;