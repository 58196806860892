import React, { Component } from 'react';
import airbnb from '../assets/image/gucci-logo-illustration@2x.png';
import Rectangle_6 from '../assets/image/Rectangle_669.png';
import { Col, Nav, Tab, Row, Modal, Button } from 'react-bootstrap';
import Show_user_job from './Show_user_job';
import { BrowserRouter as Router, Link, Navigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
// import moment from 'moment';
import moment from 'moment-timezone';
// import ErrorSnackbar from '../components/ErrorSnackbar';
moment.tz.setDefault('Etc/UTC');

//Add Tabs
class About_post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen_2: false,
            // get data 
            job_title: this.props.job_title,
            city: this.props.city,
            state: this.props.state,
            responsibilities: this.props.responsibilities,
            // company
            company_profile_img: this.props.company_profile_img,
            company_name: this.props.company_name,
            // user data
            user_name: this.props.user_name,
            user_profile: this.props.user_profile,
            //salary
            min_salary: this.props.min_salary,
            max_salary: this.props.max_salary,
            //industry
            industry: this.props.industry,
            send_message: "",
            valid_send_message: "",
            redirect: false,

            showerror: false,
            showerrortype: 0,
            showerrormessage: "",
        };

        this.send_message = React.createRef();
    }


    openModal = () => this.setState({ isOpen_2: true });
    closeModal = () => this.setState({ isOpen_2: false });

    handleKeyPress(e) {
        this.setState({
            valid_send_message: "",
            send_message: e.target.value
        });
    }

    Delete_post_job_id(event) {
        event.preventDefault();
        const apiOption = {
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + this.props.sendtokon,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "job_id": this.props.job_active_id,
            }),
        }

        fetch(this.props.apiPath + "delete_job", apiOption)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                if (resJson.status == true) {
                    this.closeModal();
                    this.props.get_list_closed();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "The job has been deleted" });
                    this.error_handaling();
                } else {
                    this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                    let data = resJson.message;
                    this.error_handaling();
                }
            })
            .catch(error => {
                // this.handleExceptionError(error);
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });
    }

    Submit_and_close(event) {
        event.preventDefault();
        // let user_login_by_id = localStorage.getItem('User_id');
        // let post_by_id = this.props.company_post_id;
        // if(user_login_by_id === post_by_id){
        let log = false;
        if (!this.send_message.current.value) {
            this.setState({ valid_send_message: "Please enter a valid reason for closing" });
            log = true;
        }

        if (log === true) {
            return
        }

        this.state.send_message = this.send_message.current.value;
        this.setState({ send_message: this.state.send_message });

        const apiOption = {
            method: "POST",
            headers: {
                Authorization: "Bearer" + " " + localStorage.getItem("token"),
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "job_id": this.props.job_active_id
            }),
        }

        fetch(this.props.apiPath + "close_job", apiOption)
            .then(function (res) {
                return res.json();
            }).then(resJson => {
                if (resJson.status) {
                    this.closeModal();
                    this.props.listofdata();
                    this.setState({ showerror: true, showerrortype: 1, showerrormessage: "This job has been closed" });
                    this.error_handaling();
                } else {
                    this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response 2" });
                    let data = resJson.message;
                    this.error_handaling();
                }
            })
            .catch(error => {
                // this.handleExceptionError(error);
                this.setState({ showerror: true, showerrortype: 2, showerrormessage: "Invalid Response" });
                this.error_handaling();
            });

    }

    editJob() {
        localStorage.setItem('edit_job_id', JSON.stringify(this.props.job_active_id));
        this.setState({ redirect: true });
    }

    error_handaling() {
        if (this.state.showerror == true) {
            setTimeout(function () {
                this.setState({ showerror: false });
            }.bind(this), 3000);

            if (this.state.showerrortype == 1) {
                setTimeout(function () {
                    // this.setState({redirect: true});
                }.bind(this), 3100);
            }

            return (
                <>
                    <Alert style={{ position: "absolute", top: "0", left: "0", right: "0", width: "100%", float: "left", textAlign: "center" }} variant={this.state.showerrortype == 1 ? "success" : "danger"}>
                        {this.state.showerrormessage}
                    </Alert>
                </>
            )
        }
    }

    // handleExceptionError(error) {
    //     var ExceptionError = this.state.ExceptionError;
    //     ExceptionError = [...ExceptionError, { id: Date.now(), message: error.message }];
    //     this.state.ExceptionError = ExceptionError;
    //     this.setState({ ExceptionError: ExceptionError });
    // }
    // clearErrors(id) {
    //     var ExceptionError = this.state.ExceptionError;
    //     ExceptionError = ExceptionError.filter(msg => msg.id !== id);
    //     this.state.ExceptionError = ExceptionError;
    //     this.setState({ ExceptionError: ExceptionError });
    // }

    render() {
        let $com_profile = null;
        if (this.props.company_name != "") {
            $com_profile = (<img style={{ width: "107px", height: "107px", borderRadius: "6px" }} src={this.props.company_profile_img} />);
        } else {
            $com_profile = (<img style={{ width: "107px", height: "107px" }} src={airbnb} />);
        }

        //user Profile
        let $user_profile = null;
        if (this.props.user_profile != "") {
            $user_profile = (<img style={{ width: "56px", borderRadius: "60%", height: "56px" }} src={this.props.user_profile} />);
        } else {
            $user_profile = (<img style={{ width: "56px", borderRadius: "60%", height: "56px" }} src={Rectangle_6} />);
        }

        if (this.state.redirect) {
            return <Navigate to="/Post_Job" />
        }

        let $dataclass = "";
        if (this.props.active_id == undefined) {
            $dataclass = "show";
        } else {
            $dataclass = "hidden";
        }
        return (
            <>
                {this.props.job_title !== "" ? <>
                    {this.error_handaling()}
                    <div className={'about_post_main '}>
                        <div className='post_box-user'>
                            <span className='post_user_icon'>
                                {/* <img style={{width:"107px"}} src={$com_profile} /> */}
                                {$com_profile}
                                <span className='post_time'>{moment(this.props.created_at, "YYYYMMDD, h:mm:ss a").fromNow()}</span>
                            </span>
                            <div className='post_user_text'>
                                <span className='post_name'>{this.props.job_title}</span>
                                <a href="#" className='AirBnB'>{this.props.company_name}</a>
                                <span className='address'>{this.props.city}, {this.props.state}</span>
                                <div className="tag_data">
                                    <span className="tag_data_inner">{(this.props.min_salary / 1000) + 'K'}-{(this.props.max_salary / 1000) + 'K'}</span>
                                    <span className="tag_data_inner">{this.props.industry}</span>
                                    <span className="tag_data_inner">
                                        {this.props.employment_type == 1 ? "Full Time" : ""}
                                        {this.props.employment_type == 2 ? "Part Time" : ""}
                                        {this.props.employment_type == 3 ? "Contract" : ""}
                                        {this.props.employment_type == 4 ? "Freelance" : ""}
                                        {this.props.employment_type == 5 ? "Internship" : ""}
                                    </span>
                                    {/* <span className="tag_data_inner">In-person</span> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='about_post_users'>
                        <div className='about_post_users_box'>
                            <div className='about_post_users_box-icon'>
                                {$user_profile}
                            </div>    
                            <div className='about_post_users_box-text'>
                                <h3>Posted By { this.props.user_name}</h3>
                                <span>{this.props.company_name}</span>        
                            </div>    
                        </div>
                        <div className='about-messages'>
                            <Link to={"/Messages"} variant="outline-success">Message</Link>
                        </div>    
                    </div> */}

                        {this.props.tab_type == "Filled" ?
                            <div className='show_users_post'>
                                <Show_user_job total_applications={this.props.total_applications} applications={this.props.applications} />
                            </div>
                            : ""}

                        {this.props.tab_type == "Listed" || this.props.tab_type == "Closed" ?
                            <div className='show_users_post'>
                                <Show_user_job total_applications={this.props.total_applications} applications={this.props.applications} />
                            </div>
                            : ""
                        }


                        <div className='about_job_data'>
                            <div className='all_post_main'>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="Description">
                                    <Row style={{ margin: "0" }}>
                                        <Col sm={12} className="d-flex justify-content-between align-items-center" style={{ padding: "0" }}>
                                            <Nav variant="pills" className="flex-row">
                                                <Nav.Item><Nav.Link eventKey="Description">Description</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link eventKey="Requirements">Requirements</Nav.Link></Nav.Item>
                                                <Nav.Item><Nav.Link eventKey="Company">Company</Nav.Link></Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="Description">
                                                    <div className='post_description'>
                                                        <h3>Job Description</h3>
                                                        {/* <ul>
                                                        <li> <span> {this.props.roles !="" ? this.props.roles : ""} </span> </li>
                                                    </ul> */}
                                                        <p dangerouslySetInnerHTML={{ __html: (this.props.roles).replace(/\n/g, "<br />") }}></p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Requirements">
                                                    <div className='post_description'>
                                                        <h3>Job Responsibilities</h3>
                                                        {/* <p>{this.props.responsibilities}</p> */}
                                                        <p dangerouslySetInnerHTML={{ __html: (this.props.responsibilities).replace(/\n/g, "<br />") }}></p>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Company">
                                                    <div className='post_description'>
                                                        <div className='bio'>
                                                            <h3>About</h3>
                                                            <span className="post_name">{this.props.company_bio}</span>
                                                        </div>
                                                        <div className='bio' style={{ marginBottom: "15px" }}>
                                                            <h3>Website</h3>
                                                            <a className="post_name" style={{ textDecoration: "none", color: "var(--bs-body-color)" }} href={this.props.company_website} target='_blank' >{this.props.company_website}</a>
                                                        </div>
                                                        <span className="post_name" style={{ marginBottom: "15px" }}>{this.props.company_address}</span>
                                                    </div>

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>

                        <div className='hired_applicants'>
                            <h3>Hired Applicants</h3>
                            <div className='about_post_users'>
                                <div className='about_post_users_box'>
                                    <div className='about_post_users_box-icon'>
                                        <img style={{ width: "70px" }} src={this.props.company_profile_img != "" ? this.props.company_profile_img : airbnb} />
                                    </div>
                                    <div className='about_post_users_box-text'>
                                        <h3>Posted By {this.props.company_name}</h3>
                                        <span>UI Designer</span>
                                    </div>
                                </div>
                                <div className='about-messages'>
                                    <a href="/Messages">Message</a>
                                </div>
                            </div>
                            <div className='about_post_users'>
                                <div className='about_post_users_box'>
                                    <div className='about_post_users_box-icon'>
                                        <img style={{ width: "70px" }} src={airbnb} />
                                    </div>
                                    <div className='about_post_users_box-text'>
                                        <h3>Posted By Robert Osborn</h3>
                                        <span>UI Designer</span>
                                    </div>
                                </div>
                                <div className='about-messages'>
                                    <a href="#">Message</a>
                                </div>
                            </div>
                            <div className='about_post_users'>
                                <div className='about_post_users_box'>
                                    <div className='about_post_users_box-icon'>
                                        <img style={{ width: "70px" }} src={airbnb} />
                                    </div>
                                    <div className='about_post_users_box-text'>
                                        <h3>Posted By Robert Osborn</h3>
                                        <span>UI Designer</span>
                                    </div>
                                </div>
                                <div className='about-messages'>
                                    <a href="#">Message</a>
                                </div>
                            </div>
                            <div className='about_post_users'>
                                <div className='about_post_users_box'>
                                    <div className='about_post_users_box-icon'>
                                        <img style={{ width: "70px" }} src={airbnb} />
                                    </div>
                                    <div className='about_post_users_box-text'>
                                        <h3>Posted By Robert Osborn</h3>
                                        <span>UI Designer</span>
                                    </div>
                                </div>
                                <div className='about-messages'>
                                    <a href="#">Message</a>
                                </div>
                            </div>
                        </div>

                        <div className="action_buttons">
                            <div className='Listed-contends-button' style={{ marginTop: "15px" }}>
                                {localStorage.getItem('user_Role') == 2 || localStorage.getItem('User_id') == this.props.company_post_id ?
                                    <>
                                        <button type='button' className='close_button' onClick={this.openModal.bind(this)}>Close Job</button>
                                        <button type='button' onClick={this.editJob.bind(this)} className='edit_button'>Edit Job</button>
                                        {/* <Link className='edit_button' to={`/Post_Job-edit/${this.props.job_active_id}`} key={this.props.job_active_id}> Edit Job </Link> */}
                                    </> : ""}
                            </div>

                            {localStorage.getItem('user_Role') == 2 || localStorage.getItem('User_id') == this.props.company_post_id ? <>
                                <div className='Closed-contends-button'>
                                    <button type='button' className='close_button' onClick={this.Delete_post_job_id.bind(this)}> Delete Job </button>
                                </div>
                            </> : ""}
                        </div>
                    </div>
                </> : ""}
                <Modal className="new_team_member_box_main flagged-applicant Close-Job" show={this.state.isOpen_2} onHide={this.closeModal}>
                    <Modal.Body>
                        <div className='users_job_box'>
                            <img src={this.props.company_profile_img != "" ? this.props.company_profile_img : airbnb} />
                            <h3>You are about to close the job</h3>
                            <span> "{this.props.job_title} needed" </span>
                        </div>

                        <div className="new_team_member_box_inner">
                            <form onSubmit={this.Submit_and_close.bind(this)} >
                                <div className="singup_input" style={{ float: "left", width: "100%" }}>
                                    <label style={{ float: "left", width: "100%", fontWeight: "500" }}>Reason for Closing</label>
                                    <textarea onChange={this.handleKeyPress.bind(this)} ref={this.send_message} />
                                    <span className="form_validtion_class">{this.state.valid_send_message}</span>
                                </div>

                                <div className="singup_input " style={{ textAlign: "center", padding: "5% 0 0" }}>
                                    <button type="button" onClick={this.Submit_and_close.bind(this)} className="singup_input_button singup_setp-3-button"> Submit and close </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default About_post;         